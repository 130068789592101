import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ActionType, createAction, getType } from 'typesafe-actions';
import { Fare } from 'models/Fares';
import { getFareTickerDefaultFares, getFareTickerFaresByAirportCode } from 'api/fareTicker';

export const actions = {
    setMaxFares: createAction('awd/fareTicker/SET_MAX_FARES', resolve => (maxFares: number) => resolve(maxFares)),
    fetchFares: createAction('awd/fareTicker/FETCH_FARE_TICKER_FARES'),
    fetchFaresSuccess: createAction('awd/fareTicker/FETCH_FARE_TICKER_FARES_SUCCESS', resolve => (fares: Fare[]) =>
        resolve(fares)
    ),
    fetchFaresSuccessFallback: createAction(
        'awd/fareTicker/FETCH_FARE_TICKER_FARES_SUCCESS_FALLBACK',
        resolve => (fares: Fare[]) => resolve(fares)
    ),
    fetchFaresFailure: createAction('awd/fareTicker/FETCH_FARE_TICKER_FARES_FAILURE')
};

export type FareTickerAction = ActionType<typeof actions>;

export type FareTickerState = Readonly<{
    maxFares: number;
    fares: Fare[];
    isFallbackFares: boolean;
    isError: boolean;
    isLoading: boolean;
}>;

export interface StoreWithFareTickerState {
    fareTicker: FareTickerState;
}

const initialState: FareTickerState = {
    maxFares: 10,
    fares: [],
    isFallbackFares: false,
    isError: false,
    isLoading: false
};

export default function reducer(
    state: FareTickerState = initialState,
    action: FareTickerAction
): Readonly<FareTickerState> {
    switch (action.type) {
        case getType(actions.setMaxFares):
            return action.payload > 0 ? { ...state, maxFares: action.payload } : state;
        case getType(actions.fetchFares):
            return { ...state, isError: false, isFallbackFares: false, isLoading: true };
        case getType(actions.fetchFaresFailure):
            return { ...state, isError: true, isFallbackFares: false, isLoading: false };
        case getType(actions.fetchFaresSuccess):
            return { ...state, fares: action.payload, isError: false, isFallbackFares: false, isLoading: false };
        case getType(actions.fetchFaresSuccessFallback):
            return { ...state, fares: action.payload, isError: false, isFallbackFares: true, isLoading: false };
        default:
            return state;
    }
}

export function fetchFareTickerFares({
    airportCode
}: {
    airportCode?: string;
}): ThunkAction<Promise<FareTickerAction>, StoreWithFareTickerState, void, FareTickerAction> {
    return async (
        dispatch: ThunkDispatch<StoreWithFareTickerState, void, FareTickerAction>,
        getState: () => StoreWithFareTickerState
    ) => {
        const maxFares = getMaxFareTickerFares(getState());
        dispatch(actions.fetchFares());

        try {
            if (airportCode) {
                const requestedFares = await getFareTickerFaresByAirportCode({ airportCode, maxFares });

                if (!requestedFares.length) {
                    const fallbackFares = await getFareTickerDefaultFares(maxFares);
                    return dispatch(actions.fetchFaresSuccessFallback(fallbackFares));
                }
                return dispatch(actions.fetchFaresSuccess(requestedFares));
            } else {
                const defaultFares = await getFareTickerDefaultFares(maxFares);
                return dispatch(actions.fetchFaresSuccess(defaultFares));
            }
        } catch (e) {
            return dispatch(actions.fetchFaresFailure());
        }
    };
}

export function getMaxFareTickerFares(state: StoreWithFareTickerState): number {
    return state.fareTicker.maxFares;
}

export function getFareTickerFares(state: StoreWithFareTickerState): Fare[] {
    return state.fareTicker.fares;
}

export function isFareTickerInError(state: StoreWithFareTickerState): boolean {
    return state.fareTicker.isError;
}

export function isFareTickerShowingFallbackFares(state: StoreWithFareTickerState): boolean {
    return state.fareTicker.isFallbackFares;
}

export function isFareTickerLoading(state: StoreWithFareTickerState): boolean {
    return state.fareTicker.isLoading;
}
