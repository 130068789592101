import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from 'redux';
import { StoreWithAirSearchDataState, actions as airSearchDataActions, getAirSearchData } from 'ducks/airSearchData';
import { fetchFareTickerFares, StoreWithFareTickerState } from 'ducks/fareTicker';
import { getType } from 'typesafe-actions';
import { ThunkDispatch } from 'redux-thunk';

type FareTickerMiddlewareState = StoreWithAirSearchDataState & StoreWithFareTickerState;
type FareTickerMiddlewareAPI = MiddlewareAPI<
    ThunkDispatch<FareTickerMiddlewareState, void, AnyAction>,
    FareTickerMiddlewareState
>;

export default function fareTickerMiddleware(): Middleware {
    return (store: FareTickerMiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
        const prevState = store.getState(),
            prevLocation1 = getAirSearchData(prevState).location1,
            prevLocation1AirportCode = prevLocation1?.suggestion.airportCode,
            result = next(action),
            nextState = store.getState(),
            nextLocation1 = getAirSearchData(nextState).location1,
            nextLocation1AirportCode = nextLocation1?.suggestion.airportCode;

        // If location1 has changed (or if it's unset, e.g. on page load), fetch new fares
        if (
            action.type === getType(airSearchDataActions.updateAirSearchDataWithoutUpdatingCookie) &&
            (!nextLocation1AirportCode || prevLocation1AirportCode !== nextLocation1AirportCode)
        ) {
            store.dispatch(fetchFareTickerFares({ airportCode: nextLocation1AirportCode }));
        }
        return result;
    };
}
