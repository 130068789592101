import React from 'react';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

import FareViewProps from '../shared/props';
import FareHighlights from '../shared/components/FareHighlights';

import './styles.scss';

interface FareCardProps extends FareViewProps {
    withBorder?: boolean;
    pricePromoNote?: FormattedMessage.MessageDescriptor;
}

const FareCard = ({ fare, fareInstance, withBorder = true, currency, pricePromoNote }: FareCardProps) => {
    return (
        <div className={`fare-card ${withBorder ? 'fare-card--with-border' : ''}`}>
            <FareHighlights
                fare={fare}
                fareInstance={fareInstance || fare.fareInstances[0]}
                currency={currency}
                pricePromoNote={pricePromoNote}
            />
        </div>
    );
};

export default injectIntl(FareCard);
