import React from 'react';
import { SearchFormProps } from '../props';
import { Suggestion, SuggestionDecorator } from 'models/Suggestion';
import { InputWithAutocomplete } from 'components/Input';
import { AwdIconName } from 'components/AwdIcon';
import msgs from '../messages';
import { SearchFormField } from '../fields';
import { FormattedMessage } from 'react-intl';

type LocationFieldSearchFormProps<T extends Suggestion> = Pick<
    SearchFormProps<T>,
    | 'insetLabels'
    | 'getLocationSuggestions'
    | 'getOnLocationBlurHandler'
    | 'getOnLocationChangeHandler'
    | 'fieldsInError'
>;

interface LocationFieldProps<T extends Suggestion> extends LocationFieldSearchFormProps<T> {
    containerClassNames?: string[];
    fieldClassNames?: string[];
    location?: SuggestionDecorator<T>;
    label?: FormattedMessage.Props;
    placeholder?: FormattedMessage.Props;
    searchFormField: SearchFormField.LOCATION1 | SearchFormField.LOCATION2;
}

export default function LocationField<T extends Suggestion>({
    containerClassNames,
    fieldClassNames,
    fieldsInError,
    getLocationSuggestions,
    getOnLocationBlurHandler,
    getOnLocationChangeHandler,
    insetLabels,
    location,
    label,
    searchFormField,
    placeholder
}: LocationFieldProps<T>) {
    const isLocation1 = searchFormField === SearchFormField.LOCATION1;

    if (!placeholder) {
        placeholder = isLocation1 ? msgs.location1Placeholder : msgs.location2Placeholder;
    }

    return (
        <div className={`search-form__field-container ${containerClassNames ? containerClassNames.join(' ') : ''}`}>
            {!insetLabels && (
                <div className='search-form__field-label'>
                    <FormattedMessage {...(label || msgs.location1Label)} />
                </div>
            )}
            <div
                className={`search-form__field search-form__field${isLocation1 ? 1 : 2} ${
                    fieldClassNames ? fieldClassNames.join(' ') : ''
                }`}
            >
                <InputWithAutocomplete
                    suggestion={location}
                    iconName={AwdIconName.Pin}
                    label={insetLabels ? label || msgs.location1Label : undefined}
                    getSuggestions={getLocationSuggestions}
                    onBlur={getOnLocationBlurHandler(searchFormField)}
                    onSuggestionSelected={getOnLocationChangeHandler(searchFormField)}
                    inError={fieldsInError.includes(searchFormField)}
                    key={location && location.text}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
}
