import React from 'react';
import { FormattedMessage, InjectedIntl } from 'react-intl';
import { FareInstance } from 'models/Fares';
import msgs from '../messages';

export default function getFormattedDates({ intl, fareInstance }: { intl: InjectedIntl; fareInstance: FareInstance }) {
    const isFlexibleDates = fareInstance.isFlexibleDates,
        date1 = fareInstance.departureDate && fareInstance.departureDate.toDate(),
        date2 = fareInstance.returnDate && fareInstance.returnDate.toDate(),
        formatDate = (date: Date, includeWeekday: boolean) =>
            date &&
            intl.formatDate(date, {
                month: 'short',
                day: 'numeric',
                weekday: includeWeekday ? 'short' : undefined
            });
    let datesMessage;

    if (date1 && date2) {
        if (isFlexibleDates) {
            datesMessage = (
                <FormattedMessage
                    {...msgs.dateRange}
                    values={{ date1: formatDate(date1, false), date2: formatDate(date2, false) }}
                />
            );
        } else {
            datesMessage = (
                <FormattedMessage
                    {...msgs.dateRange}
                    values={{ date1: formatDate(date1, true), date2: formatDate(date2, true) }}
                />
            );
        }
    } else if (date1) {
        datesMessage = (
            <FormattedMessage {...msgs.dateInfoFlexibleDate1Only} values={{ date1: formatDate(date1, false) }} />
        );
    } else if (date2) {
        datesMessage = (
            <FormattedMessage {...msgs.dateInfoFlexibleDate2Only} values={{ date2: formatDate(date2, false) }} />
        );
    } else {
        datesMessage = <FormattedMessage {...msgs.dateInfoFlexibleVaries} />;
    }

    return datesMessage;
}
