import React from 'react';
import { FareInstance } from 'models/Fares';
import { getDescriptorFareTags } from 'utils/fares/fareAttributes';

import './styles.scss';

export default function FareViewDescriptorTags({ fareInstance }: { fareInstance: FareInstance }) {
    const descriptorFareTags = getDescriptorFareTags(fareInstance.fareAttributes).slice(0, 2);

    return (
        <div className='fare-view-descriptor-tags'>
            {descriptorFareTags.map((tag, idx) => {
                return (
                    <React.Fragment key={tag.code}>
                        {idx > 0 && <span className='fare-row__tag-separator'>|</span>}
                        <span className='fare-row__tag'>{tag.shortDescription}</span>
                    </React.Fragment>
                );
            })}
        </div>
    );
}
