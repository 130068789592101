import React from 'react';
import { SearchFormProps, SearchFormStyle } from '../props';
import { Suggestion } from 'models/Suggestion';
import FlightTypeInput from 'components/FlightTypeInput';
import { RadioInputTheme } from 'components/RadioInput';
import NumTravelersInput from 'components/NumTravelersInput';

type OptionsRowSearchFormProps<T extends Suggestion> = Pick<
    SearchFormProps<T>,
    'onFlightTypeChange' | 'onTravelersChange' | 'searchData' | 'style' | 'useShortFlightTypeLabels'
>;

interface OptionsRowProps<T extends Suggestion> extends OptionsRowSearchFormProps<T> {
    containerClassNames?: string[];
    fieldClassNames?: string[];
}

export default function OptionsRow<T extends Suggestion>({
    containerClassNames,
    fieldClassNames,
    onFlightTypeChange,
    onTravelersChange,
    searchData,
    style,
    useShortFlightTypeLabels
}: OptionsRowProps<T>) {
    const fieldClassNamesStr = fieldClassNames ? fieldClassNames.join(' ') : '',
        { flightSearchType, travelers } = searchData;

    return (
        <div className={`search-form__options ${containerClassNames ? containerClassNames.join(' ') : ''}`}>
            <div className={`search-form__option search-form__option__flight-type ${fieldClassNamesStr}`}>
                <FlightTypeInput
                    useShortLabels={useShortFlightTypeLabels}
                    value={flightSearchType}
                    onChange={onFlightTypeChange}
                    radioTheme={style === SearchFormStyle.WHITE ? RadioInputTheme.Blue : RadioInputTheme.Default}
                />
            </div>
            <div className={`search-form__option search-form__option__num-travelers ${fieldClassNamesStr}`}>
                <NumTravelersInput value={travelers} onChange={onTravelersChange} />
            </div>
        </div>
    );
}
