import { defineMessages } from 'react-intl';

export default defineMessages({
    to: {
        id: 'FareHighlights_to',
        defaultMessage: 'to'
    },
    cta: {
        id: 'FareHighlights_cta',
        defaultMessage: 'Find This Deal'
    }
});
