import { defineMessages } from 'react-intl';

export default defineMessages({
    headerLine1: {
        id: 'HomePage_headerLine1',
        defaultMessage: 'Find Cheap Flights'
    },
    headerLine2: {
        id: 'HomePage_headerLine2',
        defaultMessage: "See today's prices to your favorite destinations"
    },
    headerMobile: {
        id: 'HomePage_headerMobile',
        defaultMessage: 'Find Cheap Flights'
    },
    searchModeFlightToggle: {
        id: 'HomePage_searchModeFlightToggle',
        defaultMessage: 'Flights'
    },
    searchModeHotelToggle: {
        id: 'HomePage_searchModeHotelToggle',
        defaultMessage: 'Hotels'
    },
    exactDateLocation1Label: {
        id: 'HomePage_exactDateLocation1Label',
        defaultMessage: 'Traveling from'
    },
    exactDateLocation2Label: {
        id: 'HomePage_exactDateLocation2Label',
        defaultMessage: 'Traveling to'
    },
    exactDateDatesLabel: {
        id: 'HomePage_exactDateDatesLabel',
        defaultMessage: 'Travel dates'
    },
    exactDateEmailFieldPlaceholder: {
        id: 'HomePage_exactDateEmailFieldPlaceholder',
        defaultMessage: 'Enter your email address'
    },
    exactDateButtonText: {
        id: 'HomePage_exactDateButtonText',
        defaultMessage: 'Show Deals'
    },
    noFaresLine1: {
        id: 'HomePage_noFaresLine1',
        defaultMessage: "We didn't find any great deals from this airport today."
    },
    noFaresLine2: {
        id: 'HomePage_noFaresLine2',
        defaultMessage: 'Try another airport or check back soon!'
    },
    topFaresCTA: {
        id: 'HomePage_topFaresCTA',
        defaultMessage: "Deals don't always last. Search quick!"
    },
    topFaresHeaderLine1: {
        id: 'HomePage_topFaresHeaderLine1',
        defaultMessage: 'We Find The Best Flight Deals'
    },
    topFaresHeaderLine2: {
        id: 'HomePage_topFaresHeaderLine2',
        defaultMessage: "Here's what we found today from"
    },
    topFaresLocationPlaceholder: {
        id: 'HomePage_topFaresLocationPlaceholder',
        defaultMessage: 'Select Departure City'
    },
    topFaresHeaderTooltip: {
        id: 'HomePage_topFaresHeaderTooltip',
        defaultMessage: 'Refreshed Daily'
    },
    seeAllFares: {
        id: 'HomePage_seeAllFares',
        defaultMessage: "See all of today's flight deals"
    },
    seeAllFaresFrom: {
        id: 'HomePage_seeAllFaresFromLocation',
        defaultMessage: "See all of today's flight deals from"
    }
});
