import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import FareViewProps from 'components/FareView/shared/props';
import { hideSelector, selectFareInstance, showSelector } from '../shared/utils/fareInstanceSelectorReducer';
import openFareLink from '../shared/utils/openFareLink';
import FareHighlights from '../shared/components/FareHighlights';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import msgs from './messages';

import './styles.scss';
import getFormattedDates from 'components/FareView/shared/utils/getFormattedDates';
import OutsideClickHandler from 'components/OutsideClickHandler';
import FareInstanceSelector, { FareInstanceSelectorType } from 'components/FareInstanceSelector';
import getUnselectedFareInstances from 'components/FareView/shared/utils/getUnselectedFareInstances';
import { FareInstance } from 'models/Fares';
import FareDates from '../shared/components/FareDates';
import useFareInstanceSelectorReducer from 'components/FareView/shared/hooks/useFareInstanceSelectorReducer';
import FareDuration from 'components/FareDuration';

function FareCol({ fare, fareInstance, currency, intl }: FareViewProps) {
    const [selectorState, selectorDispatch] = useFareInstanceSelectorReducer(fareInstance || fare.fareInstances[0]),
        { selectedFareInstance, selectorVisible } = selectorState,
        { departureDate, returnDate, airlines, priceConfidenceAttribute } = selectedFareInstance,
        airlinesStr = airlines.map(a => a.displayName).join(', '),
        duration = departureDate && returnDate && returnDate.diff(departureDate, 'day'),
        showDuration = duration !== undefined && duration !== null && duration >= 0;

    return (
        // There are anchor tags within this container element, so we can't also use an anchor tag here
        <div
            className='fare-col do_no_popunder'
            onClick={() => openFareLink({ fareInstance: selectedFareInstance, fareDetailsForFlexFares: false })}
        >
            <div className={'fare-col__panel'}>
                <FareHighlights fare={fare} fareInstance={selectedFareInstance} currency={currency} />
            </div>
            <div className={'fare-col__panel fare-col__details'}>
                <div className={'fare-col__detail fare-col__dates'}>
                    <span className={'fare-col__icon'}>
                        <AwdIcon name={AwdIconName.Calendar} />{' '}
                    </span>
                    <span className={'fare-col__dates-wrapper  do_no_popunder'}>
                        <FareDates
                            fare={fare}
                            selectorState={selectorState}
                            onMultiDateClicked={() => {
                                selectorDispatch(showSelector());
                            }}
                        />
                    </span>

                    {selectorVisible && (
                        <OutsideClickHandler onOutsideClick={() => selectorDispatch(hideSelector())}>
                            <div
                                className='fare-row__more-fares-modal do_no_popunder'
                                onClick={e => e.stopPropagation()}
                            >
                                <FareInstanceSelector
                                    fareInstances={getUnselectedFareInstances({
                                        fareInstances: fare.fareInstances,
                                        selectedFareInstance
                                    })}
                                    getFormattedDates={fareInstance => getFormattedDates({ intl, fareInstance })}
                                    listText={msgs.seeFareDetails}
                                    onClose={() => selectorDispatch(hideSelector())}
                                    onSelect={(i: FareInstance) => {
                                        selectorDispatch(selectFareInstance(i));
                                        selectorDispatch(hideSelector());
                                    }}
                                    selectedFareInstance={selectedFareInstance}
                                    type={FareInstanceSelectorType.MODAL}
                                />
                            </div>
                        </OutsideClickHandler>
                    )}
                </div>
                {showDuration && (
                    <div className={'fare-col__detail fare-col__duration'}>
                        <span className={'fare-col__icon fare-col__icon--duration'}>
                            <AwdIcon name={AwdIconName.Refresh} />{' '}
                        </span>
                        <span className={'fare-col__duration__str'}>
                            <FareDuration fareInstance={selectedFareInstance} />
                        </span>
                    </div>
                )}
                <h5 className={'fare-col__detail fare-col__airlines'}>
                    <span className={'fare-col__icon'}>
                        <AwdIcon name={AwdIconName.PlaneFromAboveUp} />
                    </span>
                    <span className={'fare-col__airline__str'}>{airlinesStr}</span>
                    <span />
                </h5>
                <div className='fare-col__see-details'>
                    <a
                        href={selectedFareInstance.fareUrl}
                        target='_blank'
                        rel='noopener'
                        onClick={e => e.stopPropagation()}
                    >
                        <FormattedMessage {...msgs.seeFareDetails} />
                    </a>
                </div>
            </div>
            {priceConfidenceAttribute && (
                <div className={'fare-col__panel fare-col__pca'}>{priceConfidenceAttribute.longDescription}</div>
            )}
        </div>
    );
}

export default injectIntl(FareCol);
