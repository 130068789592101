import React from 'react';
import xorBy from 'lodash/xorBy';

import DropdownFilterWrapper, { DropdownFilterWrapperStyle } from '../DropdownWrapper';
import FilterOptionsList, { FilterOptionId, FilterOptionsListProps } from '../OptionsList';
import { AwdIconName } from 'components/AwdIcon';

interface DropdownFilterProps<T extends FilterOptionId> extends FilterOptionsListProps<T> {
    buttonPlaceholder?: string;
    filterLabel: string;
    filterStyle?: DropdownFilterWrapperStyle;
    filterFieldIconName?: AwdIconName;
    onClearFilter(): void;
}

/**
 * A component encapsulating a filter button, dropdown modal, and list of options.
 */
export default class DropdownFilter<T extends FilterOptionId> extends React.Component<DropdownFilterProps<T>> {
    public render = () => {
        const {
                filterFieldIconName,
                filterLabel,
                filterStyle,
                isMultiSelect,
                onClearFilter,
                onOptionChanged,
                onSelectAll,
                options,
                otherOptions,
                selectedOptions,
                showSelectAll
            } = this.props,
            isFilterSet = this.isFilterSet(),
            buttonLabel = this.getButtonLabel();

        return (
            <DropdownFilterWrapper
                isFilterSet={isFilterSet}
                buttonLabel={buttonLabel}
                filterFieldIconName={filterFieldIconName}
                filterLabel={filterLabel}
                filterStyle={filterStyle}
                onClearFilter={onClearFilter}
            >
                {(closeModal: () => void) => (
                    <FilterOptionsList
                        isMultiSelect={isMultiSelect}
                        options={options}
                        otherOptions={otherOptions}
                        selectedOptions={selectedOptions}
                        showSelectAll={showSelectAll}
                        closeModal={closeModal}
                        onClearFilter={onClearFilter}
                        onOptionChanged={onOptionChanged}
                        onSelectAll={onSelectAll}
                    />
                )}
            </DropdownFilterWrapper>
        );
    };

    private getAllOptions = () => {
        return this.props.options.concat(this.props.otherOptions || []);
    };

    private getButtonLabel = () => {
        const { buttonPlaceholder, filterLabel, selectedOptions } = this.props;

        if (!this.isFilterSet()) {
            if (buttonPlaceholder) {
                return buttonPlaceholder;
            }

            return filterLabel;
        }

        let setFilterLabel = selectedOptions[0].description;

        if (selectedOptions.length > 1) {
            setFilterLabel += ', ...';
        }

        return setFilterLabel;
    };

    private isFilterSet = () => {
        const allOptionsSelected = !xorBy(this.props.selectedOptions, this.getAllOptions(), 'id').length;

        return !!this.props.selectedOptions.length && !allOptionsSelected;
    };
}
