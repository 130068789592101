import { AirportSuggestion, CitySuggestion, SuggestionDecorator } from 'models/Suggestion';
import LocationSuggestError from 'models/Errors/LocationSuggestError';

const BASE_PATH = '/location/',
    AIRPORTS_PATH = `${BASE_PATH}airport/`,
    CITIES_PATH = `${BASE_PATH}city/`;

/**
 * Front end representation of the LocationSuggestType.java enum
 */
export enum LocationSuggestType {
    AIRPORTS_ALL = 'airports_all',
    AIRPORTS_DOMESTIC = 'airports_domestic',
    AIRPORTS_INTERNATIONAL = 'airports_international'
}

/**
 * Get suggestions for airports that match the provided string
 *
 * @param query
 * @param searchType
 */
export async function getAirportSuggestions({
    query,
    searchType = LocationSuggestType.AIRPORTS_ALL
}: {
    query: string;
    searchType?: LocationSuggestType;
}): Promise<Array<SuggestionDecorator<AirportSuggestion>>> {
    const res = await fetch(`${AIRPORTS_PATH}?query=${query}&search_type=${searchType}`);

    if (!res.ok) {
        throw new LocationSuggestError(`Failed to get airport suggestions for "${query}"`);
    }

    const data = (await res.json()) as AirportSuggestion[];

    return data.map(d => ({ text: d.displayName, suggestion: d }));
}

/**
 * Get suggestions for cities that match the provided string
 *
 * @param query
 * @param searchType
 */
export async function getCitySuggestions({
    query
}: {
    query: string;
}): Promise<Array<SuggestionDecorator<CitySuggestion>>> {
    const res = await fetch(`${CITIES_PATH}?query=${query}`);

    if (!res.ok) {
        throw new LocationSuggestError(`Failed to get city suggestions for "${query}"`);
    }

    const data = (await res.json()) as CitySuggestion[];

    return data.map(d => ({ text: d.cityFullLocation, suggestion: d }));
}
