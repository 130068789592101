import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { getHotelSearchData } from 'ducks/hotelSearchData';
import { getSubscriber } from 'ducks/user';
import { HotelSearchFormResponsive } from './index';
import { getCitySuggestions } from 'api/locationSuggest';
import SearchData, { isSearchDataWithLocation2 } from 'models/SearchData';
import { CitySuggestion } from 'models/Suggestion';
import { Subscriber } from 'models/Subscriptions';
import {
    HotelSearchFormState,
    openHotelListingPageAndSubscribeUser,
    updateHotelSearchDataAndQueueSubscriber
} from 'ducks/hotelSearchForm';

/*
 * This file contains "connected" components, which are hotel search forms that have been connected to the redux store
 * in order to get and update search data and subscriber info and launch tab browsing on form submission.
 */

const getLocationSuggestions = async (query: string) => {
    return getCitySuggestions({ query });
};

const mapStateToProps = (state: HotelSearchFormState) => ({
    getLocationSuggestions,
    searchData: getHotelSearchData(state),
    subscriber: getSubscriber(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<HotelSearchFormState, void, AnyAction>) => ({
    onChange: ({ searchData, subscriber }: { searchData?: SearchData<CitySuggestion>; subscriber?: Subscriber }) =>
        dispatch(updateHotelSearchDataAndQueueSubscriber({ searchData, subscriber })),
    onFormSubmit: ({
        searchData,
        subscribeUser,
        subscriber
    }: {
        searchData: SearchData<CitySuggestion>;
        subscribeUser: boolean;
        subscriber?: Subscriber | null;
    }) => {
        if (!isSearchDataWithLocation2(searchData)) {
            return;
        }
        return dispatch(
            openHotelListingPageAndSubscribeUser({
                searchData,
                subscribeUser,
                subscriber
            })
        );
    }
});

export const ConnectedHotelSearchFormResponsive = connect(
    mapStateToProps,
    mapDispatchToProps
)(HotelSearchFormResponsive);
