import { useEffect } from 'react';
import { executePageRenderedCallbacks } from 'utils/callbacks';

/**
 * Hook to execute page rendered callbacks once a component mounts
 */
export default function usePageRenderedCallbacks() {
    useEffect(() => {
        executePageRenderedCallbacks();
    }, []); // This empty array makes it so that this hook will only load when component mounts
}
