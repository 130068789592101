import React from 'react';
import { SearchFormProps } from '../props';
import { Suggestion } from 'models/Suggestion';
import { InputFreeText } from 'components/Input';
import { AwdIconName } from 'components/AwdIcon';
import msgs from '../messages';
import { Colors } from 'constants/colors';
import Autocomplete from 'constants/autocomplete';

type EmailFieldSearchFormProps<T extends Suggestion> = Pick<
    SearchFormProps<T>,
    'subscriber' | 'emailFieldPlaceholder' | 'onEmailBlur'
>;

interface EmailFieldProps<T extends Suggestion> extends EmailFieldSearchFormProps<T> {
    containerClassNames?: string[];
    fieldClassNames?: string[];
}

export default function EmailField<T extends Suggestion>({
    containerClassNames,
    fieldClassNames,
    subscriber,
    emailFieldPlaceholder,
    onEmailBlur
}: EmailFieldProps<T>) {
    const iconColor = Colors.Razzmatazz; // razzmatazz

    return (
        <div className={`search-form__field-container ${containerClassNames ? containerClassNames.join(' ') : ''}`}>
            <div
                className={`search-form__field search-form__field4 ${fieldClassNames ? fieldClassNames.join(' ') : ''}`}
            >
                <InputFreeText
                    value={subscriber && subscriber.emailAddress}
                    iconName={AwdIconName.EnvelopeWithLines}
                    placeholder={emailFieldPlaceholder || msgs.emailPlaceholder}
                    onBlur={onEmailBlur}
                    iconColor={iconColor}
                    autoComplete={Autocomplete.EMAIL}
                />
            </div>
        </div>
    );
}
