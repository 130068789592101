import { defineMessages } from 'react-intl';

export default defineMessages({
    twitterCardTitle: {
        id: 'RailCard_twitterCardTitle',
        defaultMessage: 'Sweet Tweets'
    },
    twitterCardDescription: {
        id: 'RailCard_twitterCardDescription',
        defaultMessage: 'Follow us on Twitter to see our best flight deals'
    },
    twitterCardButton: {
        id: 'RailCard_twitterCardButton',
        defaultMessage: 'See Today’s Tweets'
    }
});
