import { FareInstance } from 'models/Fares';

export interface FareInstanceSelectorState {
    selectorVisible: boolean;
    selectedFareInstance: FareInstance;
    isUserSelectedFareInstance: boolean;
}

enum FareInstanceSelectorActionType {
    SHOW_SELECTOR,
    HIDE_SELECTOR,
    SELECT_FARE_INSTANCE
}

export type FareInstanceSelectorDispatch = (action: FareInstanceSelectorAction) => void;

type FareInstanceSelectorAction =
    | { type: FareInstanceSelectorActionType.SHOW_SELECTOR }
    | { type: FareInstanceSelectorActionType.HIDE_SELECTOR }
    | { type: FareInstanceSelectorActionType.SELECT_FARE_INSTANCE; fareInstance: FareInstance };

// Action factories:
export function hideSelector(): FareInstanceSelectorAction {
    return { type: FareInstanceSelectorActionType.HIDE_SELECTOR };
}

export function showSelector(): FareInstanceSelectorAction {
    return { type: FareInstanceSelectorActionType.SHOW_SELECTOR };
}

export function selectFareInstance(fareInstance: FareInstance): FareInstanceSelectorAction {
    return { type: FareInstanceSelectorActionType.SELECT_FARE_INSTANCE, fareInstance };
}

// Reducer
export default function fareInstanceSelectorReducer(
    state: FareInstanceSelectorState,
    action: FareInstanceSelectorAction
): FareInstanceSelectorState {
    switch (action.type) {
        case FareInstanceSelectorActionType.SHOW_SELECTOR:
            return { ...state, selectorVisible: true };
        case FareInstanceSelectorActionType.HIDE_SELECTOR:
            return { ...state, selectorVisible: false };
        case FareInstanceSelectorActionType.SELECT_FARE_INSTANCE:
            return { ...state, selectedFareInstance: action.fareInstance, isUserSelectedFareInstance: true };
    }
}
