import 'react-dates/initialize';
import React from 'react';
import moment from 'moment';
import { SingleDatePicker as SDP } from 'react-dates';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';

import './styles.scss';
import { MomentDate } from 'models/Date';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import { withSharedMethods } from '../common/withSharedMethods';
import { InjectedDatePickerProps } from '../common/props';

interface DatePickerSingleProps extends InjectedDatePickerProps {
    placeholder: FormattedMessage.MessageDescriptor;
    dateFormat: string;
    inError?: boolean;
    date?: MomentDate;
    onDateChange?: (date: MomentDate) => void;
    id?: string; // This id is applied to the input.
    numberOfMonths?: number;
    intl: InjectedIntl; // Prop automatically injected by injectIntl: https://github.com/yahoo/react-intl/wiki/API#injectintl
}

interface DatePickerSingleState {
    date: MomentDate;
    isFocused: boolean;
    prevMonthDisabled: boolean;
    nextMonthDisabled: boolean;
}

class DatePickerSingle extends React.Component<DatePickerSingleProps, DatePickerSingleState> {
    public state: DatePickerSingleState = {
        date: this.props.date || null,
        isFocused: false,
        prevMonthDisabled: false,
        nextMonthDisabled: false
    };

    public render() {
        const { date, isFocused, prevMonthDisabled, nextMonthDisabled } = this.state,
            { intl, id, dateFormat, numberOfMonths, placeholder, isOutsideRange, inError } = this.props,
            classNames = [
                inError === undefined || !inError ? '' : 'in-error',
                prevMonthDisabled ? 'prev-month-disabled' : '',
                nextMonthDisabled ? 'next-month-disabled' : ''
            ];

        return (
            <span className={`date-picker-single ${classNames.join(' ')}`}>
                <SDP
                    // Required props:
                    date={date}
                    id={id || 'DPS'}
                    focused={isFocused}
                    onDateChange={this.onDateChange}
                    onFocusChange={this.onFocusChange}
                    // Input related props:
                    readOnly={true}
                    placeholder={intl.formatMessage(placeholder)}
                    customInputIcon={<AwdIcon name={AwdIconName.Calendar} />}
                    // calendar presentation and interaction related props:
                    numberOfMonths={numberOfMonths || 1}
                    hideKeyboardShortcutsPanel={true}
                    // Day presentation and interaction related props:
                    isOutsideRange={isOutsideRange}
                    onPrevMonthClick={this.onMonthChange}
                    onNextMonthClick={this.onMonthChange}
                    // Internationalization props:
                    displayFormat={dateFormat}
                />
            </span>
        );
    }

    private onDateChange = (date: MomentDate) => {
        this.setState({ date });
        this.props.onDateChange && this.props.onDateChange(date);
    };

    private onFocusChange = ({ focused }: { focused: boolean | null }) => {
        const isFocused = !!focused;
        this.setState({ isFocused });

        if (isFocused) {
            this.onMonthChange(this.props.date || moment());
        }
    };

    // TODO: Once https://github.com/airbnb/react-dates/pull/1727 is merged,
    // remove this logic and custom prevMonthDisabled/nextMonthDisabled styling, and switch
    // to passing through minDate and maxDate props (see DateRange component).
    private onMonthChange = (newCurrentMonth: moment.Moment) => {
        const { isOutsideRange } = this.props,
            prevMonth = newCurrentMonth
                .clone()
                .subtract(1, 'month')
                .endOf('month'),
            nextMonth = newCurrentMonth
                .clone()
                .add(1, 'month')
                .startOf('month');
        let prevMonthDisabled = false,
            nextMonthDisabled = false;

        if (isOutsideRange(prevMonth)) {
            prevMonthDisabled = true;
        }

        if (isOutsideRange(nextMonth)) {
            nextMonthDisabled = true;
        }

        this.setState({ prevMonthDisabled, nextMonthDisabled });
    };
}

export default withSharedMethods(injectIntl(DatePickerSingle));
