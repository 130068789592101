import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ActionType, createAction, getType } from 'typesafe-actions';

import SearchData from 'models/SearchData';
import { CitySuggestion } from 'models/Suggestion';
import { setSearchDataCookie } from 'utils/searchData/cookie';
import { ExternalSearchData } from 'models/incomingData/SearchData';
import { translateBaseExternalSearchData } from './airSearchData';
import SearchType from 'models/SearchData/enums/SearchType';

// Contains redux functions for hotel search data.

export type HotelSearchDataState = Readonly<SearchData<CitySuggestion>>;

export interface StoreWithHotelSearchDataState {
    hotelSearchData: HotelSearchDataState;
}

// Define actions:
export const actions = {
    // now that this is exported, we want to make it clear that this doesn't update the search data cookie
    // which is really only useful if the cookie has already been updated by, say, the flightjs app
    updateHotelSearchDataWithoutUpdatingCookie: createAction('awd/hotelSearchData/UPDATE', resolve => {
        return (hotelSearchData: SearchData<CitySuggestion>) => resolve({ hotelSearchData });
    })
};
export type HotelSearchDataAction = ActionType<typeof actions>;

export default function reducer(
    state: HotelSearchDataState = { searchType: SearchType.HOTEL },
    action: HotelSearchDataAction
): Readonly<HotelSearchDataState> {
    if (action.type === getType(actions.updateHotelSearchDataWithoutUpdatingCookie)) {
        return action.payload.hotelSearchData;
    } else {
        return state;
    }
}

// Selectors:
export function getHotelSearchData(state: StoreWithHotelSearchDataState): SearchData<CitySuggestion> {
    return state.hotelSearchData;
}

// Action Creators:
export function updateHotelSearchData(
    searchData: SearchData<CitySuggestion>
): ThunkAction<HotelSearchDataAction, StoreWithHotelSearchDataState, void, HotelSearchDataAction> {
    setSearchDataCookie(searchData);

    return (dispatch: ThunkDispatch<StoreWithHotelSearchDataState, void, HotelSearchDataAction>) => {
        return dispatch(actions.updateHotelSearchDataWithoutUpdatingCookie(searchData));
    };
}

export function importExternalHotelSearchDataWithCity(
    externalSearchData: ExternalSearchData
): ThunkAction<HotelSearchDataAction, StoreWithHotelSearchDataState, void, HotelSearchDataAction> {
    return (dispatch: ThunkDispatch<StoreWithHotelSearchDataState, void, HotelSearchDataAction>) => {
        const searchData = translateExternalHotelSearchDataWithCities(externalSearchData);
        return dispatch(actions.updateHotelSearchDataWithoutUpdatingCookie(searchData));
    };
}

/**
 * Translates the {@link ExternalSearchData} entity that is used by the backend
 * to the {@link SearchData} entity primarily used by the frontend.
 *
 * @param data
 */
export function translateExternalHotelSearchDataWithCities(data: ExternalSearchData): SearchData<CitySuggestion> {
    let city2;

    if (data.location2CityId) {
        const { location2CityDisplayName, location2CityId, location2Name } = data;

        city2 = {
            text: location2CityDisplayName!,
            suggestion: {
                id: location2CityId!,
                cityName: location2CityDisplayName!,
                cityFullLocation: location2Name!
            }
        };
    }

    return {
        ...translateBaseExternalSearchData(data),
        location2: city2,
        // On pages where both flight and hotel searches exist, we may be translating external air search data, but
        // want to make sure we always end up with a searchType of hotel for hotel search data.
        searchType: SearchType.HOTEL
    };
}
