import React from 'react';
import './styles.scss';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import Button from 'components/Button';

import { FormattedMessage } from 'react-intl';

export interface RailCardProps {
    readonly iconName: AwdIconName;
    readonly title: FormattedMessage.MessageDescriptor;
    readonly description: FormattedMessage.MessageDescriptor;
    readonly buttonText: FormattedMessage.MessageDescriptor;
    readonly linkUrl: string;
}

export default class RailCard extends React.Component<RailCardProps> {
    public render() {
        const { iconName, title, description, buttonText, linkUrl } = this.props;
        return (
            <a className='rail-card' href={linkUrl} target='_blank' rel='noopener'>
                <div className='rail-card__icon-wrapper'>
                    <AwdIcon name={iconName} />
                </div>
                <div className='rail-card__title'>
                    <FormattedMessage {...title} />
                </div>
                <div className='rail-card__description'>
                    <FormattedMessage {...description} />
                </div>
                <div className='rail-card__button-wrapper'>
                    <Button text={buttonText} />
                </div>
            </a>
        );
    }
}
