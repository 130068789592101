import React from 'react';
import { Fare, FareInstance } from 'models/Fares';
import { getSpecialFareTags } from 'utils/fares/fareAttributes';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import msgs from './messages';

import './styles.scss';
import { FareAttributeTagContainer } from 'components/FareAttributeTag/container';
import { CurrencyCode } from 'models/Currency';

export default function PriceSection({
    fare,
    currency,
    fareInstance,
    promoNote,
    priceFontSizePx = 40,
    renderWithToolTipExpanded = false
}: {
    fare: Fare;
    currency: CurrencyCode;
    fareInstance: FareInstance;
    promoNote?: FormattedMessage.MessageDescriptor;
    priceFontSizePx?: number;
    renderWithToolTipExpanded?: boolean;
}) {
    const specialFareTag = getSpecialFareTags(fareInstance.fareAttributes)[0],
        priceConfidenceAttribute = fareInstance.priceConfidenceAttribute,
        price = fare.prices[currency] || fare.price,
        priceFontSize = `${priceFontSizePx}px`;

    return (
        <div className='fare-view-price-section'>
            <div className='fare-view-price-section__promo'>
                {promoNote && (
                    <span>
                        <FormattedMessage {...promoNote} />
                    </span>
                )}
                {!promoNote && specialFareTag && <span>{specialFareTag.shortDescription}</span>}
            </div>
            <div
                style={{ fontSize: priceFontSize, height: priceFontSize, lineHeight: priceFontSize }}
                className={`fare-view-price-section__price-container ${
                    renderWithToolTipExpanded ? 'fare-view-price-section__price-container-large' : ''
                }`}
            >
                <span className='fare-view-price-section__price'>
                    <FormattedNumber
                        value={price}
                        currency={currency || fare.currency}
                        currencyDisplay={'symbol'}
                        style={'currency'}
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                    />
                </span>
                {fare.isRoundTrip && (
                    <span
                        className={`fare-view-price-section__rt ${
                            renderWithToolTipExpanded ? 'fare-view-price-section__rt-large' : ''
                        }`}
                    >
                        <FormattedMessage {...msgs.roundTrip} />
                    </span>
                )}
            </div>
            {priceConfidenceAttribute && (
                <div
                    className={`fare-view-price-section__price-attribute ${
                        renderWithToolTipExpanded ? 'fare-view-price-section__price-attribute-margin' : ''
                    }`}
                >
                    <FareAttributeTagContainer
                        fare={fare}
                        fareInstance={fareInstance}
                        renderWithToolTipExpanded={renderWithToolTipExpanded}
                    />
                </div>
            )}
        </div>
    );
}
