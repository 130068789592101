import React from 'react';
import ReactDOM from 'react-dom';
import { AnyAction, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { HomePageWindow, HomeStoreState } from './globals';
import configReducer, { ConfigState } from 'ducks/config';
import airSearchDataReducer, { importExternalSearchDataWithAirport } from 'ducks/airSearchData';
import hotelSearchDataReducer, { importExternalHotelSearchDataWithCity } from 'ducks/hotelSearchData';
import userReducer from 'ducks/user';
import HomePage from 'pages/HomePage';
import { getGlobalMiddlewares } from 'utils/app';
import fareFiltersReducer from 'ducks/fareFilters';
import fareTickerReducer, { actions as fareTickerActions } from 'ducks/fareTicker';
import fareTickerMiddleware from 'middleware/fareTicker';
import { renderBaseComponents } from 'apps/common';
import AwdProvider from 'components/AwdProvider';

declare const window: HomePageWindow;

const HOME_PAGE_FARE_TICKER_MAX_FARES = 7;

export function start() {
    const {
            abTests,
            externalSearchData,
            isExactDateSearch,
            locale,
            siteId,
            staticBaseUrl,
            subscriber,
            currency,
            homeAirport
        } = window.pageData,
        composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose,
        configState: ConfigState = {
            abTests,
            locale,
            siteId,
            staticBaseUrl
        },
        appInitialState = {
            config: configState,
            user: { subscriber, currency, homeAirport }
        },
        reducers = combineReducers({
            config: configReducer,
            fareFilters: fareFiltersReducer,
            fareTicker: fareTickerReducer,
            hotelSearchData: hotelSearchDataReducer,
            airSearchData: airSearchDataReducer,
            user: userReducer
        }),
        store = createStore(
            reducers,
            appInitialState,
            composeWithDevTools(applyMiddleware(...getGlobalMiddlewares(window.pageData), fareTickerMiddleware()))
        );

    const dispatch: ThunkDispatch<HomeStoreState, void, AnyAction> = store.dispatch;

    // Set max fares to fetch for the fare ticker
    dispatch(fareTickerActions.setMaxFares(HOME_PAGE_FARE_TICKER_MAX_FARES));
    // Set initial air search data state
    dispatch(importExternalSearchDataWithAirport(externalSearchData));
    // Set initial hotel search data state
    dispatch(importExternalHotelSearchDataWithCity(externalSearchData));

    ReactDOM.render(
        <AwdProvider store={store}>
            <HomePage isExactDateSearch={isExactDateSearch} />
        </AwdProvider>,
        document.getElementById('react-root') as HTMLElement
    );

    renderBaseComponents({ store });
}
