import React from 'react';

import './styles.scss';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import Spinner, { SpinnerColor } from 'components/Spinner';

export interface InlineInputBaseWrapperProps {
    classNames?: string[]; // An array of class names to be added to the base wrapper
    iconName?: AwdIconName; // Name of icon to render to the left of the input
    showSpinner?: boolean;
    error?: string; // Optional text describing a problem with the value in the input
    label?: string; // Optional text for label to be shown above input
}

/**
 * Base component used to style and render specific implementations of the InlineInput components (i.e.
 * free-text, one with autocomplete, etc.). Note that the actual input elements, need to be passed as
 * children to this base component
 */
export default class InlineInputBaseWrapper extends React.Component<InlineInputBaseWrapperProps> {
    constructor(props: InlineInputBaseWrapperProps) {
        super(props);

        this.state = { value: '', suggestions: [] };
    }

    public render() {
        const { error, label, iconName, children, classNames, showSpinner } = this.props,
            classNameStr = [
                ...(classNames || []),
                'input-inline',
                'do_no_popunder',
                error ? 'input-inline--with-error' : '',
                label ? 'input-inline--with-label' : '',
                !!iconName ? 'input-inline--with-icon' : ''
            ].join(' ');

        return (
            <span className={classNameStr}>
                {iconName && (
                    <span className='input-inline__icon'>
                        {showSpinner ? <Spinner color={SpinnerColor.Navy} /> : <AwdIcon name={iconName} />}
                    </span>
                )}
                <div className='input-inline__inner-wrapper'>
                    {label && <div className='input-inline__label'>{label}</div>}
                    {children}
                    {error && <div className={'input-inline__error'}>{error}</div>}
                </div>
                <span className='input-inline__arrow-down'>
                    <AwdIcon name={AwdIconName.ArrowDown} />
                </span>
            </span>
        );
    }
}
