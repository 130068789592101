import React from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';
import msgs from './messages';
import { FlightSearchType } from 'models/SearchData';
import RadioInput, { RadioInputTheme } from 'components/RadioInput';

interface FlightTypeProps {
    value?: FlightSearchType;
    useShortLabels?: boolean;
    radioTheme?: RadioInputTheme;
    onChange(flightType: FlightSearchType): void;
}

export default class FlightTypeInput extends React.Component<FlightTypeProps> {
    public render = () => {
        const { value, radioTheme } = this.props,
            roundtripLabel = this.props.useShortLabels ? msgs.roundtripShortLabel : msgs.roundtripLabel,
            onewayLabel = this.props.useShortLabels ? msgs.onewayShortLabel : msgs.onewayLabel;

        return (
            <div className='flight-type-input do_no_popunder'>
                <span className='flight-type-input__option'>
                    <RadioInput
                        value={FlightSearchType.ROUNDTRIP as FlightSearchType}
                        selectedValue={value}
                        onChange={this.onChange}
                        theme={radioTheme}
                    >
                        <FormattedMessage {...roundtripLabel} />
                    </RadioInput>
                </span>
                <span className='flight-type-input__option'>
                    <RadioInput
                        value={FlightSearchType.ONEWAY as FlightSearchType}
                        selectedValue={value}
                        onChange={this.onChange}
                        theme={radioTheme}
                    >
                        <FormattedMessage {...onewayLabel} />
                    </RadioInput>
                </span>
            </div>
        );
    };

    private onChange = (flightType: FlightSearchType) => {
        this.props.onChange && this.props.onChange(flightType);
    };
}
