import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Fare, FareInstance } from 'models/Fares';
import FareViewDescriptorTags from '../DescriptorTags';
import PriceSection from '../PriceSection';
import msgs from './messages';
import FareAction from 'models/Fares/FareAction';
import Button from 'components/Button';

import './styles.scss';
import { CurrencyCode } from 'models/Currency';

interface FareHighlightsProps {
    fare: Fare;
    fareInstance: FareInstance;
    currency: CurrencyCode;
    fareAction?: FareAction;
    pricePromoNote?: FormattedMessage.MessageDescriptor;
}

export default function FareHighlights({
    fare,
    fareInstance,
    currency,
    fareAction = FareAction.To,
    pricePromoNote
}: FareHighlightsProps) {
    const { departureDisplayName, departureCode, arrivalDisplayName, arrivalCode } = fare,
        inbound = fareAction === FareAction.To;

    return (
        <div className={'fare-view-highlights'}>
            <div className='fare-view-highlights__tags'>
                <FareViewDescriptorTags fareInstance={fareInstance} />
            </div>
            <h3 className='fare-view-highlights__route'>
                <div className={`fare-view-highlights__loc ${inbound ? '' : 'fare-view-highlights__loc--strong'}`}>
                    {departureDisplayName} ({departureCode}) {inbound && <FormattedMessage {...msgs.to} />}
                </div>
                <div className={`fare-view-highlights__loc ${inbound ? 'fare-view-highlights__loc--strong' : ''}`}>
                    {!inbound && <FormattedMessage {...msgs.to} />} {arrivalDisplayName} ({arrivalCode})
                </div>
            </h3>
            <h4 className='fare-view-highlights__price'>
                <PriceSection fare={fare} fareInstance={fareInstance} currency={currency} promoNote={pricePromoNote} />
            </h4>
            <div className='fare-view-highlights__cta'>
                <Button text={msgs.cta} />
            </div>
        </div>
    );
}
