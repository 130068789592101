import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { getAirSearchData } from 'ducks/airSearchData';
import {
    AirSearchFormState,
    openTabBrowsingAndSubscribeUser,
    updateAirSearchDataAndQueueSubscriber
} from 'ducks/airSearchForm';
import { getSubscriber } from 'ducks/user';
import SearchData from 'models/SearchData';
import { Subscriber } from 'models/Subscriptions';
import { AirportSuggestion } from 'models/Suggestion';
import { AirSearchFormResponsive, AirSearchFormSticky, AirSearchFormVertical, AirSearchFormWide } from './index';
import { getAirportSuggestions, LocationSuggestType } from 'api/locationSuggest';

/*
 * This file contains "connected" components, which are air search forms that have been connected to the redux store
 * in order to get and update search data and subscriber info and launch tab browsing on form submission.
 */

const getLocationSuggestions = async (query: string) => {
    return getAirportSuggestions({ query, searchType: LocationSuggestType.AIRPORTS_ALL });
};

const mapStateToProps = (state: AirSearchFormState) => ({
    getLocationSuggestions,
    searchData: getAirSearchData(state),
    subscriber: getSubscriber(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AirSearchFormState, void, AnyAction>) => ({
    onChange: ({ searchData, subscriber }: { searchData?: SearchData<AirportSuggestion>; subscriber?: Subscriber }) =>
        dispatch(updateAirSearchDataAndQueueSubscriber({ searchData, subscriber })),
    onFormSubmit: ({
        searchData,
        subscribeUser,
        subscriber,
        redirectOnSubmit
    }: {
        searchData: SearchData<AirportSuggestion>;
        subscribeUser: boolean;
        subscriber?: Subscriber | null;
        redirectOnSubmit?: boolean;
    }) => {
        return dispatch(openTabBrowsingAndSubscribeUser({ searchData, subscribeUser, subscriber, redirectOnSubmit }));
    }
});

export const ConnectedAirSearchFormVertical = connect(mapStateToProps, mapDispatchToProps)(AirSearchFormVertical);
export const ConnectedAirSearchFormWide = connect(mapStateToProps, mapDispatchToProps)(AirSearchFormWide);
export const ConnectedAirSearchFormSticky = connect(mapStateToProps, mapDispatchToProps)(AirSearchFormSticky);
export const ConnectedAirSearchFormResponsive = connect(mapStateToProps, mapDispatchToProps)(AirSearchFormResponsive);
