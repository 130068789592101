import React from 'react';

import './styles.scss';
import { GptAdPosition, GptAdSize } from 'models/GPT';
import { NonEmptyArray } from 'models/Collections';

interface GptLegacyAdProps {
    sizes: NonEmptyArray<GptAdSize>;
    position: GptAdPosition;
    forceShow?: boolean;
}

/**
 * Component renders the markup our legacy flight.js code needs in order to render
 * the Dart/GPT ads. In the future, we really shouldn't be using these and we should
 * port all that logic into React. But for now, we add this markup and then tell flight.js
 * to do its thing.
 *
 * Note for this markup to do anything we need to be loading and initialize the
 * dartAdController in the flight.js code, and we need to kick it off after the markup
 * for this and all other GptLegacyAds components has been added.
 */
class GptLegacyAd extends React.Component<GptLegacyAdProps> {
    public render = () => {
        const { sizes, position, forceShow } = this.props,
            sizesString = sizes.join(',');

        return (
            <div
                className='gpt-legacy-ad do_dart_ad'
                data-ad-size={sizesString}
                data-ad-position={position}
                data-ad-force-show={forceShow}
            />
        );
    };
}

export default GptLegacyAd;
