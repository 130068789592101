import { FareSort, FareSortDir, FareSortField } from 'models/Fares';

/**
 * Returns a list of fare sort options supported by AWD
 */
export function getFareSortOptions(): FareSort[] {
    const options: FareSort[] = [],
        sortFields = [FareSortField.PRICE, FareSortField.LOCATION],
        sortDirs = [FareSortDir.ASC, FareSortDir.DESC];

    sortFields.forEach(field => {
        sortDirs.forEach(direction => {
            options.push({ field, direction });
        });
    });
    return options;
}

/**
 * Returns the string representation of a FareSort, e.g. "location,asc"
 * @param fareSort
 */
export function getFareSortAsString(fareSort: FareSort): string {
    return fareSort.field + ',' + fareSort.direction;
}

/**
 * Returns a FareSort for the given string (in the format "location,asc")
 * @param fareSortString
 */
export function getFareSortByString(fareSortString: string): FareSort | undefined {
    const [field, dir] = fareSortString.split(','),
        isValidField = Object.values(FareSortField).includes(field as FareSortField),
        isValidDir = Object.values(FareSortDir).includes(dir as FareSortDir);

    if (isValidField && isValidDir) {
        return {
            field: field as FareSortField,
            direction: dir as FareSortDir
        };
    }
}
