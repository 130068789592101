/**
 * These colors should always be kept in sync with global-colors.scss file
 */
export enum Colors {
    Cerulean = '#01a1dd',
    FunBLue = '#17468f',
    NeonCarrot = '#fd922d',
    RobinEggBlue = '#02d2bf',
    Razzmatazz = '#d60a7c',
    Navy = '#001d40',
    Gray = '#666',
    White = '#fff',
    Black = '#000',
    ErrorRed = '#cc0202',
    GrayLight = '#999',
    LightGray1 = '#f6f8fb',
    LightGray2 = '#f0f0f0',
    LightGray3 = '#dfe6f0',
    LightGray4 = '#c7c7c7'
}
