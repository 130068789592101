import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import SearchData, { SearchDataWithRequiredLocation1 } from 'models/SearchData';
import { AirportSuggestion } from 'models/Suggestion';
import { Subscriber } from 'models/Subscriptions';
import { StoreWithAirSearchDataState, updateAirSearchData } from 'ducks/airSearchData';
import {
    buildFareDetailsUrl,
    buildFareListingUrl,
    buildTabbedBrowsingUrl,
    FareListingUrlFilterOptions
} from 'utils/urls';
import { addOrUpdateAwdSubscriber, queueNewSubscriber, StoreWithUserState, UserAction } from 'ducks/user';
import { getAwdSubscriptionForSearchData } from 'utils/subscriptions';
import FareAction from 'models/Fares/FareAction';
import { getSiteId, StoreWithConfigState } from 'ducks/config';

// An event fired so FlightJS code can handle tracking for searches
const EV_DATA_REACT_EXACT_SEARCH_SUBMITTED = 'data:react:exact:search:submitted';

export type AirSearchFormState = StoreWithAirSearchDataState & StoreWithUserState & StoreWithConfigState;

export const updateAirSearchDataAndQueueSubscriber = ({
    searchData,
    subscriber
}: {
    searchData?: SearchData<AirportSuggestion>;
    subscriber?: Subscriber;
}): ThunkAction<void, AirSearchFormState, void, AnyAction> => {
    return (dispatch: ThunkDispatch<AirSearchFormState, void, AnyAction>) => {
        if (searchData) {
            dispatch(updateAirSearchData(searchData));
        }
        if (subscriber) {
            dispatch(queueNewSubscriber(subscriber));
        }
    };
};

export const openTabBrowsingAndSubscribeUser = ({
    searchData,
    subscribeUser,
    subscriber,
    redirectOnSubmit
}: {
    searchData: SearchData<AirportSuggestion>;
    subscribeUser: boolean;
    subscriber?: Subscriber | null;
    redirectOnSubmit?: boolean;
}): ThunkAction<Promise<UserAction | void>, AirSearchFormState, void, UserAction> => {
    return (dispatch: ThunkDispatch<AirSearchFormState, void, UserAction>, getState: () => AirSearchFormState) => {
        trackAirExactSearch(searchData);
        window.open(buildTabbedBrowsingUrl({ searchData }), '_blank');

        const siteId = getSiteId(getState());
        const subscribePromise: () => Promise<void> =
            subscribeUser && subscriber
                ? () =>
                      dispatch(
                          addOrUpdateAwdSubscriber({
                              subscriber: {
                                  ...subscriber,
                                  // We only need to include requested subscriptions here
                                  subscriptions: getAwdSubscriptionForSearchData(searchData, siteId)
                              }
                          })
                      )
                : () => Promise.resolve();

        return subscribePromise().then(() => {
            const { location1 } = searchData;

            if (!redirectOnSubmit || !location1) {
                return;
            }

            window.location.href = buildFareListingUrl({
                fareAction: FareAction.From,
                airport: location1.suggestion
            });
        });
    };
};

export const openFarePageAndSubscribeUser = ({
    searchData,
    shouldSubscribeUser,
    subscriber,
    filterOptions
}: {
    searchData: SearchDataWithRequiredLocation1<AirportSuggestion>;
    shouldSubscribeUser: boolean;
    subscriber?: Subscriber | null;
    filterOptions?: FareListingUrlFilterOptions;
}): ThunkAction<Promise<void>, AirSearchFormState, void, UserAction> => {
    return (dispatch: ThunkDispatch<AirSearchFormState, void, UserAction>, getState: () => AirSearchFormState) => {
        const { location1, location2 } = searchData;

        let url;
        if (location2) {
            url = buildFareDetailsUrl({ location1, location2 });
        } else {
            url = buildFareListingUrl({
                fareAction: FareAction.From,
                airport: location1.suggestion,
                filterOptions: {
                    ...filterOptions,
                    filterRoute: searchData.location2 && searchData.location2.suggestion
                }
            });
        }
        window.open(url, '_blank');

        if (!shouldSubscribeUser || !subscriber) {
            return Promise.resolve();
        }

        const siteId = getSiteId(getState());

        return dispatch(
            addOrUpdateAwdSubscriber({
                subscriber: {
                    ...subscriber,
                    // We only need to include requested subscriptions here
                    subscriptions: getAwdSubscriptionForSearchData(searchData, siteId)
                }
            })
        );
    };
};

/**
 * Fires an event with the current air search data to indicate a successful air search form submission. FlightJS listens
 * for the event and fires off the appropriate tracking calls (e.g. collection service, GTM).
 */
function trackAirExactSearch(searchData: SearchData<AirportSuggestion>) {
    // initCustomEvent is deprecated, but IE11 doesn't support `new CustomEvent()`.
    const searchEvent: CustomEvent = document.createEvent('CustomEvent');
    searchEvent.initCustomEvent(EV_DATA_REACT_EXACT_SEARCH_SUBMITTED, true, true, { searchData });
    document.dispatchEvent(searchEvent);
}
