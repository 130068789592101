import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import SearchData, { SearchDataWithRequiredLocation2 } from 'models/SearchData';
import { CitySuggestion } from 'models/Suggestion';
import { Subscriber } from 'models/Subscriptions';
import { StoreWithHotelSearchDataState, updateHotelSearchData } from 'ducks/hotelSearchData';
import { addOrUpdateAwdSubscriber, queueNewSubscriber, StoreWithUserState, UserAction } from 'ducks/user';
import { buildHotelListingUrl } from 'utils/urls';
import { getAwdSubscriptionForSearchData } from 'utils/subscriptions';
import { getSiteId, StoreWithConfigState } from 'ducks/config';

export type HotelSearchFormState = StoreWithHotelSearchDataState & StoreWithUserState & StoreWithConfigState;

export const updateHotelSearchDataAndQueueSubscriber = ({
    searchData,
    subscriber
}: {
    searchData?: SearchData<CitySuggestion>;
    subscriber?: Subscriber;
}): ThunkAction<void, HotelSearchFormState, void, AnyAction> => {
    return (dispatch: ThunkDispatch<HotelSearchFormState, void, AnyAction>) => {
        if (searchData) {
            dispatch(updateHotelSearchData(searchData));
        }
        if (subscriber) {
            dispatch(queueNewSubscriber(subscriber));
        }
    };
};

export const openHotelListingPageAndSubscribeUser = ({
    searchData,
    subscribeUser,
    subscriber
}: {
    searchData: SearchDataWithRequiredLocation2<CitySuggestion>;
    subscribeUser: boolean;
    subscriber?: Subscriber | null;
}): ThunkAction<Promise<void>, HotelSearchFormState, void, UserAction> => {
    return (dispatch: ThunkDispatch<HotelSearchFormState, void, UserAction>, getState: () => HotelSearchFormState) => {
        window.open(buildHotelListingUrl(searchData), '_blank');

        if (!subscribeUser || !subscriber) {
            return Promise.resolve();
        }

        const siteId = getSiteId(getState());

        return dispatch(
            addOrUpdateAwdSubscriber({
                subscriber: {
                    ...subscriber,
                    // We only need to include requested subscriptions here
                    subscriptions: getAwdSubscriptionForSearchData(searchData, siteId)
                }
            })
        );
    };
};
