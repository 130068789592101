import { FareInstance } from 'models/Fares';

/**
 * In some cases, such as Flex fares on the airline deals page, we don't generate a tab browsing URL for fares.
 * If this happens, we should fall back to the fare details URL.
 */
export default function openFareLink({
    fareInstance,
    fareDetailsForFlexFares
}: {
    fareInstance: FareInstance;
    fareDetailsForFlexFares?: boolean;
}) {
    const urlToOpen = getFareLink({ fareInstance, fareDetailsForFlexFares });

    urlToOpen && window.open(urlToOpen, '_blank');
}

export function getFareLink({
    fareInstance,
    fareDetailsForFlexFares
}: {
    fareInstance: FareInstance;
    fareDetailsForFlexFares?: boolean;
}) {
    let urlToOpen = fareInstance.tabBrowsingUrl || fareInstance.fareUrl;

    if (fareDetailsForFlexFares && fareInstance.fareUrl && fareInstance.isFlexibleDates) {
        urlToOpen = fareInstance.fareUrl;
    }

    return urlToOpen;
}
