import React from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

export enum ConversationBubbleTailPosition {
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right'
}

export enum ConversationBubbleSize {
    SMALL = 'small',
    LARGE = 'large'
}

interface ConversationBubbleProps {
    message: FormattedMessage.Props;
    tailPosition?: ConversationBubbleTailPosition;
    size?: ConversationBubbleSize;
}

export default function ConversationBubble({
    message,
    tailPosition = ConversationBubbleTailPosition.BOTTOM_LEFT,
    size = ConversationBubbleSize.SMALL
}: ConversationBubbleProps) {
    return (
        <div
            className={`conversation-bubble conversation-bubble__tail-${tailPosition} conversation-bubble--size-${size}`}
        >
            <FormattedMessage {...message} />
        </div>
    );
}
