import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import pick from 'lodash/pick';
import msgs from './messages';
import './styles.scss';
import InputWithAutocomplete from 'components/Input/WithAutocomplete';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import Button from 'components/Button';
import NumStopsFareFilter from 'components/FareListingFilter/NumStops';
import Checkbox, { CheckboxTheme } from 'components/Checkbox';
import InputFreeText from 'components/Input/FreeText';
import { AirportSuggestion, Suggestion, SuggestionDecorator } from 'models/Suggestion';
import SearchData, { isSearchDataWithLocation1, SearchDataWithRequiredLocation1 } from 'models/SearchData';
import { Subscriber } from 'models/Subscriptions';
import { SearchFormField } from 'components/SearchForm/common/fields';
import { FareNumStops } from 'models/Fares';
import without from 'lodash/without';
import { isValidEmail } from 'utils/email';
import FareTravelTimeFilter from 'components/FareListingFilter/TravelTime';
import Tooltip, { TooltipPosition } from 'components/Tooltip';
import SearchType from 'models/SearchData/enums/SearchType';
import { Colors } from 'constants/colors';
import Autocomplete from 'constants/autocomplete';
import MessageDescriptor = ReactIntl.FormattedMessage.MessageDescriptor;

export enum SearchFormDatelessLayout {
    VERTICAL = 'vertical',
    WIDE = 'wide'
}

interface SearchFormDatelessProps {
    showFilters?: boolean;
    filterNumStops?: FareNumStops;
    filterTravelMonths?: string[];
    filterWeekendOnly?: boolean;
    intl: InjectedIntl;
    layout: SearchFormDatelessLayout;
    searchData: SearchData<AirportSuggestion>;
    subscriber?: Subscriber;
    getOriginLocationSuggestions(queryText: string): Promise<Array<SuggestionDecorator<AirportSuggestion>>>;
    getDestinationLocationSuggestions(queryText: string): Promise<Array<SuggestionDecorator<AirportSuggestion>>>;
    onFilterNumStopsChange(numStops: FareNumStops): void;
    onFilterNumStopsClear(): void;
    onFilterMonthToggled(monthStr: string): void;
    onFilterWeekendToggled(): void;
    onFilterTravelTimeClear(): void;
    onSearchDataChange(searchData: SearchData<AirportSuggestion>): void;
    onSubscriberChange(subscriber: Subscriber): void;
    onSubmit({
        searchData,
        shouldSubscribeUser,
        subscriber,
        filterNumStops,
        filterTravelMonths,
        filterWeekendOnly
    }: {
        searchData: SearchDataWithRequiredLocation1<AirportSuggestion>;
        shouldSubscribeUser: boolean;
        subscriber?: Subscriber | null;
        filterNumStops?: FareNumStops;
        filterTravelMonths?: string[];
        filterWeekendOnly?: boolean;
    }): void;
}

interface SearchFormDatelessState {
    fieldsInError: SearchFormField[];
    shouldSubscribeUser: boolean;
}

export class SearchFormDateless extends React.Component<SearchFormDatelessProps> {
    public static defaultProps = { filterTravelMonths: [], filterWeekendOnly: false };
    public state: SearchFormDatelessState = {
        fieldsInError: [],
        shouldSubscribeUser: false
    };
    private readonly emailIconColor = Colors.Razzmatazz; // razzmatazz

    public render() {
        const { layout, showFilters } = this.props;

        return (
            <div className={`search-form-dateless search-form-dateless--${layout}`}>
                <div className='search-form-dateless__content'>
                    <div className='search-form-dateless__fields'>
                        {this.renderOriginLocation()}
                        {this.renderDestinationLocation()}
                        {layout === SearchFormDatelessLayout.WIDE && this.renderSearchButton()}
                    </div>
                    {showFilters && this.renderFilters()}
                    {this.renderSubscriptionSection()}
                    {layout === SearchFormDatelessLayout.VERTICAL && this.renderSearchButton()}
                </div>
            </div>
        );
    }

    private renderOriginLocation = () => {
        return this.renderLocation({
            suggestion: this.props.searchData.location1,
            label: msgs.location1Label,
            placeholder: msgs.location1Placeholder,
            onLocationChange: this.onLocationChange(SearchFormField.LOCATION1),
            inError: this.state.fieldsInError.includes(SearchFormField.LOCATION1),
            onBlur: this.onOriginLocationBlur,
            highlightOnEmpty: true
        });
    };

    private renderDestinationLocation = () => {
        return this.renderLocation({
            suggestion: this.props.searchData.location2,
            label: msgs.location2Label,
            placeholder: msgs.location2Placeholder,
            onLocationChange: this.onLocationChange(SearchFormField.LOCATION2),
            inError: this.state.fieldsInError.includes(SearchFormField.LOCATION2),
            highlightOnEmpty: false
        });
    };

    private renderLocation = ({
        suggestion,
        label,
        placeholder,
        onLocationChange,
        inError,
        onBlur,
        highlightOnEmpty
    }: {
        suggestion?: SuggestionDecorator<Suggestion>;
        label: MessageDescriptor;
        placeholder: MessageDescriptor;
        onLocationChange: (suggestion: SuggestionDecorator<Suggestion>) => void;
        inError: boolean;
        onBlur?: (suggestion: SuggestionDecorator<Suggestion>) => void;
        highlightOnEmpty: boolean;
    }) => {
        return (
            <div className='search-form-dateless__field-container search-form-dateless__location'>
                <div className='search-form-dateless__field-label search-form-dateless__location-label'>
                    <FormattedMessage {...label} />
                </div>
                <div className='search-form-dateless__field search-form-dateless__location-input'>
                    <InputWithAutocomplete
                        suggestion={suggestion}
                        iconName={AwdIconName.Pin}
                        getSuggestions={this.props.getDestinationLocationSuggestions}
                        highlightOnEmpty={highlightOnEmpty}
                        placeholder={placeholder}
                        onSuggestionSelected={onLocationChange}
                        inError={inError}
                        key={suggestion && suggestion.text}
                        onBlur={onBlur}
                    />
                </div>
            </div>
        );
    };

    private renderFilters = () => {
        const {
            filterTravelMonths,
            filterWeekendOnly,
            onFilterMonthToggled,
            onFilterWeekendToggled,
            onFilterTravelTimeClear,
            onFilterNumStopsChange,
            onFilterNumStopsClear,
            filterNumStops
        } = this.props;

        return (
            <div className='search-form-dateless__filters'>
                <div className='search-form-dateless__filters__label'>
                    <FormattedMessage {...msgs.filtersLabel} />
                </div>
                <div className='search-form-dateless__filter'>
                    <NumStopsFareFilter
                        onValueChanged={onFilterNumStopsChange}
                        onClearFilter={onFilterNumStopsClear}
                        selectedValue={filterNumStops}
                    />
                </div>
                <div className='search-form-dateless__filter'>
                    <FareTravelTimeFilter
                        isWeekendOnly={filterWeekendOnly!}
                        selectedMonths={filterTravelMonths!}
                        onMonthToggled={onFilterMonthToggled}
                        onWeekendOnlyToggled={onFilterWeekendToggled}
                        onClearFilter={onFilterTravelTimeClear}
                    />
                </div>
            </div>
        );
    };

    private renderSearchButton = () => {
        return (
            <div className='search-form-dateless__field-container search-form-dateless__search'>
                <Button text={msgs.buttonText} onClick={this.onSubmit} />
            </div>
        );
    };

    private renderSubscriptionSection = () => {
        const { subscriber, searchData } = this.props,
            { shouldSubscribeUser } = this.state,
            tooltipMessage =
                searchData.searchType === SearchType.AIR ? msgs.subscriptionHoverAir : msgs.subscriptionHoverHotel;

        return (
            <div className='search-form-dateless_subscription-container'>
                <div className='search-form-dateless__subscription-header'>
                    <FormattedMessage
                        {...(searchData.location1 ? msgs.subscriptionHeader : msgs.subscriptionHeaderNoLocation)}
                    />
                </div>
                <div className='search-form-dateless__subscription'>
                    <div className='search-form-dateless__subscription-checkbox'>
                        <Checkbox
                            checked={shouldSubscribeUser}
                            theme={CheckboxTheme.Blue}
                            onChange={this.toggleSubscriberCheckbox}
                        >
                            {this.renderSubscriptionCheckboxLabel()}
                            <Tooltip
                                anchorClassNames={['search-form-dateless__subscription-checkbox__info']}
                                containerClassNames={['search-form-dateless__subscription-checkbox__tooltip-container']}
                                tooltipClassNames={['search-form-dateless__subscription-checkbox__tooltip']}
                                anchorContents={<AwdIcon name={AwdIconName.Info} />}
                                tooltipContents={<FormattedMessage {...tooltipMessage} />}
                                position={TooltipPosition.TOP}
                            />
                        </Checkbox>
                    </div>
                    {shouldSubscribeUser && (
                        <div className='search-form-dateless__subscription-field'>
                            <InputFreeText
                                value={subscriber && subscriber.emailAddress}
                                iconName={AwdIconName.EnvelopeWithLines}
                                placeholder={msgs.subscriberEmailPlaceholder}
                                onBlur={this.onEmailBlur}
                                iconColor={this.emailIconColor}
                                autoComplete={Autocomplete.EMAIL}
                            />
                        </div>
                    )}
                </div>
                {shouldSubscribeUser && (
                    <div className='search-form-dateless__subscription-disclaimer'>
                        <FormattedHTMLMessage {...msgs.subscribeDisclaimer} />
                    </div>
                )}
            </div>
        );
    };

    private renderSubscriptionCheckboxLabel = () => {
        const { location1, location2 } = this.props.searchData;
        if (location1 && location2) {
            return (
                <span>
                    {location1.text} - {location2.text}
                </span>
            );
        } else if (location1) {
            return <span>{location1.text}</span>;
        }
        return <FormattedMessage {...msgs.subscriberCheckboxLabelNoLocation} />;
    };

    private toggleSubscriberCheckbox = () => {
        this.setState((prevState: SearchFormDatelessState) => ({
            shouldSubscribeUser: !prevState.shouldSubscribeUser
        }));
    };

    private onEmailBlur = (email: string) => {
        const { subscriber, onSubscriberChange } = this.props;

        email = email && email.trim();

        if (isValidEmail({ email }) && (!subscriber || email !== subscriber.emailAddress)) {
            onSubscriberChange({ emailAddress: email, subscriptions: [] });
        }
    };

    private onOriginLocationBlur = (value: SuggestionDecorator<Suggestion>) => {
        const updatedFieldsInError = without(this.state.fieldsInError, SearchFormField.LOCATION1);

        if (!value) {
            updatedFieldsInError.push(SearchFormField.LOCATION1);
        }

        this.setState({ fieldsInError: updatedFieldsInError });
    };

    private onLocationChange = (field: SearchFormField.LOCATION1 | SearchFormField.LOCATION2) => {
        return (value: SuggestionDecorator<Suggestion>) => {
            this.setState({ fieldsInError: without(this.state.fieldsInError, field) });
            this.props.onSearchDataChange({ ...this.props.searchData, ...{ [field]: value } });
        };
    };

    private onSubmit = () => {
        const { searchData } = this.props;

        if (!isSearchDataWithLocation1(searchData)) {
            this.setState({ fieldsInError: [SearchFormField.LOCATION1] });
            return;
        }

        this.props.onSubmit({
            ...pick(this.props, ['subscriber', 'filterNumStops', 'filterTravelMonths', 'filterWeekendOnly']),
            searchData,
            shouldSubscribeUser: this.state.shouldSubscribeUser
        });
    };
}

export default injectIntl(SearchFormDateless);
