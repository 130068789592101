import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import './styles.scss';
import msgs from '../common/messages';
import { SearchType } from 'models/SearchData';
import { Suggestion } from 'models/Suggestion';
import { SearchFormProps } from '../common/props';
import { SearchFormField } from '../common/fields';
import {
    SearchFormOptionsRow,
    SearchFormLocationField,
    SearchFormDatesField,
    SearchFormSubscriptionHeader,
    SearchFormEmailField,
    SearchFormSearchButton,
    SearchFormSubscriberCheckbox
} from '../common/components';

/**
 * Wide search-form, typically used for desktop and tablet displays
 */
export default class SearchFormWide<T extends Suggestion> extends React.Component<SearchFormProps<T>> {
    public render() {
        const {
                getLocationSuggestions,
                getOnLocationChangeHandler,
                getOnLocationBlurHandler,
                subscriber,
                onClick,
                onDateChange,
                onDatesChange,
                onEmailBlur,
                searchData,
                fieldsInError,
                isCompact,
                isEmailFieldVisible,
                straightCorners,
                innerRef,
                style,
                location1Label,
                location2Label,
                datesLabel,
                emailFieldPlaceholder,
                insetLabels,
                location1Placeholder,
                location2Placeholder
            } = this.props,
            { location1, location2, searchType } = searchData,
            isAirSearch = searchType === SearchType.AIR;

        return (
            <div
                ref={innerRef}
                onClick={onClick}
                className={`search-form ${
                    straightCorners ? 'search-form__straight-corners' : ''
                } search-form--${searchType} search-form--${style} SFW`}
            >
                <div className='search-form__content SFW__content'>
                    {this.renderTopRow()}

                    <div className='search-form__fields SFW__fields'>
                        <div className='SFW__fields__row SFW__fields__row1'>
                            {isAirSearch && (
                                <SearchFormLocationField
                                    searchFormField={SearchFormField.LOCATION1}
                                    location={location1}
                                    label={location1Label}
                                    placeholder={location1Placeholder}
                                    containerClassNames={[
                                        'SFW__field-container',
                                        'SFW__location-container',
                                        'SFW__location1-container'
                                    ]}
                                    fieldClassNames={['SFW__field', 'SFW__location', 'SFW__location1']}
                                    {...{
                                        insetLabels,
                                        getLocationSuggestions,
                                        getOnLocationBlurHandler,
                                        getOnLocationChangeHandler,
                                        fieldsInError
                                    }}
                                />
                            )}

                            <SearchFormLocationField
                                searchFormField={SearchFormField.LOCATION2}
                                location={location2}
                                label={location2Label}
                                placeholder={location2Placeholder}
                                containerClassNames={[
                                    'SFW__field-container',
                                    'SFW__location-container',
                                    'SFW__location2-container'
                                ]}
                                fieldClassNames={['SFW__field', 'SFW__location', 'SFW__location2']}
                                {...{
                                    insetLabels,
                                    getLocationSuggestions,
                                    getOnLocationBlurHandler,
                                    getOnLocationChangeHandler,
                                    fieldsInError
                                }}
                            />

                            <SearchFormDatesField
                                containerClassNames={['SFW__field-container', 'SFW__dates-container']}
                                fieldClassNames={['SFW__field', 'SFW__dates']}
                                {...{ datesLabel, fieldsInError, insetLabels, onDateChange, onDatesChange, searchData }}
                            />

                            {this.renderButton()}
                        </div>

                        {!isCompact && (
                            <>
                                <div className='SFW__fields__row SFW__fields__row2'>
                                    <div className='SFW__subscription-container'>
                                        <SearchFormSubscriptionHeader
                                            searchData={searchData}
                                            classNames={['SFW__subscription-header']}
                                        />

                                        {this.renderSubscriberCheckbox()}
                                    </div>

                                    {isEmailFieldVisible && (
                                        <SearchFormEmailField
                                            containerClassNames={[
                                                'search-form__email-container',
                                                'SFW__field-container',
                                                'SFW__email-container'
                                            ]}
                                            fieldClassNames={['SFW__email']}
                                            {...{ subscriber, emailFieldPlaceholder, onEmailBlur }}
                                        />
                                    )}
                                </div>
                            </>
                        )}

                        {isEmailFieldVisible && (
                            <div className='search-form__subscriber-disclaimer SFW__subscriber-disclaimer'>
                                <FormattedHTMLMessage {...msgs.subscribeDisclaimer} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private renderTopRow() {
        const {
                isCompact,
                largeHeader,
                header,
                hideTopRowOptions,
                onFlightTypeChange,
                onTravelersChange,
                searchData,
                style,
                useShortFlightTypeLabels,
                headerFontSize
            } = this.props,
            isAirSearch = searchData.searchType === SearchType.AIR,
            shouldHideOptions = hideTopRowOptions || !isAirSearch,
            isEmptyHeader = !header && shouldHideOptions;

        if (isCompact || isEmptyHeader) {
            return null;
        }

        return (
            <div className={`search-form__top-row SFW__top-row ${header ? '' : 'SFW__top-row__no-header'}`}>
                {header && (
                    <div
                        className={`search-form__header SFW__header ${
                            largeHeader ? 'search-form__header--large SFW__header--large' : ''
                        }`}
                        style={{ fontSize: headerFontSize || '' }}
                    >
                        <FormattedMessage {...header} />
                    </div>
                )}

                {!shouldHideOptions && (
                    <SearchFormOptionsRow
                        containerClassNames={['SFW__options']}
                        fieldClassNames={['SFW__option']}
                        {...{
                            onFlightTypeChange,
                            onTravelersChange,
                            searchData,
                            style,
                            useShortFlightTypeLabels
                        }}
                    />
                )}
            </div>
        );
    }

    private renderButton() {
        const { buttonText, insetLabels, onSubmit, buttonTextSize } = this.props;

        return (
            <SearchFormSearchButton
                onSubmit={onSubmit}
                buttonText={buttonText}
                buttonTextSize={buttonTextSize}
                classNames={['SFW__button', insetLabels ? '' : 'SFW__button--with-label']}
            />
        );
    }

    private renderSubscriberCheckbox() {
        const { subscribeUser, onSubscribeUserChange, searchData, style, subscriberCheckboxLabel } = this.props;

        return (
            <SearchFormSubscriberCheckbox
                classNames={['SFW__subscriber-checkbox']}
                {...{
                    subscribeUser,
                    onSubscribeUserChange,
                    searchData,
                    style,
                    subscriberCheckboxLabel
                }}
            />
        );
    }
}
