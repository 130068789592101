import { defineMessages } from 'react-intl';

export default defineMessages({
    label: {
        id: 'NumStopsFareFilter_label',
        defaultMessage: 'Stops'
    },
    nonStop: {
        id: 'NumStopsFareFilter_nonstop',
        defaultMessage: 'Nonstop'
    },
    onePlusStops: {
        id: 'NumStopsFareFilter_onePlusStops',
        defaultMessage: '1+ Stops'
    }
});
