import moment from 'moment';

import {
    Fare,
    FareAction,
    FareAttributeCode,
    FareInstance,
    FareListGroup,
    FareNumStops,
    FareSort,
    FareSortDir,
    FareSortField,
    FareType,
    FareBlackout
} from 'models/Fares';
import {
    IncomingDisplayFareInstance,
    IncomingDisplayFaresWrapper,
    IncomingFareBlackout,
    IncomingFareListGroup,
    IncomingFilterableFareDetail
} from 'models/incomingData/Fares';

export function translateFareGroupMappings(
    fareGroups: IncomingFareListGroup[]
): {
    fareGroupRoutes: string[];
    fareGroupMapping: { [key: string]: FareListGroup };
} {
    const translatedFareGroups = fareGroups.map(fareGroup => translateFareGroup(fareGroup)),
        fareGroupRoutes: string[] = [],
        fareGroupMapping: { [key: string]: FareListGroup } = {};

    translatedFareGroups.forEach(fareGroup => {
        fareGroupRoutes.push(fareGroup.airportRoute);
        fareGroupMapping[fareGroup.airportRoute] = fareGroup;
    });

    return { fareGroupRoutes, fareGroupMapping };
}

/**
 * Translates a fare group object as supplied by the server into a fare group object used by the front end
 * @param fareGroup
 */
export function translateFareGroup(fareGroup: IncomingFareListGroup): FareListGroup {
    const { airportRoute, arrivalCode, cityFareAction, departureCode, fareRouteUrl, displayFares } = fareGroup;

    return {
        airportRoute,
        arrivalCode,
        departureCode,
        fareAction: cityFareAction,
        fareRouteUrl,
        fares: displayFares.map(translateDisplayFare)
    };
}

export function translateDisplayFare(displayFare: IncomingDisplayFaresWrapper): Fare {
    const fare = displayFare.fare,
        fareInstances = displayFare.fareInstances.map(instance => translateFareInstance(instance)),
        dateFormat = 'YYYY-MM-DD';
    return {
        airlines: fare.airlines,
        arrivalCode: fare.arrivalCode,
        arrivalDisplayName: displayFare.arrivalAirportDisplay,
        currency: fare.currency,
        departureCode: fare.departureCode,
        departureDisplayName: displayFare.departureAirportDisplay,
        fareAttributes: fare.fareAttributes,
        fareInstances,
        id: fare.id,
        isRoundTrip: fare.roundTrip,
        price: fare.price,
        prices: fare.prices,
        purchaseBy: fare.purchaseBy ? moment(fare.purchaseBy, dateFormat) : undefined,
        minStay: fare.minStay,
        maxStay: fare.maxStay,
        advancePurchase: fare.advancePurchase,
        blackouts: fare.blackouts ? convertToFareBlackout(fare.blackouts) : undefined,
        fareComments: fare.commentBlock ? splitComments(fare.commentBlock) : undefined
    };
}

function splitComments(str: string): string[] {
    return str.split('\\n').filter(s => s !== '');
}

export function convertToFareBlackout(blackouts: IncomingFareBlackout[]): FareBlackout[] {
    const dateFormat = 'YYYY-MM-DD';
    return blackouts.map(incoming => {
        if (incoming.blackOutDay) {
            return { type: incoming.type, blackOutDay: moment(incoming.blackOutDay, dateFormat) };
        }
        return { type: incoming.type };
    });
}

function translateFareInstance(displayFareInstance: IncomingDisplayFareInstance): FareInstance {
    const fareInstance = displayFareInstance.fareInstance,
        dateFormat = 'YYYY-MM-DD',
        departureDate = fareInstance.departureDate ? moment(fareInstance.departureDate, dateFormat) : null,
        returnDate = fareInstance.returnDate ? moment(fareInstance.returnDate, dateFormat) : null,
        isNonStop = displayFareInstance.attributes.some(attr => attr.code === FareAttributeCode.NONSTOP);

    return {
        airlines: displayFareInstance.airlines,
        departureDate,
        fareAttributes: displayFareInstance.attributes,
        fareUrl: displayFareInstance.fareUrl,
        isFlexibleDates: displayFareInstance.flexibleDateFare,
        isNonStop,
        priceConfidenceAttribute: displayFareInstance.priceConfidenceAttribute,
        returnDate,
        tabBrowsingUrl: displayFareInstance.tabsUrl,
        priceHistory: displayFareInstance.fareInstance.farePriceHistory || {}
    };
}

/**
 * Gets the FareType filter value from the list of fare type filters as sent from the server.
 * @param fareTypeFilters
 */
export function translateIncomingFareTypeFilter(fareTypeFilters: FareType[]): FareType | undefined {
    const filters = fareTypeFilters.map(f => f.toUpperCase()).filter(f => Object.keys(FareType).includes(f));

    if (!filters.length || filters.length > 1) {
        // Since there are only two options for fare type, specifying both is the same as selecting none
        return;
    }

    return FareType[filters[0] as keyof typeof FareType];
}

/**
 * Gets the FareNumStops filter value from the list of fare detail filters sent from the server.
 * @param fareDetailFilters
 */
export function translateIncomingNumStopsFilter(
    fareDetailFilters: IncomingFilterableFareDetail[]
): FareNumStops | undefined {
    const filters = fareDetailFilters.map(f => f.toUpperCase()).filter(f => Object.keys(FareNumStops).includes(f));

    if (!filters.length || filters.length > 1) {
        // Since there are only two options for num stops, specifying both is the same as selecting none
        return;
    }
    return FareNumStops[filters[0] as keyof typeof FareNumStops];
}

/**
 * Determines whether fares are being filtered for weekend-only based on fare detail filters sent from the server.
 * @param fareDetailFilters
 */
export function translateIncomingWeekendOnlyFilter(fareDetailFilters: IncomingFilterableFareDetail[]): boolean {
    return fareDetailFilters.map(f => f.toUpperCase()).includes(IncomingFilterableFareDetail.WEEKEND.toUpperCase());
}

/**
 * Translates the fare action as sent from the server into a fare action enum value used by the front-end. This
 * is mainly to handle the case where the data from the server has a different case than the front-end.
 * @param incomingFareAction
 */
export function translateIncomingFareAction(incomingFareAction: FareAction): FareAction {
    const fareAction = Object.keys(FareAction).find(a => a.toUpperCase() === incomingFareAction.toUpperCase());
    return FareAction[fareAction as keyof typeof FareAction];
}

/**
 * Translates the fare sort field and direction from the server into a FareSort object used by the front-end in a
 * case-insensitive way.
 * @param sortField
 * @param sortDir
 */
export function translateIncomingFareSort({
    incomingSortField,
    incomingSortDir
}: {
    incomingSortField: FareSortField;
    incomingSortDir: FareSortDir;
}): FareSort {
    const sortField = Object.keys(FareSortField).find(f => f.toLowerCase() === incomingSortField.toLowerCase());
    const sortDir = Object.keys(FareSortDir).find(d => d.toLowerCase() === incomingSortDir.toLowerCase());

    return {
        field: FareSortField[sortField as keyof typeof FareSortField],
        direction: FareSortDir[sortDir as keyof typeof FareSortDir]
    };
}
