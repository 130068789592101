import { defineMessages } from 'react-intl';

export default defineMessages({
    label: {
        id: 'FareTravelTimeFilter_label',
        defaultMessage: 'When to Travel'
    },
    optionLabelWeekend: {
        id: 'FareTravelTimeFilter_optionLabelWeekend',
        defaultMessage: 'Weekend Only'
    }
});
