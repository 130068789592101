import React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';

import './styles.scss';
import msgs from './messages';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';

export interface NumTravelersInputProps extends InjectedIntlProps {
    options?: number[];
    value?: number;
    onChange(numTravelers: number): void;
}

class NumTravelersInput extends React.Component<NumTravelersInputProps> {
    public static defaultProps = { options: [1, 2, 3, 4, 5, 6] };

    public render = () => {
        const numTravelers = this.props.value;
        return (
            <div className='num-travelers-input'>
                <span className='num-travelers-input__icon'>
                    <AwdIcon name={AwdIconName.People} />
                </span>
                <select
                    className='num-travelers-input__dropdown do_no_popunder'
                    onChange={this.onChange}
                    defaultValue={`${numTravelers}`}
                >
                    {this.props.options!.map(v => {
                        return (
                            <option key={`${v}-travelers`} value={`${v}`} className='num-travelers-input__option'>
                                {`${this.props.intl.formatMessage(msgs.numTravelers, { numTravelers: v })}`}
                            </option>
                        );
                    })}
                </select>
                <span className='num-travelers-input__dropdownIcon'>
                    <AwdIcon name={AwdIconName.ArrowDown} />
                </span>
            </div>
        );
    };

    private onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.onChange(parseInt(e.target.value, 10));
    };
}

export default injectIntl(NumTravelersInput);
