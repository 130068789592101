import React from 'react';
import Autosuggest from 'react-autosuggest';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';

import './styles.scss';
import InlineInputBaseWrapper, { InlineInputBaseWrapperProps } from '../BaseWrapper/inlineInputBaseWrapperProps';
import { Suggestion, SuggestionDecorator } from 'models/Suggestion';
import Autocomplete from 'constants/autocomplete';

interface InlineInputWithAutocompleteProps<T extends Suggestion> extends InlineInputBaseWrapperProps {
    getSuggestions: (queryText: string) => Promise<Array<SuggestionDecorator<T>>>; // Get suggestions for the provided query
    minLengthOfQuery?: number; // Minimum number of characters a user has to entry before we show the query suggestions
    placeholder: FormattedMessage.MessageDescriptor; // Placeholder text for the input
    selectHighlightedOnBlur?: boolean;
    initialValue?: string;
    onBlur?: (queryText: SuggestionDecorator<T>) => void; // Function to call on blur, for validation, etc
    onSuggestionSelected: (suggestion: SuggestionDecorator<T>) => void; // Handler called when a suggestion is selected
    intl: InjectedIntl; // Prop automatically injected by injectIntl: https://github.com/yahoo/react-intl/wiki/API#injectintl
}

interface InlineInputWithAutocompleteState<T extends Suggestion> {
    suggestions: Array<SuggestionDecorator<T>>; // Array containing the suggestions pertaining to the current input value
    value: string; // Current input value
    selectedValue: string; // Last selected input value
}

/**
 * Implementation of InlineInput with an auto-suggest type-ahead, typically
 * used for location inputs, etc
 */
class InlineInputWithAutocomplete<T extends Suggestion> extends React.Component<
    InlineInputWithAutocompleteProps<T>,
    InlineInputWithAutocompleteState<T>
> {
    public static defaultProps = { minLengthOfQuery: 3 };
    private readonly randomInt: number;

    constructor(props: InlineInputWithAutocompleteProps<T>) {
        super(props);
        const { initialValue } = this.props;
        this.state = { value: initialValue || '', suggestions: [], selectedValue: initialValue || '' };
        this.randomInt = Math.floor(Math.random() * 1_000_000_000);
    }

    public render() {
        const { value } = this.state,
            { intl, error, label, placeholder, iconName, showSpinner } = this.props,
            placeholderText = intl.formatMessage(placeholder);

        return (
            <InlineInputBaseWrapper
                classNames={['input-inline-with-autocomplete']}
                error={error}
                label={label}
                iconName={iconName}
                showSpinner={showSpinner}
            >
                <Autosuggest
                    suggestions={this.state.suggestions}
                    onSuggestionsFetchRequested={this.fetchSuggestions}
                    onSuggestionsClearRequested={this.clearSuggestions}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                    focusInputOnSuggestionClick={false}
                    highlightFirstSuggestion={true}
                    inputProps={{
                        value,
                        autoComplete: Autocomplete.OFF,
                        placeholder: placeholderText,
                        onBlur: this.onBlur,
                        onChange: this.onChange,
                        onFocus: this.onFocus,
                        type: 'text',
                        name: '__i' + this.randomInt, // Random name to bust browsers' attempts to autocomplete fields based on previous submissions
                        size: this.getWidthForInputField(value, placeholderText)
                    }}
                />
            </InlineInputBaseWrapper>
        );
    }

    private clearSuggestions = () => {
        this.setState({ suggestions: [] });
    };

    private fetchSuggestions = (query: Autosuggest.SuggestionsFetchRequestedParams) => {
        if (query.value === this.state.value) {
            // this library calls onSuggestionsFetchRequested when the input is focused. if that happens & the value
            // is unchanged, there's no reason to fetch suggestions again
            return;
        }
        this.props.getSuggestions(query.value).then(suggestions => {
            this.setState({ suggestions });
        });
    };

    private getSuggestionValue = (suggestion: SuggestionDecorator<T>) => suggestion.text;

    private getWidthForInputField = (inputValue: string, placeholderText: string): number => {
        const inputTextLength = inputValue.length,
            placeholderTextLength = placeholderText.length,
            textLength = inputTextLength < placeholderTextLength ? placeholderTextLength : inputTextLength;

        return Math.max(10, textLength);
    };

    private onChange = ({}, { newValue }: Autosuggest.ChangeEvent) => {
        this.setState({ value: newValue });
    };

    private onSuggestionSelected = (
        {},
        { suggestion }: Autosuggest.SuggestionSelectedEventData<SuggestionDecorator<T>>
    ) => {
        this.setState({ selectedValue: suggestion.text });
        this.props.onSuggestionSelected(suggestion);
        return suggestion;
    };

    private renderSuggestion = (suggestion: SuggestionDecorator<T>) => {
        return <div className='input-dotted-with-autocomplete__suggestion do_no_popunder'>{suggestion.text}</div>;
    };

    private shouldRenderSuggestions = (query: string) => {
        return query.length >= this.props.minLengthOfQuery!;
    };

    private onBlur = ({}, { highlightedSuggestion }: Autosuggest.BlurEvent<SuggestionDecorator<T>>) => {
        const suggestion = this.props.selectHighlightedOnBlur ? highlightedSuggestion : null;
        this.setState(prevState => ({ value: (suggestion && suggestion.text) || prevState.selectedValue }));
        if (suggestion) {
            this.props.onSuggestionSelected(suggestion);
            return this.props.onBlur && this.props.onBlur(suggestion);
        }
    };

    private onFocus = () => {
        this.setState({ value: '' });
    };
}

export default injectIntl(InlineInputWithAutocomplete);
