import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';
import { FareInstance } from 'models/Fares';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import { getDescriptorFareTags } from 'utils/fares/fareAttributes';

export enum FareInstanceSelectorType {
    INLINE = 'inline',
    MODAL = 'modal'
}

export interface FareInstanceSelectorProps {
    fareInstances: FareInstance[];
    getFormattedDates: (fareInstance: FareInstance) => ReactNode;
    listText: FormattedMessage.MessageDescriptor;
    onClose?: (e: Event) => void;
    onSelect?: (fareInstance: FareInstance) => void;
    selectedFareInstance?: FareInstance;
    type: FareInstanceSelectorType;
}

export default class FareInstanceSelector extends React.Component<FareInstanceSelectorProps> {
    public render() {
        const { fareInstances, listText, type } = this.props,
            isModal = type === FareInstanceSelectorType.MODAL;

        return (
            <div className={`fare-instance-selector fare-instance-selector--${type}`}>
                {isModal && (
                    <div className='fare-instance-selector__close' onClick={this.onClose}>
                        <AwdIcon name={AwdIconName.Ex} />
                    </div>
                )}
                {isModal && this.renderSelectedFareInstance()}
                <div className='fare-instance-selector__cta'>
                    <FormattedMessage {...listText} />
                </div>
                <div className='fare-instance-selector__more-dates'>
                    {fareInstances.map((fareInstance, idx) => this.renderFareInstance(fareInstance, idx))}
                </div>
            </div>
        );
    }
    private onClose = (e: React.MouseEvent) => {
        this.props.onClose && this.props.onClose(e.nativeEvent);
    };

    private renderSelectedFareInstance = () => {
        const { selectedFareInstance } = this.props;

        return (
            selectedFareInstance && (
                <div
                    className='fare-instance-selector__instance selected-instance'
                    onClick={() => {
                        this.props.onSelect && this.props.onSelect(selectedFareInstance);
                    }}
                >
                    <div className='fare-instance-selector__date-icon'>
                        <AwdIcon name={AwdIconName.Calendar} />
                    </div>
                    <div className='fare-instance-selector__dates selected-dates'>
                        {this.props.getFormattedDates(selectedFareInstance)}
                    </div>
                </div>
            )
        );
    };

    private renderFareInstance = (fareInstance: FareInstance, key: number | string) => {
        if (this.props.type === FareInstanceSelectorType.MODAL) {
            return (
                <div
                    className='fare-instance-selector__instance more-dates'
                    key={key}
                    onClick={() => {
                        this.props.onSelect && this.props.onSelect(fareInstance);
                    }}
                >
                    {this.renderFareInstanceContents(fareInstance)}
                </div>
            );
        }

        return (
            <a
                className='fare-instance-selector__instance more-dates'
                key={key}
                href={fareInstance.tabBrowsingUrl || fareInstance.fareUrl}
                target='_blank'
                rel='noopener'
            >
                {this.renderFareInstanceContents(fareInstance)}
            </a>
        );
    };

    private renderFareInstanceContents = (fareInstance: FareInstance) => {
        const fareAttribute = getDescriptorFareTags(fareInstance.fareAttributes)[0],
            fareAttributeDescription = fareAttribute && fareAttribute.shortDescription;

        return (
            <React.Fragment>
                <span className='fare-instance-selector__instance-container'>
                    <span className='fare-instance-selector__date-icon'>
                        <AwdIcon name={AwdIconName.Calendar} />
                    </span>
                    <span className='fare-instance-selector__dates'>{this.props.getFormattedDates(fareInstance)}</span>
                    <span className='fare-instance-selector__info'>{fareAttributeDescription}</span>
                </span>
                <span className='fare-instance-selector__arrow'>
                    <AwdIcon name={AwdIconName.ArrowRight} />
                </span>
            </React.Fragment>
        );
    };
}
