import { FareAttribute, FareAttributeCode } from 'models/Fares';

/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/api/src/main/java/com/smartertravel/awd/model/fare/FareAttributeUtils.java
 * This ranking and the corresponding server side ranking (linked above) should remain in sync
 */
const SPECIAL_FARE_TAG_RANKS: { [key: number]: number } = {
    [FareAttributeCode.MISTAKE]: 1,
    [FareAttributeCode.AMAZING_DEAL]: 2,
    [FareAttributeCode.PRICE_DROP]: 3,
    [FareAttributeCode.FARE_SALE]: 4,
    [FareAttributeCode.LAST_MINUTE]: 5,
    [FareAttributeCode.EVENT]: 6,
    [FareAttributeCode.ENDING_SOON]: 7,
    [FareAttributeCode.NEW_NONSTOP]: 8
};

/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/api/src/main/java/com/smartertravel/awd/model/fare/FareAttributeUtils.java
 * This ranking and the corresponding server side ranking (linked above) should remain in sync
 */
const DESCRIPTOR_FARE_TAG_RANKS: { [key: number]: number } = {
    [FareAttributeCode.NONSTOP]: 1,
    [FareAttributeCode.PROMO_CODE]: 2,
    [FareAttributeCode.FLEX]: 3,
    [FareAttributeCode.WEEKEND]: 4,
    [FareAttributeCode.UNADVERTISED]: 5,
    [FareAttributeCode.SITEDIRECT]: 6,
    [FareAttributeCode.MIDWEEK]: 7
};

/**
 * Returns the special tag fare attributes from the given list, sorted by rank ascending
 * @param fareAttributes
 */
export function getSpecialFareTags(fareAttributes: FareAttribute[]): FareAttribute[] {
    return fareAttributes
        .filter(attr => !!SPECIAL_FARE_TAG_RANKS[attr.code])
        .sort((attr1, attr2) => compareFareAttributesByRank(SPECIAL_FARE_TAG_RANKS, attr1, attr2));
}

/**
 * Returns the descriptor tag fare attributes from the given list, sorted by rank ascending
 * @param fareAttributes
 */
export function getDescriptorFareTags(fareAttributes: FareAttribute[]): FareAttribute[] {
    return fareAttributes
        .filter(attr => !!DESCRIPTOR_FARE_TAG_RANKS[attr.code])
        .sort((attr1, attr2) => compareFareAttributesByRank(DESCRIPTOR_FARE_TAG_RANKS, attr1, attr2));
}

/**
 * Compares two fare attributes by their rank. Lower rank = higher priority
 * @param attrRankMapping
 * @param attr1
 * @param attr2
 */
function compareFareAttributesByRank(
    attrRankMapping: { [key: number]: number },
    attr1: FareAttribute,
    attr2: FareAttribute
): number {
    const attrRank1 = attrRankMapping[attr1.code],
        attrRank2 = attrRankMapping[attr2.code];
    return attrRank1 - attrRank2;
}
