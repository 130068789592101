import React from 'react';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';
import { Fare } from 'models/Fares';
import getFormattedDates from 'components/FareView/shared/utils/getFormattedDates';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import { FareInstanceSelectorState } from 'components/FareView/shared/utils/fareInstanceSelectorReducer';

import './styles.scss';
import msgs from './messages';

function FareDates({
    fare,
    selectorState,
    intl,
    onMultiDateClicked,
    shouldMatchLegacyFareDetails = false
}: {
    fare: Fare;
    selectorState: FareInstanceSelectorState;
    intl: InjectedIntl;
    onMultiDateClicked: () => void;
    shouldMatchLegacyFareDetails?: boolean;
}) {
    const isMultiDate = fare.fareInstances.length > 1,
        { selectorVisible, selectedFareInstance, isUserSelectedFareInstance } = selectorState,
        onClick = (e: React.MouseEvent<HTMLDivElement>) => {
            if (!isMultiDate) {
                return;
            }

            e.stopPropagation(); // so we don't launch tab browsing when opening the date selector
            onMultiDateClicked();
        };

    return (
        <div className={`fare-view-dates ${isMultiDate ? 'fare-view-dates--multiDate' : ''}`} onClick={onClick}>
            <span
                className={`fare-view-dates__date-text ${
                    shouldMatchLegacyFareDetails ? 'fare-view-dates__date-text-legacy' : ''
                }`}
            >
                {isMultiDate && !isUserSelectedFareInstance ? (
                    <span className={shouldMatchLegacyFareDetails ? 'fare-view-dates__date-text-legacy-underline' : ''}>
                        <FormattedMessage {...msgs.fareMultiDateDefaultText} />
                    </span>
                ) : (
                    <span
                        className={
                            shouldMatchLegacyFareDetails && isMultiDate
                                ? 'fare-view-dates__date-text-legacy-underline'
                                : ''
                        }
                    >
                        {getFormattedDates({ intl, fareInstance: selectedFareInstance })}
                    </span>
                )}
            </span>
            {isMultiDate && (
                <span className='fare-view-dates__date-arrow'>
                    {selectorVisible ? (
                        <AwdIcon name={AwdIconName.ArrowUp} />
                    ) : (
                        <AwdIcon name={AwdIconName.ArrowDown} />
                    )}
                </span>
            )}
        </div>
    );
}

export default injectIntl(FareDates);
