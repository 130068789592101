import { defineMessages } from 'react-intl';
import {
    MSG_SUBSCRIBE_DISCLAIMER,
    MSG_SUBSCRIPTION_INFO_HOVER_AIR,
    MSG_SUBSCRIPTION_INFO_HOVER_HOTEL
} from 'constants/sharedMessages';

export default defineMessages({
    buttonText: {
        id: 'SearchForm_buttonText',
        defaultMessage: 'Find Fares'
    },
    date1Placeholder: {
        id: 'SearchForm_date1Placeholder',
        defaultMessage: 'Depart'
    },
    date2Placeholder: {
        id: 'SearchForm_date2Placeholder',
        defaultMessage: 'Return'
    },
    location1Label: {
        id: 'SearchForm_location1Label',
        defaultMessage: 'From:'
    },
    location1Placeholder: {
        id: 'SearchForm_location1Placeholder',
        defaultMessage: 'Enter From City'
    },
    location2Label: {
        id: 'SearchForm_location2Label',
        defaultMessage: 'To:'
    },
    location2Placeholder: {
        id: 'SearchForm_location2Placeholder',
        defaultMessage: 'Enter To City'
    },
    datesLabel: {
        id: 'SearchForm_datesLabel',
        defaultMessage: 'Travel dates:'
    },
    subscriptionHeader: {
        id: 'SearchForm_subscriptionHeaderNoLocation',
        defaultMessage: 'Send me great deals:'
    },
    subscriptionHeaderHotel: {
        id: 'SearchForm_subscriptionHeaderHotel',
        defaultMessage: 'Send me great hotel deals:'
    },
    subscriptionHeaderHotelCity: {
        id: 'SearchForm_subscriptionHeaderHotelCity',
        defaultMessage: 'Send me hotel deals for this city:'
    },
    subscriptionHeaderRoute: {
        id: 'SearchForm_subscriptionHeaderRoute',
        defaultMessage: 'Send me deals on this route:'
    },
    subscriberCheckboxLabelNoLocation: {
        id: 'SearchForm_subscriberCheckboxLabelNoLocation',
        defaultMessage: 'Deals, travel tips & news'
    },
    subscriptionHoverAir: {
        id: 'SearchForm_subscriptionHoverAir',
        defaultMessage: MSG_SUBSCRIPTION_INFO_HOVER_AIR
    },
    subscriptionHoverHotel: {
        id: 'SearchForm_subscriptionHoverHotel',
        defaultMessage: MSG_SUBSCRIPTION_INFO_HOVER_HOTEL
    },
    emailPlaceholder: {
        id: 'SearchForm_emailPlaceholder',
        defaultMessage: 'Enter your email address'
    },
    subscribeDisclaimer: {
        id: 'SearchForm_subscribeDisclaimer',
        defaultMessage: MSG_SUBSCRIBE_DISCLAIMER
    }
});
