import React from 'react';
import { SearchFormProps, SearchFormStyle } from '../props';
import { isCitySuggestion, Suggestion } from 'models/Suggestion';
import Checkbox, { CheckboxTheme } from 'components/Checkbox';
import { FormattedMessage } from 'react-intl';
import msgs from '../messages';
import SearchData, { SearchType } from 'models/SearchData';
import Tooltip, { TooltipPosition } from 'components/Tooltip';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';

type SubscriberCheckboxSearchFormProps<T extends Suggestion> = Pick<
    SearchFormProps<T>,
    'subscribeUser' | 'onSubscribeUserChange' | 'searchData' | 'style' | 'subscriberCheckboxLabel'
>;

interface SubscriberCheckboxProps<T extends Suggestion> extends SubscriberCheckboxSearchFormProps<T> {
    classNames?: string[];
}

export default function SubscriberCheckbox<T extends Suggestion>({
    classNames,
    subscribeUser,
    onSubscribeUserChange,
    searchData,
    style,
    subscriberCheckboxLabel
}: SubscriberCheckboxProps<T>) {
    const tooltipMessage =
        searchData.searchType === SearchType.AIR ? msgs.subscriptionHoverAir : msgs.subscriptionHoverHotel;

    return (
        <div className={`search-form__subscriber-checkbox ${classNames ? classNames.join(' ') : ''} do_no_popunder`}>
            <Checkbox
                checked={subscribeUser}
                onChange={onSubscribeUserChange}
                theme={style === SearchFormStyle.WHITE ? CheckboxTheme.Blue : CheckboxTheme.Default}
            >
                {subscriberCheckboxLabel ? (
                    <FormattedMessage {...subscriberCheckboxLabel} />
                ) : (
                    getCheckboxLabel(searchData)
                )}

                <Tooltip
                    anchorClassNames={['search-form__subscriber-checkbox__info']}
                    containerClassNames={['search-form__subscriber-checkbox__tooltip-container']}
                    tooltipClassNames={['search-form__subscriber-checkbox__tooltip']}
                    anchorContents={<AwdIcon name={AwdIconName.Info} />}
                    tooltipContents={<FormattedMessage {...tooltipMessage} />}
                    position={TooltipPosition.TOP}
                />
            </Checkbox>
        </div>
    );
}

function getCheckboxLabel<T extends Suggestion>(searchData: SearchData<T>) {
    if (
        searchData.searchType === SearchType.HOTEL &&
        searchData.location2 &&
        isCitySuggestion(searchData.location2.suggestion)
    ) {
        return <span>{searchData.location2.suggestion.cityName}</span>;
    }
    if (searchData.location1 && searchData.location2) {
        return (
            <span>
                {searchData.location1.text} - {searchData.location2.text}
            </span>
        );
    }
    if (searchData.location1) {
        return <span>{searchData.location1.text}</span>;
    }
    return <FormattedMessage {...msgs.subscriberCheckboxLabelNoLocation} />;
}
