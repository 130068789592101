import React, { ReactNode } from 'react';
import './styles.scss';

export enum TooltipPosition {
    TOP = 'top',
    RIGHT = 'right',
    BOTTOM = 'bottom',
    LEFT = 'left'
}

export enum TooltipVisibility {
    HIDDEN = 'hidden',
    VISIBLE = 'visible',
    HOVER = 'hover'
}

interface TooltipProps {
    anchorClassNames?: string[];
    anchorContents: ReactNode;
    containerClassNames?: string[];
    position?: TooltipPosition;
    tooltipClassNames?: string[];
    tooltipContents: ReactNode;
    tooltipVisibility?: TooltipVisibility;
}

export default function Tooltip({
    anchorClassNames,
    anchorContents,
    containerClassNames,
    position = TooltipPosition.BOTTOM,
    tooltipClassNames,
    tooltipContents,
    tooltipVisibility = TooltipVisibility.HOVER
}: TooltipProps) {
    return (
        <div className={`react-tooltip-anchor ${getClassNameString(anchorClassNames)}`}>
            {anchorContents}

            <div
                className={`react-tooltip-container react-tooltip-container--${position} react-tooltip-container--${tooltipVisibility} ${getClassNameString(
                    containerClassNames
                )}`}
            >
                <div className={`react-tooltip react-tooltip--${position} ${getClassNameString(tooltipClassNames)}`}>
                    {tooltipContents}
                </div>
            </div>
        </div>
    );
}

function getClassNameString(classNames?: string[]) {
    return classNames ? classNames.join(' ') : '';
}
