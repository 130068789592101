import React, { ReactNode } from 'react';

import './styles.scss';
import FilterButton from '../Button';
import FilterModal from '../Modal';
import { AwdIconName } from 'components/AwdIcon';
import AwdIcon from 'components/AwdIcon';

export enum DropdownFilterWrapperStyle {
    Button = 'button',
    Field = 'field'
}

interface DropdownFilterWrapperProps {
    buttonLabel: string;
    isFilterSet: boolean;
    filterFieldIconName?: AwdIconName;
    filterLabel: string;
    filterStyle?: DropdownFilterWrapperStyle;
    children(closeModal: () => void): ReactNode; // A render prop to pass a callback to close the modal to child components
    onClearFilter?(): void;
}

interface DropdownFilterWrapperState {
    isModalOpen: boolean;
}

/**
 * A wrapper component with a filter button and dropdown modal. A more generic version of the DropdownFilter component,
 * since it can display any child component as the content of the dropdown.
 */
export default class DropdownFilterWrapper extends React.Component<
    DropdownFilterWrapperProps,
    DropdownFilterWrapperState
> {
    public static defaultProps = { filterStyle: DropdownFilterWrapperStyle.Button };
    public state: DropdownFilterWrapperState = { isModalOpen: false };

    public render = () => {
        const { children, filterLabel, filterStyle } = this.props,
            { isModalOpen } = this.state;

        return (
            <div className={`filter filter-dropdown filter-dropdown--${filterStyle} do_no_popunder`}>
                {this.renderFilterButton()}
                {isModalOpen && (
                    <FilterModal labelText={filterLabel} onClose={this.closeModal}>
                        {children(this.closeModal)}
                    </FilterModal>
                )}
            </div>
        );
    };

    private renderFilterButton = () => {
        const { buttonLabel, filterFieldIconName, filterStyle, isFilterSet } = this.props;

        if (filterStyle === DropdownFilterWrapperStyle.Button) {
            return (
                <FilterButton
                    isFilterSet={isFilterSet}
                    text={buttonLabel}
                    onClick={this.openModal}
                    onClearFilter={this.onClearFilter}
                />
            );
        }

        return (
            <div className='filter-dropdown__field' onClick={this.openModal}>
                {filterFieldIconName && (
                    <span className='filter-dropdown__field-icon'>
                        <AwdIcon name={filterFieldIconName} />
                    </span>
                )}
                <span className='filter-dropdown__field-label'>{buttonLabel}</span>
            </div>
        );
    };

    private openModal = () => {
        this.setState({ isModalOpen: true });
    };

    private onClearFilter = () => {
        this.props.onClearFilter && this.props.onClearFilter();
        this.closeModal();
    };

    private closeModal = () => {
        this.setState({ isModalOpen: false });
    };
}
