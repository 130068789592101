import React from 'react';

import { AwdIconName } from 'components/AwdIcon';
import { URL_AWD_TWITTER } from 'constants/urls';
import RailCard from '../index';
import msgs from './messages';

export default function TwitterRailCard() {
    return (
        <RailCard
            iconName={AwdIconName.Twitter}
            title={msgs.twitterCardTitle}
            description={msgs.twitterCardDescription}
            buttonText={msgs.twitterCardButton}
            linkUrl={URL_AWD_TWITTER}
        />
    );
}
