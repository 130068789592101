import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getAirportSuggestions, LocationSuggestType } from 'api/locationSuggest';
import { getAirSearchData, updateAirSearchData } from 'ducks/airSearchData';
import { AirportSuggestion } from 'models/Suggestion';
import { getSubscriber, queueNewSubscriber } from 'ducks/user';
import SearchFormDateless from './index';
import SearchData, { SearchDataWithRequiredLocation1 } from 'models/SearchData';
import { Subscriber } from 'models/Subscriptions';
import { AirSearchFormState, openFarePageAndSubscribeUser } from 'ducks/airSearchForm';
import {
    fareFiltersActions,
    getSelectedNumStops,
    getSelectedTravelMonths,
    isWeekendOnly,
    StoreWithFareFiltersState
} from 'ducks/fareFilters';
import { FareNumStops } from 'models/Fares';

const getOriginLocationSuggestions = async (query: string) => {
    return getAirportSuggestions({ query, searchType: LocationSuggestType.AIRPORTS_DOMESTIC });
};

const getDestinationLocationSuggestions = async (query: string) => {
    return getAirportSuggestions({ query, searchType: LocationSuggestType.AIRPORTS_ALL });
};

const mapStateToProps = (state: AirSearchFormState & StoreWithFareFiltersState) => ({
    filterNumStops: getSelectedNumStops(state),
    filterTravelMonths: getSelectedTravelMonths(state),
    filterWeekendOnly: isWeekendOnly(state),
    getOriginLocationSuggestions,
    getDestinationLocationSuggestions,
    searchData: getAirSearchData(state),
    subscriber: getSubscriber(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AirSearchFormState, void, AnyAction>) => ({
    onFilterNumStopsChange: (numStops: FareNumStops) => dispatch(fareFiltersActions.setNumStops(numStops)),
    onFilterNumStopsClear: () => dispatch(fareFiltersActions.clearNumStops()),
    onFilterMonthToggled: (monthStr: string) => dispatch(fareFiltersActions.toggleTravelMonth(monthStr)),
    onFilterWeekendToggled: () => dispatch(fareFiltersActions.toggleWeekendOnly()),
    onFilterTravelTimeClear: () => {
        dispatch(fareFiltersActions.clearWeekendOnly());
        dispatch(fareFiltersActions.clearTravelMonths());
    },
    onSearchDataChange: (searchData: SearchData<AirportSuggestion>) => dispatch(updateAirSearchData(searchData)),
    onSubscriberChange: (subscriber: Subscriber) => dispatch(queueNewSubscriber(subscriber)),
    onSubmit: ({
        searchData,
        shouldSubscribeUser,
        subscriber,
        filterNumStops,
        filterTravelMonths,
        filterWeekendOnly
    }: {
        searchData: SearchDataWithRequiredLocation1<AirportSuggestion>;
        shouldSubscribeUser: boolean;
        subscriber?: Subscriber | null;
        filterNumStops?: FareNumStops;
        filterTravelMonths?: string[];
        filterWeekendOnly?: boolean;
    }) =>
        dispatch(
            openFarePageAndSubscribeUser({
                searchData,
                shouldSubscribeUser,
                subscriber,
                filterOptions: { filterNumStops, filterTravelMonths, filterWeekendOnly }
            })
        )
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFormDateless);
