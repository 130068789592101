import moment from 'moment';

/**
 * Returns a list of Moment objects for a number of upcoming months (default 6),
 * starting from the current month.
 * @param numMonths
 */
export function getUpcomingMonths(numMonths = 6): moment.Moment[] {
    const months = [];

    for (let i = 0; i < numMonths; i++) {
        months.push(
            moment()
                .startOf('month')
                .add(i, 'months')
        );
    }

    return months;
}
