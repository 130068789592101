import { FareInstance } from 'models/Fares';
import isEqual from 'lodash/isEqual';

export default function getUnselectedFareInstances({
    fareInstances,
    selectedFareInstance
}: {
    fareInstances: FareInstance[];
    selectedFareInstance?: FareInstance;
}) {
    if (!selectedFareInstance) {
        return fareInstances;
    }

    return fareInstances.filter(inst => !isEqual(inst, selectedFareInstance));
}
