import React from 'react';
import { injectIntl, InjectedIntl } from 'react-intl';
import moment from 'moment';

import msgs from './messages';
import { DropdownFilter, FilterOption } from 'components/Filter';
import { DateFormat } from 'constants/date-formats';
import { getUpcomingMonths } from 'utils/fares/filters';
import { DropdownFilterWrapperStyle } from 'components/Filter/DropdownWrapper';
import { AwdIconName } from 'components/AwdIcon';

interface FareTravelTimeFilterProps {
    buttonPlaceholder?: string;
    filterStyle?: DropdownFilterWrapperStyle;
    filterFieldIconName?: AwdIconName;
    filterLabel?: string;
    intl: InjectedIntl;
    numMonths?: number; // the number of upcoming months to display as options
    isWeekendOnly: boolean;
    selectedMonths: string[];
    onMonthToggled(monthStr: string): void;
    onWeekendOnlyToggled(): void;
    onClearFilter(): void;
}

/**
 * A button and dropdown for filtering fares by travel time (e.g. months, weekend-only).
 */
class FareTravelTimeFilter extends React.Component<FareTravelTimeFilterProps> {
    public static defaultProps = { numMonths: 6 };
    private weekendOptionId = 'weekend';

    public render() {
        const { buttonPlaceholder, filterLabel, filterFieldIconName, filterStyle, intl, onClearFilter } = this.props;

        return (
            <DropdownFilter
                buttonPlaceholder={buttonPlaceholder}
                filterFieldIconName={filterFieldIconName}
                filterLabel={filterLabel || intl.formatMessage(msgs.label)}
                filterStyle={filterStyle}
                isMultiSelect={true}
                options={this.getOptions()}
                selectedOptions={this.getSelectedOptions()}
                showSelectAll={false}
                onClearFilter={onClearFilter}
                onOptionChanged={this.onOptionToggled}
            />
        );
    }

    private getOptions = (): Array<FilterOption<string>> => {
        return [
            {
                id: this.weekendOptionId,
                description: this.props.intl.formatMessage(msgs.optionLabelWeekend)
            }
        ].concat(
            getUpcomingMonths(this.props.numMonths).map((m: moment.Moment) => ({
                id: m.format(DateFormat.yearDashMonth),
                description: m.format(DateFormat.monthFull)
            }))
        );
    };

    private onOptionToggled = (option: FilterOption<string>) => {
        if (option.id === this.weekendOptionId) {
            this.props.onWeekendOnlyToggled();
        } else {
            this.props.onMonthToggled(option.id);
        }
    };

    private getSelectedOptions = (): Array<FilterOption<string>> => {
        const { isWeekendOnly, selectedMonths } = this.props;

        return this.getOptions().filter(option => {
            return (isWeekendOnly && option.id === this.weekendOptionId) || selectedMonths.includes(option.id);
        });
    };
}

export default injectIntl(FareTravelTimeFilter);
