import { defineMessages } from 'react-intl';

export default defineMessages({
    weekenderCardTitle: {
        id: 'RailCard_weekenderCardTitle',
        defaultMessage: 'The Weekender'
    },
    weekenderCardDescription: {
        id: 'RailCard_weekenderCardDescription',
        defaultMessage: 'Explore the best flight and hotel deals for weekend travel'
    },
    weekenderCardButton: {
        id: 'RailCard_weekenderCardButton',
        defaultMessage: 'Explore Deals'
    }
});
