import { defineMessages } from 'react-intl';

export default defineMessages({
    numTravelers: {
        id: 'NumTravelersInput',
        defaultMessage: `{numTravelers, plural,
            one {{numTravelers} Traveler}
            other {{numTravelers} Travelers}
        }`
    }
});
