import React from 'react';
import { SearchFormProps } from 'components/SearchForm/common/props';
import { Suggestion } from 'models/Suggestion';
import { FormattedMessage } from 'react-intl';
import { FlightSearchType } from 'models/SearchData';
import { SearchFormField } from '../fields';
import msgs from '../messages';
import { DateFormat } from 'constants/date-formats';
import { DatePickerRange, DatePickerSingle } from 'components/DatePicker';

type DatesFieldSearchFormProps<T extends Suggestion> = Pick<
    SearchFormProps<T>,
    'datesLabel' | 'fieldsInError' | 'insetLabels' | 'onDateChange' | 'onDatesChange' | 'searchData'
>;

interface DatesFieldProps<T extends Suggestion> extends DatesFieldSearchFormProps<T> {
    containerClassNames?: string[];
    fieldClassNames?: string[];
}

export default function DatesField<T extends Suggestion>({
    containerClassNames,
    fieldClassNames,
    datesLabel,
    fieldsInError,
    insetLabels,
    onDateChange,
    onDatesChange,
    searchData
}: DatesFieldProps<T>) {
    const { flightSearchType, date1, date2 } = searchData;

    return (
        <div className={`search-form__field-container ${containerClassNames ? containerClassNames.join(' ') : ''}`}>
            {!insetLabels && (
                <div className='search-form__field-label'>
                    <FormattedMessage {...(datesLabel || msgs.datesLabel)} />
                </div>
            )}
            <div
                className={`search-form__field search-form__field3 ${fieldClassNames ? fieldClassNames.join(' ') : ''}`}
            >
                {flightSearchType === FlightSearchType.ONEWAY ? (
                    <DatePickerSingle
                        inError={fieldsInError.includes(SearchFormField.DATES)}
                        date={date1}
                        placeholder={msgs.date1Placeholder}
                        dateFormat={DateFormat.weekdayMonthDay}
                        onDateChange={onDateChange}
                    />
                ) : (
                    <DatePickerRange
                        inError={fieldsInError.includes(SearchFormField.DATES)}
                        date1={date1}
                        date1Placeholder={msgs.date1Placeholder}
                        date2={date2}
                        date2Placeholder={msgs.date2Placeholder}
                        dateFormat={DateFormat.weekdayMonthDay}
                        onDatesChange={onDatesChange}
                    />
                )}
            </div>
        </div>
    );
}
