import { RefObject } from 'react';
import { Subtract } from 'utility-types';
import { FormattedMessage } from 'react-intl';

import { Suggestion, SuggestionDecorator } from 'models/Suggestion';
import { MomentDate } from 'models/Date';
import { FlightSearchType } from 'models/SearchData';
import SearchData from 'models/SearchData';
import { Subscriber } from 'models/Subscriptions';
import { SearchFormField } from './fields';

export enum SearchFormStyle {
    DEFAULT = 'default',
    WHITE = 'white'
}

// Props injected into the wrapped component by the HOC function and thus not needing to
// be provided by the consumer of the HOC
export interface SearchFormInjectedProps {
    fieldsInError: SearchFormField[];
    isCompact: boolean;
    isEmailFieldVisible: boolean;
    onDateChange: (date: MomentDate) => void;
    onDatesChange: (dates: { date1: MomentDate; date2: MomentDate }) => void;
    onEmailBlur: (email: string) => void;
    onFlightTypeChange: (flightType: FlightSearchType) => void;
    getOnLocationBlurHandler: <S extends Suggestion>(
        fieldName: SearchFormField
    ) => (fieldValue: SuggestionDecorator<S>) => void;
    getOnLocationChangeHandler: <S extends Suggestion>(
        fieldName: SearchFormField
    ) => (fieldValue: SuggestionDecorator<S>) => void;
    onSubscribeUserChange: (subscribeUser: boolean) => void;
    onTravelersChange: (travelers: number) => void;
    onSubmit: () => void;
    subscribeUser: boolean;
    onClick?: () => void;
}

// Props for the wrapped component being returned by the HOC (AKA the actual SearchForm components).
// These include the injected props above.
export interface SearchFormProps<T extends Suggestion> extends SearchFormInjectedProps {
    onChange: ({ searchData, subscriber }: { searchData?: SearchData<T>; subscriber?: Subscriber }) => void;
    searchData: SearchData<T>;
    getLocationSuggestions: (queryText: string) => Promise<Array<SuggestionDecorator<T>>>; // Get location suggestions for the input fields
    subscriber?: Subscriber;
    useShortFlightTypeLabels?: boolean;
    header?: FormattedMessage.Props;
    largeHeader?: boolean;
    straightCorners?: boolean;
    innerRef?: RefObject<HTMLDivElement>; // Used by react-waypoint: https://github.com/brigade/react-waypoint#children
    expandedEmailField?: boolean; // If true, the email field will be shown even if the checkbox is unchecked
    style?: SearchFormStyle;
    hideTopRowOptions?: boolean;
    insetLabels?: boolean;
    redirectOnSubmit?: boolean; // If true for an air search, redirect to the fare details page (if location1 and 2 are both present) or the fare listing page on submit
    subscriptionFieldsAfterButton?: boolean;
    buttonTextSize?: string;
    headerFontSize?: string;

    // Overrides for default messages
    buttonText?: FormattedMessage.Props;
    location1Label?: FormattedMessage.Props;
    location2Label?: FormattedMessage.Props;
    datesLabel?: FormattedMessage.Props;
    subscriberCheckboxLabel?: FormattedMessage.Props;
    emailFieldPlaceholder?: FormattedMessage.Props;
    location1Placeholder?: FormattedMessage.Props;
    location2Placeholder?: FormattedMessage.Props;
}

// Props that the component returned by the HOC function actually needs to receive. In other words,
// these are the props we will typically need to care about when creating a SearchForm. They are created
// by taking the props we need for the wrapped component, removing those props that are generated by
// the HOC below, and then adding any additional ones needed by the HOC to do its thing
export type SearchFormHocProps<T extends Suggestion> = Subtract<SearchFormProps<T>, SearchFormInjectedProps> & {
    onFormSubmit: ({
        searchData,
        subscribeUser,
        subscriber,
        redirectOnSubmit
    }: {
        searchData: SearchData<T>;
        subscribeUser: boolean;
        subscriber?: Subscriber | null;
        redirectOnSubmit?: boolean;
    }) => void;
    isExpandable?: boolean;
};
