import xor from 'lodash/xor';
import { ActionType, getType, createAction } from 'typesafe-actions';

import Airline from 'models/Airline';
import { FareNumStops, FareSort, FareType } from 'models/Fares';
import { AirportSuggestion } from 'models/Suggestion';

export type FareFiltersState = Readonly<{
    airlineCodes: string[];
    fareType?: FareType;
    numStops?: FareNumStops;
    otherAirlines: Airline[];
    priorityAirlines: Airline[];
    routeFilter?: AirportSuggestion;
    sort?: FareSort;
    travelMonths: string[]; // ["2019-11", "2019-12"]
    weekendOnly: boolean;
}>;

export interface StoreWithFareFiltersState {
    fareFilters: FareFiltersState;
}

export const fareFiltersInitialState: FareFiltersState = {
    airlineCodes: [],
    otherAirlines: [],
    priorityAirlines: [],
    travelMonths: [],
    weekendOnly: false
};

export const fareFiltersActions = {
    toggleAirline: createAction('awd/fareFilters/TOGGLE_AIRLINE', resolve => (airlineCode: string) =>
        resolve(airlineCode)
    ),
    clearAllAirlines: createAction('awd/fareFilters/CLEAR_ALL_AIRLINES'),
    selectAllAirlines: createAction('awd/fareFilters/SELECT_ALL_AIRLINES'),
    setRouteFilter: createAction('awd/fareFilters/SET_ROUTE_FILTER', resolve => {
        return (airportSuggestion: AirportSuggestion) => resolve(airportSuggestion);
    }),
    clearRouteFilter: createAction('awd/fareFilters/CLEAR_ROUTE_FILTER'),
    setNumStops: createAction('awd/fareFilters/SET_FARE_STOPS', resolve => {
        return (numStops: FareNumStops) => resolve(numStops);
    }),
    clearNumStops: createAction('awd/fareFilters/CLEAR_FARE_STOPS'),
    setFareType: createAction('awd/fareFilters/SET_FARE_TYPE', resolve => {
        return (fareType: FareType) => resolve(fareType);
    }),
    clearFareType: createAction('awd/fareFilters/CLEAR_FARE_TYPE'),
    toggleWeekendOnly: createAction('awd/fareFilters/TOGGLE_WEEKEND_ONLY'),
    clearWeekendOnly: createAction('awd/fareFilters/CLEAR_WEEKEND_ONLY'),
    toggleTravelMonth: createAction('awd/fareFilters/TOGGLE_TRAVEL_MONTH', resolve => (travelMonth: string) =>
        resolve(travelMonth)
    ),
    clearTravelMonths: createAction('awd/fareFilters/CLEAR_TRAVEL_MONTHS'),
    setFareSort: createAction('awd/fareFilters/SET_FARE_SORT', resolve => {
        return (sort: FareSort) => resolve(sort);
    })
};
export type FareFiltersAction = ActionType<typeof fareFiltersActions>;

export default function reducer(
    state: FareFiltersState = fareFiltersInitialState,
    action: FareFiltersAction
): FareFiltersState {
    switch (action.type) {
        case getType(fareFiltersActions.toggleAirline):
            return { ...state, airlineCodes: xor(state.airlineCodes, [action.payload]) };
        case getType(fareFiltersActions.clearAllAirlines):
            return { ...state, airlineCodes: [] };
        case getType(fareFiltersActions.selectAllAirlines):
            return {
                ...state,
                airlineCodes: [...state.priorityAirlines!, ...state.otherAirlines!].map(a => a.iataCode)
            };
        case getType(fareFiltersActions.setRouteFilter):
            return { ...state, routeFilter: action.payload };
        case getType(fareFiltersActions.clearRouteFilter):
            return { ...state, routeFilter: undefined };
        case getType(fareFiltersActions.setNumStops):
            return { ...state, numStops: action.payload };
        case getType(fareFiltersActions.clearNumStops):
            return { ...state, numStops: undefined };
        case getType(fareFiltersActions.setFareType):
            return { ...state, fareType: action.payload };
        case getType(fareFiltersActions.clearFareType):
            return { ...state, fareType: undefined };
        case getType(fareFiltersActions.toggleWeekendOnly):
            return { ...state, weekendOnly: !state.weekendOnly };
        case getType(fareFiltersActions.clearWeekendOnly):
            return { ...state, weekendOnly: false };
        case getType(fareFiltersActions.toggleTravelMonth):
            return { ...state, travelMonths: xor(state.travelMonths, [action.payload]) };
        case getType(fareFiltersActions.clearTravelMonths):
            return { ...state, travelMonths: [] };
        case getType(fareFiltersActions.setFareSort):
            return { ...state, sort: action.payload };
        default:
            return state;
    }
}

export function getSelectedAirlineCodes(state: StoreWithFareFiltersState): string[] {
    return state.fareFilters.airlineCodes || [];
}

export function getExcludedAirlineCodes(state: StoreWithFareFiltersState): string[] {
    const allAirlineCodes = [...getPriorityAirlines(state), ...getOtherAirlines(state)].map(code => code.iataCode);

    return xor(allAirlineCodes, getSelectedAirlineCodes(state));
}

export function getRouteFilter(state: StoreWithFareFiltersState): AirportSuggestion | undefined {
    return state.fareFilters.routeFilter;
}

export function getSelectedFareType(state: StoreWithFareFiltersState): FareType | undefined {
    return state.fareFilters.fareType;
}

export function getSelectedNumStops(state: StoreWithFareFiltersState): FareNumStops | undefined {
    return state.fareFilters.numStops;
}

export function getPriorityAirlines(state: StoreWithFareFiltersState): Airline[] {
    return state.fareFilters.priorityAirlines;
}

export function getOtherAirlines(state: StoreWithFareFiltersState): Airline[] {
    return state.fareFilters.otherAirlines;
}

export function isWeekendOnly(state: StoreWithFareFiltersState): boolean {
    return state.fareFilters.weekendOnly;
}

export function getSelectedTravelMonths(state: StoreWithFareFiltersState): string[] {
    return state.fareFilters.travelMonths;
}

export function getSort(state: StoreWithFareFiltersState): FareSort | undefined {
    return state.fareFilters.sort;
}
