import { defineMessages } from 'react-intl';

export default defineMessages({
    dateRange: {
        id: 'FareCol_dateRange',
        defaultMessage: '{date1} - {date2}'
    },
    seeFareDetails: {
        id: 'FareCol_seeFareDetails',
        defaultMessage: 'See fare details'
    }
});
