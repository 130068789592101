import { defineMessages } from 'react-intl';

export default defineMessages({
    clearAll: {
        id: 'FilterOptionsList_clearAll',
        defaultMessage: 'Clear All'
    },
    selectAll: {
        id: 'FilterOptionsList_selectAll',
        defaultMessage: 'Select All'
    },
    showAll: {
        id: 'FilterOptionsList_showAll',
        defaultMessage: 'Show All'
    }
});
