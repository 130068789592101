import React from 'react';
import without from 'lodash/without';

import { FlightSearchType } from 'models/SearchData';
import msgs from './common/messages';
import { MomentDate } from 'models/Date';
import { Suggestion, SuggestionDecorator } from 'models/Suggestion';
import { isValidEmail } from 'utils/email';
import { SearchFormHocProps, SearchFormProps, SearchFormStyle } from './common/props';
import { SearchFormField } from './common/fields';
import SearchType from 'models/SearchData/enums/SearchType';

interface SearchFormState {
    fieldsInError: SearchFormField[];
    subscribeUser: boolean;
    isCompact: boolean;
}

/**
 * Higher order component encapsulating the shared logic and state for the SearchForm components.
 *
 * @param SearchFormComponent
 */
export default function searchFormHoc<T extends Suggestion>(
    SearchFormComponent: React.ComponentType<SearchFormProps<T>>
) {
    return class SearchFormHoc extends React.Component<SearchFormHocProps<T>, SearchFormState> {
        public static defaultProps = {
            style: SearchFormStyle.DEFAULT,
            insetLabels: true,
            location1Label: msgs.location1Label,
            location2Label: msgs.location2Label,
            location1Placeholder: msgs.location1Placeholder,
            location2Placeholder: msgs.location2Placeholder
        };

        constructor(props: SearchFormHocProps<T>) {
            super(props);

            this.state = {
                fieldsInError: [],
                subscribeUser: false,
                isCompact: !!props.isExpandable
            };
        }

        public render() {
            const { fieldsInError, subscribeUser, isCompact } = this.state,
                { searchData, subscriber } = this.props;

            // Air forms will break if for whatever reason there's no flight search type set, so
            // if it isn't present let's just assume it's roundtrip:
            if (searchData.searchType === SearchType.AIR && !searchData.flightSearchType) {
                searchData.flightSearchType = FlightSearchType.ROUNDTRIP;
            }

            return (
                <SearchFormComponent
                    {...this.props}
                    searchData={searchData}
                    fieldsInError={fieldsInError}
                    subscribeUser={subscribeUser}
                    isCompact={isCompact}
                    isEmailFieldVisible={this.isEmailFieldVisible()}
                    subscriber={subscriber}
                    onDateChange={this.onDateChange}
                    onDatesChange={this.onDatesChange}
                    onClick={this.expand}
                    getOnLocationBlurHandler={this.getOnLocationBlurHandler}
                    onEmailBlur={this.onEmailBlur}
                    onFlightTypeChange={this.onFlightTypeChange}
                    getOnLocationChangeHandler={this.getOnLocationChangeHandler}
                    onSubmit={this.onSubmit}
                    onSubscribeUserChange={this.onSubscribeUserChange}
                    onTravelersChange={this.onTravelersChange}
                />
            );
        }

        private expand = () => {
            this.setState({ isCompact: false });
        };

        private onDateChange = (date: MomentDate) => {
            return this.onDatesChange({ date1: date, date2: null });
        };

        private onDatesChange = (dates: { date1: MomentDate; date2: MomentDate }) => {
            const updatedSearchData = { ...this.props.searchData, ...dates };
            this.props.onChange({ searchData: updatedSearchData });
        };

        private onFlightTypeChange = (flightType: FlightSearchType) => {
            const updatedSearchData = { ...this.props.searchData, flightSearchType: flightType };
            this.props.onChange({ searchData: updatedSearchData });
        };

        private getOnLocationBlurHandler = <S extends Suggestion>(fieldName: SearchFormField) => {
            return (fieldValue: SuggestionDecorator<S>) => this.onLocationBlur<S>(fieldName, fieldValue);
        };

        private onLocationBlur = <S extends Suggestion>(
            fieldName: SearchFormField,
            fieldValue: SuggestionDecorator<S>
        ) => {
            const updatedFieldsInError = without(this.state.fieldsInError, fieldName);

            if (!fieldValue) {
                updatedFieldsInError.push(fieldName);
            }

            this.setState({ fieldsInError: updatedFieldsInError });
        };

        private getOnLocationChangeHandler = <S extends Suggestion>(fieldName: SearchFormField) => {
            return (fieldValue: SuggestionDecorator<S>) => this.onLocationChange<S>(fieldName, fieldValue);
        };

        private onLocationChange = <S extends Suggestion>(
            fieldName: SearchFormField,
            fieldValue: SuggestionDecorator<S>
        ) => {
            // the blur event isn't triggered if the user selects a new location by hitting enter,
            // so we manually validate the field here just in case.
            const updatedFieldsInError = without(this.state.fieldsInError, fieldName);
            this.setState({ fieldsInError: updatedFieldsInError });

            const updatedSearchData = { ...this.props.searchData, ...{ [fieldName]: fieldValue } };
            this.props.onChange({ searchData: updatedSearchData });
        };

        private onSubscribeUserChange = (subscribeUser: boolean) => {
            this.setState({ subscribeUser });
        };

        private onTravelersChange = (travelers: number) => {
            const updatedSearchData = { ...this.props.searchData, travelers };
            this.props.onChange({ searchData: updatedSearchData });
        };

        private onEmailBlur = (email: string) => {
            const { subscriber, onChange } = this.props;

            email = email && email.trim();

            if (isValidEmail({ email }) && (!subscriber || email !== subscriber.emailAddress)) {
                onChange({ subscriber: { emailAddress: email, subscriptions: [] } });
            }
        };

        private isDataValid = (): boolean => {
            const { location1, location2, date1, date2, flightSearchType, searchType } = this.props.searchData,
                updatedFieldsInError = [];

            let result = true;

            if (!location1 && searchType === SearchType.AIR) {
                updatedFieldsInError.push(SearchFormField.LOCATION1);
                result = false;
            }

            if (!location2) {
                updatedFieldsInError.push(SearchFormField.LOCATION2);
                result = false;
            }

            if (!date1 || (flightSearchType === FlightSearchType.ROUNDTRIP && (!date1 || !date2))) {
                updatedFieldsInError.push(SearchFormField.DATES);
                result = false;
            }

            this.setState({ fieldsInError: updatedFieldsInError });

            return result;
        };

        private onSubmit = () => {
            if (this.state.isCompact || !this.isDataValid()) {
                return;
            }

            const { subscribeUser } = this.state,
                { searchData, subscriber, onFormSubmit, redirectOnSubmit } = this.props;

            onFormSubmit && onFormSubmit({ searchData, subscribeUser, subscriber, redirectOnSubmit });
        };

        private isEmailFieldVisible = () => {
            return !this.state.isCompact && (this.props.expandedEmailField || this.state.subscribeUser);
        };
    };
}
