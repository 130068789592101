export interface Suggestion {
    id: number;
}

/**
 * Airport suggestion entity, as returned by our backend
 */
export interface AirportSuggestion extends Suggestion {
    airportCode: string;
    displayName: string;
    parentDisplayName: string;
    parentId: number;
    departureFareEligible?: boolean;
}

/**
 * City suggestion entity, as returned by our backend
 */
export interface CitySuggestion extends Suggestion {
    cityName: string;
    cityFullLocation: string;
}

/**
 * Decorator around a specific suggestion returned from the backend.
 * Used so that we can abstract specific implementations of auto-completes
 * from specific types of suggestions
 */
export interface SuggestionDecorator<T extends Suggestion> {
    text: string;
    suggestion: T;
}

/**
 * Checks whether a specific Suggestion is an AirportSuggestion
 * @param suggestion
 */
export function isAirportSuggestion(suggestion: Suggestion): suggestion is AirportSuggestion {
    return (suggestion as AirportSuggestion).airportCode !== undefined;
}

export function isCitySuggestion(suggestion: Suggestion): suggestion is CitySuggestion {
    return (suggestion as CitySuggestion).cityName !== undefined;
}
