import React from 'react';

import './styles.scss';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';

interface FareAttributeTagProps {
    theme?: FareAttributeTagTheme;
    iconName?: AwdIconName;
    iconColor?: FareAttributeTagIconColor;
    text?: string;
    tooltipText?: string;
    renderWithToolTipExpanded?: boolean;
}

export enum FareAttributeTagTheme {
    LightBlue = 'light-blue',
    LightBlueWithBorder = 'light-blue-with-border',
    Navy = 'navy',
    White = 'white',
    Transparent = 'transparent'
}

export enum FareAttributeTagIconColor {
    Inherit = 'inherit',
    Blue = 'blue',
    Razzmatazz = 'razzmatazz'
}

/**
 * A component to display a fare attribute tag and its description
 */
export default function FareAttributeTag({
    iconName,
    iconColor,
    text,
    theme = FareAttributeTagTheme.LightBlue,
    tooltipText,
    renderWithToolTipExpanded = false
}: FareAttributeTagProps) {
    return (
        <div className={`fare-attribute-tag ${'fare-attribute-tag--' + theme}`}>
            {iconName && (
                <span className={`fare-attribute-tag__icon ${'fare-attribute-tag__icon--' + iconColor}`}>
                    <AwdIcon name={iconName} />
                </span>
            )}
            <span className='fare-attribute-tag__desc'>{text}</span>
            {tooltipText && (
                <>
                    <span className='fare-attribute-tag__info'>
                        <AwdIcon name={AwdIconName.Info} />
                    </span>
                    <div
                        className={`fare-attribute-tag__tooltip ${
                            renderWithToolTipExpanded ? 'fare-attribute-tag__tooltip-right-expanded' : ''
                        }`}
                    >
                        <span className='fare-attribute-tag__tooltip-msg'>{tooltipText}</span>
                        <span
                            className={`fare-attribute-tag__tooltip-arrow ${
                                renderWithToolTipExpanded ? 'fare-attribute-tag__tooltip-arrow-right-expanded' : ''
                            }`}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
