import React from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';
import msgs from './messages';
import Checkbox, { CheckboxTheme } from 'components/Checkbox';
import RadioInput, { RadioInputTheme } from 'components/RadioInput';

export type FilterOptionId = string | number;

export interface FilterOption<T extends FilterOptionId> {
    description: string;
    id: T;
}

export interface FilterOptionsListProps<T extends FilterOptionId> {
    isMultiSelect: boolean; // If true, show checkboxes instead of radio buttons
    options: Array<FilterOption<T>>;
    otherOptions?: Array<FilterOption<T>>; // Options to be hidden initially
    selectedOptions: Array<FilterOption<T>>;
    showSelectAll?: boolean; // If true, show a select all checkbox. Defaults to true.
    closeModal?(): void; // A callback to close a related modal when an option is selected.
    onClearFilter?(): void;
    onOptionChanged(option: FilterOption<T>): void;
    onSelectAll?(): void;
}

interface FilterOptionsListState {
    showAllOptions: boolean;
}

/**
 * A list of options that can be filtered on.
 */
export default class FilterOptionsList<T extends FilterOptionId> extends React.Component<
    FilterOptionsListProps<T>,
    FilterOptionsListState
> {
    public static defaultProps = { showSelectAll: true };
    public state: FilterOptionsListState = { showAllOptions: false };

    public render = () => {
        const { options } = this.props;

        return (
            <div className='filter-options-list'>
                {this.renderControls()}
                <div className='filter-options'>
                    {options.map(option => this.renderFilterOption(option))}
                    {this.renderOtherOptions()}
                </div>
                {this.renderShowAll()}
            </div>
        );
    };

    private renderControls = () => {
        const { isMultiSelect, onClearFilter, showSelectAll } = this.props;

        return (
            <div className='filter-options-list__controls'>
                {isMultiSelect && (
                    <div className='filter-option filter-option__clear' onClick={onClearFilter}>
                        <FormattedMessage {...msgs.clearAll} />
                    </div>
                )}
                {showSelectAll && (
                    <div className='filter-option filter-option__select-all'>
                        <Checkbox
                            checked={this.shouldShowAllSelected()}
                            onChange={() => undefined} // using onClick here, so do nothing onChange
                            onClick={this.onSelectAll}
                            theme={CheckboxTheme.Blue}
                        >
                            <FormattedMessage {...msgs.selectAll} />
                        </Checkbox>
                    </div>
                )}
            </div>
        );
    };

    private renderFilterOption = (option: FilterOption<T>) => {
        const isSelected = this.isOptionSelected(option);

        return (
            <div className='filter-option' key={option.id}>
                {this.props.isMultiSelect ? (
                    <Checkbox
                        checked={isSelected}
                        onChange={() => this.onOptionChanged(option)}
                        theme={CheckboxTheme.Blue}
                    >
                        {option.description}
                    </Checkbox>
                ) : (
                    <RadioInput
                        value={option.id}
                        selectedValue={this.props.selectedOptions[0] && this.props.selectedOptions[0].id}
                        theme={RadioInputTheme.Blue}
                        onChange={() => this.onOptionChanged(option)}
                    >
                        {option.description}
                    </RadioInput>
                )}
            </div>
        );
    };

    private renderOtherOptions = () => {
        const { otherOptions } = this.props,
            { showAllOptions } = this.state;

        // If we're showing all options, render other options if we have them
        if (otherOptions && otherOptions.length && showAllOptions) {
            return otherOptions.map(option => this.renderFilterOption(option));
        }
    };

    private renderShowAll = () => {
        const { otherOptions } = this.props,
            { showAllOptions } = this.state;

        // If we're not showing all options but have other options to show, render a show all button
        if (otherOptions && otherOptions.length && !showAllOptions) {
            return (
                <div className='filter-options-list__show-all' onClick={this.showAllOptions}>
                    <FormattedMessage {...msgs.showAll} />
                </div>
            );
        }
    };

    private onOptionChanged = (option: FilterOption<T>) => {
        this.props.onOptionChanged(option);
        this.closeModal();
    };

    private onSelectAll = () => {
        this.props.onSelectAll && this.props.onSelectAll();
        this.closeModal();
    };

    // For single-select option lists, call the closeModal callback if we have one
    private closeModal = () => {
        const { closeModal, isMultiSelect } = this.props;
        !isMultiSelect && closeModal && closeModal();
    };

    private getAllOptions = () => {
        return this.props.options.concat(this.props.otherOptions || []);
    };

    private showAllOptions = () => {
        this.setState({ showAllOptions: true });
    };

    private isOptionSelected = (option: FilterOption<T>) => {
        return this.props.selectedOptions.map(o => o.id).includes(option.id);
    };

    private shouldShowAllSelected = () => {
        if (this.props.isMultiSelect) {
            return this.getAllOptions().every(option => this.isOptionSelected(option));
        }

        return this.props.selectedOptions.length < 1;
    };
}
