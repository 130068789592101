import React, { ReactNode } from 'react';

import './styles.scss';

type RadioInputValue = string | number;

export enum RadioInputTheme {
    Default = 'default',
    Blue = 'blue'
}

interface RadioInputProps<T extends RadioInputValue> {
    children: ReactNode;
    value: T;
    selectedValue?: T;
    theme?: RadioInputTheme;
    onChange(value: T): void;
}

export default class RadioInput<T extends RadioInputValue> extends React.Component<RadioInputProps<T>> {
    public static defaultProps = { theme: RadioInputTheme.Default };

    public render = () => {
        const { value, selectedValue, theme } = this.props;
        return (
            <label className={`radio-input radio-input--${theme} do_no_popunder`}>
                <span className={'radio-input__option'}>{this.props.children}</span>
                <input
                    className='radio-input__radio'
                    type='radio'
                    checked={value === selectedValue}
                    onChange={this.onChange}
                    value={value}
                />
                <span className='radio-input__dot' />
            </label>
        );
    };

    private onChange = () => {
        this.props.onChange(this.props.value);
    };
}
