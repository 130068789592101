import React from 'react';

import searchFormHoc from './container';
import VerticalForm from './Vertical';
import WideForm from './Wide';
import StickyForm from './Sticky';
import { AirportSuggestion, CitySuggestion, Suggestion } from 'models/Suggestion';
import { SearchFormProps } from 'components/SearchForm/common/props';
import { mediaLessThanMd } from 'constants/mediaQueries';
import useMedia from 'hooks/useMedia';

function ResponsiveForm<T extends Suggestion>(props: SearchFormProps<T>) {
    const isLessThanMd = useMedia(mediaLessThanMd),
        Form = isLessThanMd ? VerticalForm : WideForm;
    return <Form subscriptionFieldsAfterButton={isLessThanMd} {...props} />;
}

export const AirSearchFormVertical = searchFormHoc<AirportSuggestion>(VerticalForm);
export const AirSearchFormWide = searchFormHoc<AirportSuggestion>(WideForm);
export const AirSearchFormSticky = searchFormHoc<AirportSuggestion>(StickyForm);
export const AirSearchFormResponsive = searchFormHoc<AirportSuggestion>(ResponsiveForm);

export const HotelSearchFormVertical = searchFormHoc<CitySuggestion>(VerticalForm);
export const HotelSearchFormWide = searchFormHoc<CitySuggestion>(WideForm);
export const HotelSearchFormResponsive = searchFormHoc<CitySuggestion>(ResponsiveForm);
