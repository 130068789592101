// Entities associated with Google Publisher Tags/Dart ads

/**
 * Frontend representation of DartAdSize.java
 */
export enum GptAdSize {
    SKINNY_RAIL = '120x600',
    RAIL = '160x600',
    FILM_STRIP = '300x600',
    MPU = '300x250',
    RISING_STAR = '970x250',
    HWD_HOTWIRE = '970x225',
    LEADERBOARD = '728x90',
    MOBILE = '320x50'
}

export enum GptAdPosition {
    ABOVE_FOLD = 'abovefold',
    BELOW_FOLD = 'belowfold'
}
