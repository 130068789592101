import { defineMessages } from 'react-intl';

export default defineMessages({
    roundtripLabel: {
        id: 'FlightTypeInput_roundtripLabel',
        defaultMessage: 'Roundtrip'
    },
    roundtripShortLabel: {
        id: 'FlightTypeInput_roundtripShortLabel',
        defaultMessage: 'RT'
    },
    onewayLabel: {
        id: 'FlightTypeInput_onewayLabel',
        defaultMessage: 'One Way'
    },
    onewayShortLabel: {
        id: 'FlightTypeInput_onewayShortLabel',
        defaultMessage: 'OW'
    }
});
