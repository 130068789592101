export interface RouteStaticData {
    title: string;
    url: string;
}

export interface RoutesStaticData {
    title: string;
    url: string;
    routes: RouteStaticData[];
}

export interface CityStaticData {
    title: string;
    from: RoutesStaticData;
    to: RoutesStaticData;
}

export const linksListData = [
    {
        title: 'Albany (ALB)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Albany-New-York/ALB/',
            title: 'Flights From Albany (ALB)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/albany-new-york-alb-to-atlanta-georgia-atl/',
                    title: 'Albany, NY (ALB) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/albany-new-york-alb-to-fort-lauderdale-florida-fll/',
                    title: 'Albany, NY (ALB) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/albany-new-york-alb-to-los-angeles-california-lax/',
                    title: 'Albany, NY (ALB) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/albany-new-york-alb-to-miami-florida-mia/',
                    title: 'Albany, NY (ALB) to Miami, FL (MIA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/albany-new-york-alb-to-orlando-florida-mco/',
                    title: 'Albany, NY (ALB) to Orlando, FL (MCO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Albany-New-York/ALB/',
            title: 'Flights To Albany (ALB)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-albany-new-york-alb/',
                    title: 'Atlanta, GA (ATL) to Albany, NY (ALB)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/chicago-illinois-ord-to-albany-new-york-alb/',
                    title: 'Chicago, IL (ORD) to Albany, NY (ALB)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-albany-new-york-alb/',
                    title: 'Fort Lauderdale, FL (FLL) to Albany, NY (ALB)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-albany-new-york-alb/',
                    title: 'Los Angeles, CA (LAX) to Albany, NY (ALB)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-albany-new-york-alb/',
                    title: 'Orlando, FL (MCO) to Albany, NY (ALB)'
                }
            ]
        }
    },
    {
        title: 'Albuquerque (ABQ)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Albuquerque-New-Mexico/ABQ/',
            title: 'Flights From Albuquerque (ABQ)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/albuquerque-new-mexico-abq-to-austin-texas-aus/',
                    title: 'Albuquerque, NM (ABQ) to Austin, TX (AUS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/albuquerque-new-mexico-abq-to-denver-colorado-den/',
                    title: 'Albuquerque, NM (ABQ) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/albuquerque-new-mexico-abq-to-houston-texas-iah/',
                    title: 'Albuquerque, NM (ABQ) to Houston, TX (IAH)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/albuquerque-new-mexico-abq-to-las-vegas-nevada-las/',
                    title: 'Albuquerque, NM (ABQ) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/albuquerque-new-mexico-abq-to-phoenix-arizona-phx/',
                    title: 'Albuquerque, NM (ABQ) to Phoenix, AZ (PHX)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Albuquerque-New-Mexico/ABQ/',
            title: 'Flights To Albuquerque (ABQ)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-albuquerque-new-mexico-abq/',
                    title: 'Austin, TX (AUS) to Albuquerque, NM (ABQ)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-albuquerque-new-mexico-abq/',
                    title: 'Denver, CO (DEN) to Albuquerque, NM (ABQ)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-albuquerque-new-mexico-abq/',
                    title: 'Las Vegas, NV (LAS) to Albuquerque, NM (ABQ)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-albuquerque-new-mexico-abq/',
                    title: 'Los Angeles, CA (LAX) to Albuquerque, NM (ABQ)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/phoenix-arizona-phx-to-albuquerque-new-mexico-abq/',
                    title: 'Phoenix, AZ (PHX) to Albuquerque, NM (ABQ)'
                }
            ]
        }
    },
    {
        title: 'Anchorage (ANC)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Anchorage-Alaska/ANC/',
            title: 'Flights From Anchorage (ANC)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/anchorage-alaska-anc-to-fairbanks-alaska-fai/',
                    title: 'Anchorage, AK (ANC) to Fairbanks, AK (FAI)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/anchorage-alaska-anc-to-homer-alaska-hom/',
                    title: 'Anchorage, AK (ANC) to Homer, AK (HOM)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/anchorage-alaska-anc-to-las-vegas-nevada-las/',
                    title: 'Anchorage, AK (ANC) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/anchorage-alaska-anc-to-portland-oregon-pdx/',
                    title: 'Anchorage, AK (ANC) to Portland, OR (PDX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/anchorage-alaska-anc-to-seattle-washington-sea/',
                    title: 'Anchorage, AK (ANC) to Seattle, WA (SEA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Anchorage-Alaska/ANC/',
            title: 'Flights To Anchorage (ANC)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-anchorage-alaska-anc/',
                    title: 'Atlanta, GA (ATL) to Anchorage, AK (ANC)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-anchorage-alaska-anc/',
                    title: 'Austin, TX (AUS) to Anchorage, AK (ANC)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-anchorage-alaska-anc/',
                    title: 'Boston, MA (BOS) to Anchorage, AK (ANC)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-anchorage-alaska-anc/',
                    title: 'Los Angeles, CA (LAX) to Anchorage, AK (ANC)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/seattle-washington-sea-to-anchorage-alaska-anc/',
                    title: 'Seattle, WA (SEA) to Anchorage, AK (ANC)'
                }
            ]
        }
    },
    {
        title: 'Atlanta (ATL)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Atlanta-Georgia/ATL/',
            title: 'Flights From Atlanta (ATL)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-denver-colorado-den/',
                    title: 'Atlanta, GA (ATL) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-houston-texas-hou/',
                    title: 'Atlanta, GA (ATL) to Houston, TX (HOU)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-las-vegas-nevada-las/',
                    title: 'Atlanta, GA (ATL) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-miami-florida-mia/',
                    title: 'Atlanta, GA (ATL) to Miami, FL (MIA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-tampa-florida-tpa/',
                    title: 'Atlanta, GA (ATL) to Tampa, FL (TPA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Atlanta-Georgia/ATL/',
            title: 'Flights To Atlanta (ATL)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-atlanta-georgia-atl/',
                    title: 'Boston, MA (BOS) to Atlanta, GA (ATL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-atlanta-georgia-atl/',
                    title: 'Denver, CO (DEN) to Atlanta, GA (ATL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/houston-texas-hou-to-atlanta-georgia-atl/',
                    title: 'Houston, TX (HOU) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-atlanta-georgia-atl/',
                    title: 'Los Angeles, CA (LAX) to Atlanta, GA (ATL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-atlanta-georgia-atl/',
                    title: 'Miami, FL (MIA) to Atlanta, GA (ATL)'
                }
            ]
        }
    },
    {
        title: 'Austin (AUS)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Austin-Texas/AUS/',
            title: 'Flights From Austin (AUS)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-dallas-fort-worth-texas-dfw/',
                    title: 'Austin, TX (AUS) to Dallas Fort Worth, TX (DFW)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-denver-colorado-den/',
                    title: 'Austin, TX (AUS) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-las-vegas-nevada-las/',
                    title: 'Austin, TX (AUS) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-los-angeles-california-lax/',
                    title: 'Austin, TX (AUS) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-miami-florida-mia/',
                    title: 'Austin, TX (AUS) to Miami, FL (MIA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Austin-Texas/AUS/',
            title: 'Flights To Austin (AUS)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-austin-texas-aus/',
                    title: 'Atlanta, GA (ATL) to Austin, TX (AUS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-austin-texas-aus/',
                    title: 'Boston, MA (BOS) to Austin, TX (AUS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-austin-texas-aus/',
                    title: 'Denver, CO (DEN) to Austin, TX (AUS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-austin-texas-aus/',
                    title: 'Los Angeles, CA (LAX) to Austin, TX (AUS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-austin-texas-aus/',
                    title: 'San Francisco, CA (SFO) to Austin, TX (AUS)'
                }
            ]
        }
    },
    {
        title: 'Baltimore (BWI)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Baltimore-Maryland/BWI/',
            title: 'Flights From Baltimore (BWI)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/baltimore-maryland-bwi-to-atlanta-georgia-atl/',
                    title: 'Baltimore, MD (BWI) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/baltimore-maryland-bwi-to-boston-massachusetts-bos/',
                    title: 'Baltimore, MD (BWI) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/baltimore-maryland-bwi-to-las-vegas-nevada-las/',
                    title: 'Baltimore, MD (BWI) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/baltimore-maryland-bwi-to-miami-florida-mia/',
                    title: 'Baltimore, MD (BWI) to Miami, FL (MIA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/baltimore-maryland-bwi-to-orlando-florida-mco/',
                    title: 'Baltimore, MD (BWI) to Orlando, FL (MCO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Baltimore-Maryland/BWI/',
            title: 'Flights To Baltimore (BWI)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-baltimore-maryland-bwi/',
                    title: 'Atlanta, GA (ATL) to Baltimore, MD (BWI)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-baltimore-maryland-bwi/',
                    title: 'Boston, MA (BOS) to Baltimore, MD (BWI)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-baltimore-maryland-bwi/',
                    title: 'Fort Lauderdale, FL (FLL) to Baltimore, MD (BWI)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-baltimore-maryland-bwi/',
                    title: 'Los Angeles, CA (LAX) to Baltimore, MD (BWI)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-baltimore-maryland-bwi/',
                    title: 'Orlando, FL (MCO) to Baltimore, MD (BWI)'
                }
            ]
        }
    },
    {
        title: 'Boston (BOS)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Boston-Massachusetts/BOS/',
            title: 'Flights From Boston (BOS)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-atlanta-georgia-atl/',
                    title: 'Boston, MA (BOS) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-fort-lauderdale-florida-fll/',
                    title: 'Boston, MA (BOS) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-los-angeles-california-lax/',
                    title: 'Boston, MA (BOS) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-miami-florida-mia/',
                    title: 'Boston, MA (BOS) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-orlando-florida-orl/',
                    title: 'Boston, MA (BOS) to Orlando, FL (ORL)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Boston-Massachusetts/BOS/',
            title: 'Flights To Boston (BOS)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-boston-massachusetts-bos/',
                    title: 'Atlanta, GA (ATL) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-boston-massachusetts-bos/',
                    title: 'Denver, CO (DEN) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-boston-massachusetts-bos/',
                    title: 'Los Angeles, CA (LAX) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-boston-massachusetts-bos/',
                    title: 'Philadelphia, PA (PHL) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-boston-massachusetts-bos/',
                    title: 'San Francisco, CA (SFO) to Boston, MA (BOS)'
                }
            ]
        }
    },
    {
        title: 'Buffalo (BUF)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Buffalo-New-York/BUF/',
            title: 'Flights From Buffalo (BUF)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/buffalo-new-york-buf-to-boston-massachusetts-bos/',
                    title: 'Buffalo, NY (BUF) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/buffalo-new-york-buf-to-los-angeles-california-lax/',
                    title: 'Buffalo, NY (BUF) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/buffalo-new-york-buf-to-miami-florida-mia/',
                    title: 'Buffalo, NY (BUF) to Miami, FL (MIA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/buffalo-new-york-buf-to-orlando-florida-mco/',
                    title: 'Buffalo, NY (BUF) to Orlando, FL (MCO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/buffalo-new-york-buf-to-tampa-florida-tpa/',
                    title: 'Buffalo, NY (BUF) to Tampa, FL (TPA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Buffalo-New-York/BUF/',
            title: 'Flights To Buffalo (BUF)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-buffalo-new-york-buf/',
                    title: 'Atlanta, GA (ATL) to Buffalo, NY (BUF)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-buffalo-new-york-buf/',
                    title: 'Boston, MA (BOS) to Buffalo, NY (BUF)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/houston-texas-iah-to-buffalo-new-york-buf/',
                    title: 'Houston, TX (IAH) to Buffalo, NY (BUF)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-buffalo-new-york-buf/',
                    title: 'Miami, FL (MIA) to Buffalo, NY (BUF)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/new-york-city-new-york-jfk-to-buffalo-new-york-buf/',
                    title: 'New York City, NY (JFK) to Buffalo, NY (BUF)'
                }
            ]
        }
    },
    {
        title: 'Burbank (BUR)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Burbank-California/BUR/',
            title: 'Flights From Burbank (BUR)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/burbank-california-bur-to-boston-massachusetts-bos/',
                    title: 'Burbank, CA (BUR) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/burbank-california-bur-to-las-vegas-nevada-las/',
                    title: 'Burbank, CA (BUR) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/burbank-california-bur-to-los-angeles-california-lax/',
                    title: 'Burbank, CA (BUR) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/burbank-california-bur-to-portland-oregon-pdx/',
                    title: 'Burbank, CA (BUR) to Portland, OR (PDX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/burbank-california-bur-to-seattle-washington-sea/',
                    title: 'Burbank, CA (BUR) to Seattle, WA (SEA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Burbank-California/BUR/',
            title: 'Flights To Burbank (BUR)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-burbank-california-bur/',
                    title: 'Boston, MA (BOS) to Burbank, CA (BUR)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-burbank-california-bur/',
                    title: 'Denver, CO (DEN) to Burbank, CA (BUR)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-burbank-california-bur/',
                    title: 'Las Vegas, NV (LAS) to Burbank, CA (BUR)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-burbank-california-bur/',
                    title: 'Los Angeles, CA (LAX) to Burbank, CA (BUR)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-burbank-california-bur/',
                    title: 'San Francisco, CA (SFO) to Burbank, CA (BUR)'
                }
            ]
        }
    },
    {
        title: 'Charlotte (CLT)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Charlotte-North-Carolina/CLT/',
            title: 'Flights From Charlotte (CLT)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/charlotte-north-carolina-clt-to-boston-massachusetts-bos/',
                    title: 'Charlotte, NC (CLT) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/charlotte-north-carolina-clt-to-fort-lauderdale-florida-fll/',
                    title: 'Charlotte, NC (CLT) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/charlotte-north-carolina-clt-to-las-vegas-nevada-las/',
                    title: 'Charlotte, NC (CLT) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/charlotte-north-carolina-clt-to-miami-florida-mia/',
                    title: 'Charlotte, NC (CLT) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/charlotte-north-carolina-clt-to-orlando-florida-mco/',
                    title: 'Charlotte, NC (CLT) to Orlando, FL (MCO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Charlotte-North-Carolina/CLT/',
            title: 'Flights To Charlotte (CLT)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-charlotte-north-carolina-clt/',
                    title: 'Atlanta, GA (ATL) to Charlotte, NC (CLT)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-charlotte-north-carolina-clt/',
                    title: 'Boston, MA (BOS) to Charlotte, NC (CLT)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-charlotte-north-carolina-clt/',
                    title: 'Fort Lauderdale, FL (FLL) to Charlotte, NC (CLT)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-charlotte-north-carolina-clt/',
                    title: 'Orlando, FL (MCO) to Charlotte, NC (CLT)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-charlotte-north-carolina-clt/',
                    title: 'San Francisco, CA (SFO) to Charlotte, NC (CLT)'
                }
            ]
        }
    },
    {
        title: 'Chicago (MDW)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Chicago-Illinois/MDW/',
            title: 'Flights From Chicago (MDW)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/chicago-illinois-mdw-to-las-vegas-nevada-las/',
                    title: 'Chicago, IL (MDW) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/chicago-illinois-mdw-to-los-angeles-california-lax/',
                    title: 'Chicago, IL (MDW) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/chicago-illinois-mdw-to-new-york-new-york-lga/',
                    title: 'Chicago, IL (MDW) to New York, NY (LGA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/chicago-illinois-mdw-to-orlando-florida-mco/',
                    title: 'Chicago, IL (MDW) to Orlando, FL (MCO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/chicago-illinois-mdw-to-phoenix-arizona-phx/',
                    title: 'Chicago, IL (MDW) to Phoenix, AZ (PHX)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Chicago-Illinois/MDW/',
            title: 'Flights To Chicago (MDW)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-chicago-illinois-mdw/',
                    title: 'Denver, CO (DEN) to Chicago, IL (MDW)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-chicago-illinois-mdw/',
                    title: 'Los Angeles, CA (LAX) to Chicago, IL (MDW)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/minneapolis-minnesota-msp-to-chicago-illinois-mdw/',
                    title: 'Minneapolis, MN (MSP) to Chicago, IL (MDW)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/newark-new-jersey-ewr-to-chicago-illinois-mdw/',
                    title: 'Newark, NJ (EWR) to Chicago, IL (MDW)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-chicago-illinois-mdw/',
                    title: 'Orlando, FL (MCO) to Chicago, IL (MDW)'
                }
            ]
        }
    },
    {
        title: 'Chicago (ORD)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Chicago-Illinois/ORD/',
            title: 'Flights From Chicago (ORD)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/chicago-illinois-ord-to-denver-colorado-den/',
                    title: 'Chicago, IL (ORD) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/chicago-illinois-ord-to-las-vegas-nevada-las/',
                    title: 'Chicago, IL (ORD) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/chicago-illinois-ord-to-los-angeles-california-lax/',
                    title: 'Chicago, IL (ORD) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/chicago-illinois-ord-to-miami-florida-mia/',
                    title: 'Chicago, IL (ORD) to Miami, FL (MIA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/chicago-illinois-ord-to-tampa-florida-tpa/',
                    title: 'Chicago, IL (ORD) to Tampa, FL (TPA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Chicago-Illinois/ORD/',
            title: 'Flights To Chicago (ORD)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-chicago-illinois-ord/',
                    title: 'Atlanta, GA (ATL) to Chicago, IL (ORD)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-chicago-illinois-ord/',
                    title: 'Fort Lauderdale, FL (FLL) to Chicago, IL (ORD)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-chicago-illinois-ord/',
                    title: 'Los Angeles, CA (LAX) to Chicago, IL (ORD)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/new-york-new-york-lga-to-chicago-illinois-ord/',
                    title: 'New York, NY (LGA) to Chicago, IL (ORD)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/washington-dc-district-of-columbia-dca-to-chicago-illinois-ord/',
                    title: 'Washington, DC (DCA) to Chicago, IL (ORD)'
                }
            ]
        }
    },
    {
        title: 'Cleveland (CLE)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Cleveland-Ohio/CLE/',
            title: 'Flights From Cleveland (CLE)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/cleveland-ohio-cle-to-fort-lauderdale-florida-fll/',
                    title: 'Cleveland, OH (CLE) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/cleveland-ohio-cle-to-las-vegas-nevada-las/',
                    title: 'Cleveland, OH (CLE) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/cleveland-ohio-cle-to-miami-florida-mia/',
                    title: 'Cleveland, OH (CLE) to Miami, FL (MIA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/cleveland-ohio-cle-to-orlando-florida-orl/',
                    title: 'Cleveland, OH (CLE) to Orlando, FL (ORL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/cleveland-ohio-cle-to-tampa-florida-tpa/',
                    title: 'Cleveland, OH (CLE) to Tampa, FL (TPA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Cleveland-Ohio/CLE/',
            title: 'Flights To Cleveland (CLE)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-cleveland-ohio-cle/',
                    title: 'Atlanta, GA (ATL) to Cleveland, OH (CLE)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-cleveland-ohio-cle/',
                    title: 'Boston, MA (BOS) to Cleveland, OH (CLE)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-cleveland-ohio-cle/',
                    title: 'Denver, CO (DEN) to Cleveland, OH (CLE)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-cleveland-ohio-cle/',
                    title: 'Los Angeles, CA (LAX) to Cleveland, OH (CLE)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/tampa-florida-tpa-to-cleveland-ohio-cle/',
                    title: 'Tampa, FL (TPA) to Cleveland, OH (CLE)'
                }
            ]
        }
    },
    {
        title: 'Columbus (CMH)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Columbus-Ohio/CMH/',
            title: 'Flights From Columbus (CMH)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/columbus-ohio-cmh-to-las-vegas-nevada-las/',
                    title: 'Columbus, OH (CMH) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/columbus-ohio-cmh-to-los-angeles-california-lax/',
                    title: 'Columbus, OH (CMH) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/columbus-ohio-cmh-to-miami-florida-mia/',
                    title: 'Columbus, OH (CMH) to Miami, FL (MIA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/columbus-ohio-cmh-to-orlando-florida-mco/',
                    title: 'Columbus, OH (CMH) to Orlando, FL (MCO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/columbus-ohio-cmh-to-tampa-florida-tpa/',
                    title: 'Columbus, OH (CMH) to Tampa, FL (TPA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Columbus-Ohio/CMH/',
            title: 'Flights To Columbus (CMH)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-columbus-ohio-cmh/',
                    title: 'Boston, MA (BOS) to Columbus, OH (CMH)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-columbus-ohio-cmh/',
                    title: 'Denver, CO (DEN) to Columbus, OH (CMH)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-columbus-ohio-cmh/',
                    title: 'Los Angeles, CA (LAX) to Columbus, OH (CMH)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-columbus-ohio-cmh/',
                    title: 'Orlando, FL (MCO) to Columbus, OH (CMH)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-columbus-ohio-cmh/',
                    title: 'Philadelphia, PA (PHL) to Columbus, OH (CMH)'
                }
            ]
        }
    },
    {
        title: 'Dallas (DAL)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Dallas-Texas/DAL/',
            title: 'Flights From Dallas (DAL)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/dallas-texas-dal-to-atlanta-georgia-atl/',
                    title: 'Dallas, TX (DAL) to Atlanta, GA (ATL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/dallas-texas-dal-to-denver-colorado-den/',
                    title: 'Dallas, TX (DAL) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/dallas-texas-dal-to-las-vegas-nevada-las/',
                    title: 'Dallas, TX (DAL) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/dallas-texas-dal-to-new-york-city-new-york-jfk/',
                    title: 'Dallas, TX (DAL) to New York City, NY (JFK)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/dallas-texas-dal-to-seattle-washington-sea/',
                    title: 'Dallas, TX (DAL) to Seattle, WA (SEA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Dallas-Texas/DAL/',
            title: 'Flights To Dallas (DAL)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-dallas-texas-dal/',
                    title: 'Atlanta, GA (ATL) to Dallas, TX (DAL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-dallas-texas-dal/',
                    title: 'Los Angeles, CA (LAX) to Dallas, TX (DAL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/portland-oregon-pdx-to-dallas-texas-dal/',
                    title: 'Portland, OR (PDX) to Dallas, TX (DAL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/washington-dc-district-of-columbia-dca-to-dallas-texas-dal/',
                    title: 'Washington, DC (DCA) to Dallas, TX (DAL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/west-palm-beach-florida-pbi-to-dallas-texas-dal/',
                    title: 'West Palm Beach, FL (PBI) to Dallas, TX (DAL)'
                }
            ]
        }
    },
    {
        title: 'Denver (DEN)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Denver-Colorado/DEN/',
            title: 'Flights From Denver (DEN)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-las-vegas-nevada-las/',
                    title: 'Denver, CO (DEN) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-los-angeles-california-lax/',
                    title: 'Denver, CO (DEN) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-miami-florida-mia/',
                    title: 'Denver, CO (DEN) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-salt-lake-city-utah-slc/',
                    title: 'Denver, CO (DEN) to Salt Lake City, UT (SLC)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-seattle-washington-sea/',
                    title: 'Denver, CO (DEN) to Seattle, WA (SEA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Denver-Colorado/DEN/',
            title: 'Flights To Denver (DEN)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-denver-colorado-den/',
                    title: 'Atlanta, GA (ATL) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-denver-colorado-den/',
                    title: 'Austin, TX (AUS) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-denver-colorado-den/',
                    title: 'Boston, MA (BOS) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/dallas-fort-worth-texas-dfw-to-denver-colorado-den/',
                    title: 'Dallas Fort Worth, TX (DFW) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/minneapolis-minnesota-msp-to-denver-colorado-den/',
                    title: 'Minneapolis, MN (MSP) to Denver, CO (DEN)'
                }
            ]
        }
    },
    {
        title: 'Detroit (DTW)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Detroit-Michigan/DTW/',
            title: 'Flights From Detroit (DTW)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/detroit-michigan-dtw-to-atlanta-georgia-atl/',
                    title: 'Detroit, MI (DTW) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/detroit-michigan-dtw-to-boston-massachusetts-bos/',
                    title: 'Detroit, MI (DTW) to Boston, MA (BOS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/detroit-michigan-dtw-to-denver-colorado-den/',
                    title: 'Detroit, MI (DTW) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/detroit-michigan-dtw-to-los-angeles-california-lax/',
                    title: 'Detroit, MI (DTW) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/detroit-michigan-dtw-to-miami-florida-mia/',
                    title: 'Detroit, MI (DTW) to Miami, FL (MIA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Detroit-Michigan/DTW/',
            title: 'Flights To Detroit (DTW)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-detroit-michigan-dtw/',
                    title: 'Atlanta, GA (ATL) to Detroit, MI (DTW)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-detroit-michigan-dtw/',
                    title: 'Boston, MA (BOS) to Detroit, MI (DTW)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-detroit-michigan-dtw/',
                    title: 'Denver, CO (DEN) to Detroit, MI (DTW)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-detroit-michigan-dtw/',
                    title: 'Los Angeles, CA (LAX) to Detroit, MI (DTW)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-detroit-michigan-dtw/',
                    title: 'Orlando, FL (MCO) to Detroit, MI (DTW)'
                }
            ]
        }
    },
    {
        title: 'El Paso (ELP)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-El-Paso-Texas/ELP/',
            title: 'Flights From El Paso (ELP)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/el-paso-texas-elp-to-dallas-fort-worth-texas-dfw/',
                    title: 'El Paso, TX (ELP) to Dallas Fort Worth, TX (DFW)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/el-paso-texas-elp-to-houston-texas-hou/',
                    title: 'El Paso, TX (ELP) to Houston, TX (HOU)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/el-paso-texas-elp-to-las-vegas-nevada-las/',
                    title: 'El Paso, TX (ELP) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/el-paso-texas-elp-to-los-angeles-california-lax/',
                    title: 'El Paso, TX (ELP) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/el-paso-texas-elp-to-san-antonio-texas-sat/',
                    title: 'El Paso, TX (ELP) to San Antonio, TX (SAT)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-El-Paso-Texas/ELP/',
            title: 'Flights To El Paso (ELP)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-el-paso-texas-elp/',
                    title: 'Atlanta, GA (ATL) to El Paso, TX (ELP)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-el-paso-texas-elp/',
                    title: 'Austin, TX (AUS) to El Paso, TX (ELP)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-el-paso-texas-elp/',
                    title: 'Denver, CO (DEN) to El Paso, TX (ELP)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-el-paso-texas-elp/',
                    title: 'Las Vegas, NV (LAS) to El Paso, TX (ELP)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-el-paso-texas-elp/',
                    title: 'Los Angeles, CA (LAX) to El Paso, TX (ELP)'
                }
            ]
        }
    },
    {
        title: 'Fort Lauderdale (FLL)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Fort-Lauderdale-Florida/FLL/',
            title: 'Flights From Fort Lauderdale (FLL)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-atlanta-georgia-atl/',
                    title: 'Fort Lauderdale, FL (FLL) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-boston-massachusetts-bos/',
                    title: 'Fort Lauderdale, FL (FLL) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-denver-colorado-den/',
                    title: 'Fort Lauderdale, FL (FLL) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-key-west-florida-eyw/',
                    title: 'Fort Lauderdale, FL (FLL) to Key West, FL (EYW)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-las-vegas-nevada-las/',
                    title: 'Fort Lauderdale, FL (FLL) to Las Vegas, NV (LAS)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Fort-Lauderdale-Florida/FLL/',
            title: 'Flights To Fort Lauderdale (FLL)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-fort-lauderdale-florida-fll/',
                    title: 'Atlanta, GA (ATL) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-fort-lauderdale-florida-fll/',
                    title: 'Boston, MA (BOS) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-fort-lauderdale-florida-fll/',
                    title: 'Los Angeles, CA (LAX) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/newark-new-jersey-ewr-to-fort-lauderdale-florida-fll/',
                    title: 'Newark, NJ (EWR) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-fort-lauderdale-florida-fll/',
                    title: 'Philadelphia, PA (PHL) to Fort Lauderdale, FL (FLL)'
                }
            ]
        }
    },
    {
        title: 'Honolulu (HNL)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Honolulu-Hawaii/HNL/',
            title: 'Flights From Honolulu (HNL)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/honolulu-hawaii-hnl-to-denver-colorado-den/',
                    title: 'Honolulu, HI (HNL) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/honolulu-hawaii-hnl-to-hilo-hawaii-ito/',
                    title: 'Honolulu, HI (HNL) to Hilo, HI (ITO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/honolulu-hawaii-hnl-to-las-vegas-nevada-las/',
                    title: 'Honolulu, HI (HNL) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/honolulu-hawaii-hnl-to-lihue-hawaii-lih/',
                    title: 'Honolulu, HI (HNL) to Lihue, HI (LIH)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/honolulu-hawaii-hnl-to-los-angeles-california-lax/',
                    title: 'Honolulu, HI (HNL) to Los Angeles, CA (LAX)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Honolulu-Hawaii/HNL/',
            title: 'Flights To Honolulu (HNL)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-honolulu-hawaii-hnl/',
                    title: 'Atlanta, GA (ATL) to Honolulu, HI (HNL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-honolulu-hawaii-hnl/',
                    title: 'Boston, MA (BOS) to Honolulu, HI (HNL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-honolulu-hawaii-hnl/',
                    title: 'Denver, CO (DEN) to Honolulu, HI (HNL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-honolulu-hawaii-hnl/',
                    title: 'Los Angeles, CA (LAX) to Honolulu, HI (HNL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-honolulu-hawaii-hnl/',
                    title: 'San Francisco, CA (SFO) to Honolulu, HI (HNL)'
                }
            ]
        }
    },
    {
        title: 'Houston (HOU)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Houston-Texas/HOU/',
            title: 'Flights From Houston (HOU)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/houston-texas-hou-to-atlanta-georgia-atl/',
                    title: 'Houston, TX (HOU) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/houston-texas-hou-to-dallas-fort-worth-texas-dfw/',
                    title: 'Houston, TX (HOU) to Dallas Fort Worth, TX (DFW)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/houston-texas-hou-to-denver-colorado-den/',
                    title: 'Houston, TX (HOU) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/houston-texas-hou-to-new-orleans-louisiana-msy/',
                    title: 'Houston, TX (HOU) to New Orleans, LA (MSY)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/houston-texas-hou-to-orlando-florida-mco/',
                    title: 'Houston, TX (HOU) to Orlando, FL (MCO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Houston-Texas/HOU/',
            title: 'Flights To Houston (HOU)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-houston-texas-hou/',
                    title: 'Atlanta, GA (ATL) to Houston, TX (HOU)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-houston-texas-hou/',
                    title: 'Boston, MA (BOS) to Houston, TX (HOU)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-houston-texas-hou/',
                    title: 'Denver, CO (DEN) to Houston, TX (HOU)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-houston-texas-hou/',
                    title: 'Las Vegas, NV (LAS) to Houston, TX (HOU)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-houston-texas-hou/',
                    title: 'Los Angeles, CA (LAX) to Houston, TX (HOU)'
                }
            ]
        }
    },
    {
        title: 'Houston (IAH)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Houston-Texas/IAH/',
            title: 'Flights From Houston (IAH)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/houston-texas-iah-to-boston-massachusetts-bos/',
                    title: 'Houston, TX (IAH) to Boston, MA (BOS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/houston-texas-iah-to-las-vegas-nevada-las/',
                    title: 'Houston, TX (IAH) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/houston-texas-iah-to-los-angeles-california-lax/',
                    title: 'Houston, TX (IAH) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/houston-texas-iah-to-miami-florida-mia/',
                    title: 'Houston, TX (IAH) to Miami, FL (MIA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/houston-texas-iah-to-san-diego-california-san/',
                    title: 'Houston, TX (IAH) to San Diego, CA (SAN)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Houston-Texas/IAH/',
            title: 'Flights To Houston (IAH)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-houston-texas-iah/',
                    title: 'Austin, TX (AUS) to Houston, TX (IAH)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-houston-texas-iah/',
                    title: 'Fort Lauderdale, FL (FLL) to Houston, TX (IAH)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-houston-texas-iah/',
                    title: 'Miami, FL (MIA) to Houston, TX (IAH)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-houston-texas-iah/',
                    title: 'Orlando, FL (MCO) to Houston, TX (IAH)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/seattle-washington-sea-to-houston-texas-iah/',
                    title: 'Seattle, WA (SEA) to Houston, TX (IAH)'
                }
            ]
        }
    },
    {
        title: 'Indianapolis (IND)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Indianapolis-Indiana/IND/',
            title: 'Flights From Indianapolis (IND)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/indianapolis-indiana-ind-to-boston-massachusetts-bos/',
                    title: 'Indianapolis, IN (IND) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/indianapolis-indiana-ind-to-denver-colorado-den/',
                    title: 'Indianapolis, IN (IND) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/indianapolis-indiana-ind-to-las-vegas-nevada-las/',
                    title: 'Indianapolis, IN (IND) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/indianapolis-indiana-ind-to-los-angeles-california-lax/',
                    title: 'Indianapolis, IN (IND) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/indianapolis-indiana-ind-to-orlando-florida-orl/',
                    title: 'Indianapolis, IN (IND) to Orlando, FL (ORL)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Indianapolis-Indiana/IND/',
            title: 'Flights To Indianapolis (IND)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-indianapolis-indiana-ind/',
                    title: 'Austin, TX (AUS) to Indianapolis, IN (IND)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-indianapolis-indiana-ind/',
                    title: 'Boston, MA (BOS) to Indianapolis, IN (IND)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-indianapolis-indiana-ind/',
                    title: 'Las Vegas, NV (LAS) to Indianapolis, IN (IND)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-indianapolis-indiana-ind/',
                    title: 'Los Angeles, CA (LAX) to Indianapolis, IN (IND)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-indianapolis-indiana-ind/',
                    title: 'Orlando, FL (MCO) to Indianapolis, IN (IND)'
                }
            ]
        }
    },
    {
        title: 'Jacksonville (JAX)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Jacksonville-Florida/JAX/',
            title: 'Flights From Jacksonville (JAX)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/jacksonville-florida-jax-to-atlanta-georgia-atl/',
                    title: 'Jacksonville, FL (JAX) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/jacksonville-florida-jax-to-fort-lauderdale-florida-fll/',
                    title: 'Jacksonville, FL (JAX) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/jacksonville-florida-jax-to-key-west-florida-eyw/',
                    title: 'Jacksonville, FL (JAX) to Key West, FL (EYW)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/jacksonville-florida-jax-to-miami-florida-mia/',
                    title: 'Jacksonville, FL (JAX) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/jacksonville-florida-jax-to-philadelphia-pennsylvania-phl/',
                    title: 'Jacksonville, FL (JAX) to Philadelphia, PA (PHL)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Jacksonville-Florida/JAX/',
            title: 'Flights To Jacksonville (JAX)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-jacksonville-florida-jax/',
                    title: 'Atlanta, GA (ATL) to Jacksonville, FL (JAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-jacksonville-florida-jax/',
                    title: 'Denver, CO (DEN) to Jacksonville, FL (JAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-jacksonville-florida-jax/',
                    title: 'Orlando, FL (MCO) to Jacksonville, FL (JAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-jacksonville-florida-jax/',
                    title: 'Philadelphia, PA (PHL) to Jacksonville, FL (JAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/tampa-florida-tpa-to-jacksonville-florida-jax/',
                    title: 'Tampa, FL (TPA) to Jacksonville, FL (JAX)'
                }
            ]
        }
    },
    {
        title: 'Kansas City (MCI)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Kansas-City-Missouri/MCI/',
            title: 'Flights From Kansas City (MCI)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/kansas-city-missouri-mci-to-denver-colorado-den/',
                    title: 'Kansas City, MO (MCI) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/kansas-city-missouri-mci-to-las-vegas-nevada-las/',
                    title: 'Kansas City, MO (MCI) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/kansas-city-missouri-mci-to-los-angeles-california-lax/',
                    title: 'Kansas City, MO (MCI) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/kansas-city-missouri-mci-to-miami-florida-mia/',
                    title: 'Kansas City, MO (MCI) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/kansas-city-missouri-mci-to-orlando-florida-mco/',
                    title: 'Kansas City, MO (MCI) to Orlando, FL (MCO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Kansas-City-Missouri/MCI/',
            title: 'Flights To Kansas City (MCI)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-kansas-city-missouri-mci/',
                    title: 'Atlanta, GA (ATL) to Kansas City, MO (MCI)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-kansas-city-missouri-mci/',
                    title: 'Boston, MA (BOS) to Kansas City, MO (MCI)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-kansas-city-missouri-mci/',
                    title: 'Denver, CO (DEN) to Kansas City, MO (MCI)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-kansas-city-missouri-mci/',
                    title: 'Las Vegas, NV (LAS) to Kansas City, MO (MCI)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-kansas-city-missouri-mci/',
                    title: 'Los Angeles, CA (LAX) to Kansas City, MO (MCI)'
                }
            ]
        }
    },
    {
        title: 'Las Vegas (LAS)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Las-Vegas-Nevada/LAS/',
            title: 'Flights From Las Vegas (LAS)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-los-angeles-california-lax/',
                    title: 'Las Vegas, NV (LAS) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-phoenix-arizona-phx/',
                    title: 'Las Vegas, NV (LAS) to Phoenix, AZ (PHX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-reno-nevada-rno/',
                    title: 'Las Vegas, NV (LAS) to Reno, NV (RNO)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-san-diego-california-san/',
                    title: 'Las Vegas, NV (LAS) to San Diego, CA (SAN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-san-francisco-california-sfo/',
                    title: 'Las Vegas, NV (LAS) to San Francisco, CA (SFO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Las-Vegas-Nevada/LAS/',
            title: 'Flights To Las Vegas (LAS)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-las-vegas-nevada-las/',
                    title: 'Atlanta, GA (ATL) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-las-vegas-nevada-las/',
                    title: 'Denver, CO (DEN) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-las-vegas-nevada-las/',
                    title: 'Los Angeles, CA (LAX) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-las-vegas-nevada-las/',
                    title: 'San Francisco, CA (SFO) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/seattle-washington-sea-to-las-vegas-nevada-las/',
                    title: 'Seattle, WA (SEA) to Las Vegas, NV (LAS)'
                }
            ]
        }
    },
    {
        title: 'Long Beach (LGB)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Long-Beach-California/LGB/',
            title: 'Flights From Long Beach (LGB)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/long-beach-california-lgb-to-las-vegas-nevada-las/',
                    title: 'Long Beach, CA (LGB) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/long-beach-california-lgb-to-los-angeles-california-lax/',
                    title: 'Long Beach, CA (LGB) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/long-beach-california-lgb-to-oakland-california-oak/',
                    title: 'Long Beach, CA (LGB) to Oakland, CA (OAK)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/long-beach-california-lgb-to-salt-lake-city-utah-slc/',
                    title: 'Long Beach, CA (LGB) to Salt Lake City, UT (SLC)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/long-beach-california-lgb-to-san-francisco-california-sfo/',
                    title: 'Long Beach, CA (LGB) to San Francisco, CA (SFO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Long-Beach-California/LGB/',
            title: 'Flights To Long Beach (LGB)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-long-beach-california-lgb/',
                    title: 'Austin, TX (AUS) to Long Beach, CA (LGB)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-long-beach-california-lgb/',
                    title: 'Las Vegas, NV (LAS) to Long Beach, CA (LGB)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/salt-lake-city-utah-slc-to-long-beach-california-lgb/',
                    title: 'Salt Lake City, UT (SLC) to Long Beach, CA (LGB)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-long-beach-california-lgb/',
                    title: 'San Francisco, CA (SFO) to Long Beach, CA (LGB)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/seattle-washington-sea-to-long-beach-california-lgb/',
                    title: 'Seattle, WA (SEA) to Long Beach, CA (LGB)'
                }
            ]
        }
    },
    {
        title: 'Los Angeles (LAX)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Los-Angeles-California/LAX/',
            title: 'Flights From Los Angeles (LAX)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-atlanta-georgia-atl/',
                    title: 'Los Angeles, CA (LAX) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-boston-massachusetts-bos/',
                    title: 'Los Angeles, CA (LAX) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-las-vegas-nevada-las/',
                    title: 'Los Angeles, CA (LAX) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-miami-florida-mia/',
                    title: 'Los Angeles, CA (LAX) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-san-francisco-california-sfo/',
                    title: 'Los Angeles, CA (LAX) to San Francisco, CA (SFO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Los-Angeles-California/LAX/',
            title: 'Flights To Los Angeles (LAX)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-los-angeles-california-lax/',
                    title: 'Boston, MA (BOS) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-los-angeles-california-lax/',
                    title: 'Denver, CO (DEN) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-los-angeles-california-lax/',
                    title: 'Las Vegas, NV (LAS) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-los-angeles-california-lax/',
                    title: 'Philadelphia, PA (PHL) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-los-angeles-california-lax/',
                    title: 'San Francisco, CA (SFO) to Los Angeles, CA (LAX)'
                }
            ]
        }
    },
    {
        title: 'Louisville (SDF)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Louisville-Kentucky/SDF/',
            title: 'Flights From Louisville (SDF)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/louisville-kentucky-sdf-to-atlanta-georgia-atl/',
                    title: 'Louisville, KY (SDF) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/louisville-kentucky-sdf-to-denver-colorado-den/',
                    title: 'Louisville, KY (SDF) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/louisville-kentucky-sdf-to-las-vegas-nevada-las/',
                    title: 'Louisville, KY (SDF) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/louisville-kentucky-sdf-to-miami-florida-mia/',
                    title: 'Louisville, KY (SDF) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/louisville-kentucky-sdf-to-orlando-florida-mco/',
                    title: 'Louisville, KY (SDF) to Orlando, FL (MCO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Louisville-Kentucky/SDF/',
            title: 'Flights To Louisville (SDF)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-louisville-kentucky-sdf/',
                    title: 'Atlanta, GA (ATL) to Louisville, KY (SDF)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-louisville-kentucky-sdf/',
                    title: 'Denver, CO (DEN) to Louisville, KY (SDF)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-louisville-kentucky-sdf/',
                    title: 'Las Vegas, NV (LAS) to Louisville, KY (SDF)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-louisville-kentucky-sdf/',
                    title: 'Miami, FL (MIA) to Louisville, KY (SDF)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/nashville-tennessee-bna-to-louisville-kentucky-sdf/',
                    title: 'Nashville, TN (BNA) to Louisville, KY (SDF)'
                }
            ]
        }
    },
    {
        title: 'Memphis (MEM)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Memphis-Tennessee/MEM/',
            title: 'Flights From Memphis (MEM)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/memphis-tennessee-mem-to-atlanta-georgia-atl/',
                    title: 'Memphis, TN (MEM) to Atlanta, GA (ATL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/memphis-tennessee-mem-to-denver-colorado-den/',
                    title: 'Memphis, TN (MEM) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/memphis-tennessee-mem-to-las-vegas-nevada-las/',
                    title: 'Memphis, TN (MEM) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/memphis-tennessee-mem-to-miami-florida-mia/',
                    title: 'Memphis, TN (MEM) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/memphis-tennessee-mem-to-nashville-tennessee-bna/',
                    title: 'Memphis, TN (MEM) to Nashville, TN (BNA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Memphis-Tennessee/MEM/',
            title: 'Flights To Memphis (MEM)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-memphis-tennessee-mem/',
                    title: 'Atlanta, GA (ATL) to Memphis, TN (MEM)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-memphis-tennessee-mem/',
                    title: 'Boston, MA (BOS) to Memphis, TN (MEM)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-memphis-tennessee-mem/',
                    title: 'Denver, CO (DEN) to Memphis, TN (MEM)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-memphis-tennessee-mem/',
                    title: 'Los Angeles, CA (LAX) to Memphis, TN (MEM)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/nashville-tennessee-bna-to-memphis-tennessee-mem/',
                    title: 'Nashville, TN (BNA) to Memphis, TN (MEM)'
                }
            ]
        }
    },
    {
        title: 'Miami (MIA)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Miami-Florida/MIA/',
            title: 'Flights From Miami (MIA)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-atlanta-georgia-atl/',
                    title: 'Miami, FL (MIA) to Atlanta, GA (ATL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-boston-massachusetts-bos/',
                    title: 'Miami, FL (MIA) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-los-angeles-california-lax/',
                    title: 'Miami, FL (MIA) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-orlando-florida-mco/',
                    title: 'Miami, FL (MIA) to Orlando, FL (MCO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-tampa-florida-tpa/',
                    title: 'Miami, FL (MIA) to Tampa, FL (TPA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Miami-Florida/MIA/',
            title: 'Flights To Miami (MIA)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-miami-florida-mia/',
                    title: 'Atlanta, GA (ATL) to Miami, FL (MIA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-miami-florida-mia/',
                    title: 'Boston, MA (BOS) to Miami, FL (MIA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-miami-florida-mia/',
                    title: 'Denver, CO (DEN) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-miami-florida-mia/',
                    title: 'Los Angeles, CA (LAX) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-miami-florida-mia/',
                    title: 'Philadelphia, PA (PHL) to Miami, FL (MIA)'
                }
            ]
        }
    },
    {
        title: 'Milwaukee (MKE)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Milwaukee-Wisconsin/MKE/',
            title: 'Flights From Milwaukee (MKE)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/milwaukee-wisconsin-mke-to-denver-colorado-den/',
                    title: 'Milwaukee, WI (MKE) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/milwaukee-wisconsin-mke-to-las-vegas-nevada-las/',
                    title: 'Milwaukee, WI (MKE) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/milwaukee-wisconsin-mke-to-miami-florida-mia/',
                    title: 'Milwaukee, WI (MKE) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/milwaukee-wisconsin-mke-to-phoenix-arizona-phx/',
                    title: 'Milwaukee, WI (MKE) to Phoenix, AZ (PHX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/milwaukee-wisconsin-mke-to-tampa-florida-tpa/',
                    title: 'Milwaukee, WI (MKE) to Tampa, FL (TPA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Milwaukee-Wisconsin/MKE/',
            title: 'Flights To Milwaukee (MKE)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-milwaukee-wisconsin-mke/',
                    title: 'Boston, MA (BOS) to Milwaukee, WI (MKE)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-milwaukee-wisconsin-mke/',
                    title: 'Denver, CO (DEN) to Milwaukee, WI (MKE)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/minneapolis-minnesota-msp-to-milwaukee-wisconsin-mke/',
                    title: 'Minneapolis, MN (MSP) to Milwaukee, WI (MKE)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-milwaukee-wisconsin-mke/',
                    title: 'Orlando, FL (MCO) to Milwaukee, WI (MKE)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/phoenix-arizona-phx-to-milwaukee-wisconsin-mke/',
                    title: 'Phoenix, AZ (PHX) to Milwaukee, WI (MKE)'
                }
            ]
        }
    },
    {
        title: 'Minneapolis (MSP)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Minneapolis-Minnesota/MSP/',
            title: 'Flights From Minneapolis (MSP)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/minneapolis-minnesota-msp-to-denver-colorado-den/',
                    title: 'Minneapolis, MN (MSP) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/minneapolis-minnesota-msp-to-fort-myers-florida-rsw/',
                    title: 'Minneapolis, MN (MSP) to Fort Myers, FL (RSW)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/minneapolis-minnesota-msp-to-las-vegas-nevada-las/',
                    title: 'Minneapolis, MN (MSP) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/minneapolis-minnesota-msp-to-los-angeles-california-lax/',
                    title: 'Minneapolis, MN (MSP) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/minneapolis-minnesota-msp-to-phoenix-arizona-phx/',
                    title: 'Minneapolis, MN (MSP) to Phoenix, AZ (PHX)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Minneapolis-Minnesota/MSP/',
            title: 'Flights To Minneapolis (MSP)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-minneapolis-minnesota-msp/',
                    title: 'Boston, MA (BOS) to Minneapolis, MN (MSP)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-minneapolis-minnesota-msp/',
                    title: 'Denver, CO (DEN) to Minneapolis, MN (MSP)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-minneapolis-minnesota-msp/',
                    title: 'Los Angeles, CA (LAX) to Minneapolis, MN (MSP)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/phoenix-arizona-phx-to-minneapolis-minnesota-msp/',
                    title: 'Phoenix, AZ (PHX) to Minneapolis, MN (MSP)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-minneapolis-minnesota-msp/',
                    title: 'San Francisco, CA (SFO) to Minneapolis, MN (MSP)'
                }
            ]
        }
    },
    {
        title: 'Nashville (BNA)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Nashville-Tennessee/BNA/',
            title: 'Flights From Nashville (BNA)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/nashville-tennessee-bna-to-boston-massachusetts-bos/',
                    title: 'Nashville, TN (BNA) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/nashville-tennessee-bna-to-las-vegas-nevada-las/',
                    title: 'Nashville, TN (BNA) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/nashville-tennessee-bna-to-los-angeles-california-lax/',
                    title: 'Nashville, TN (BNA) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/nashville-tennessee-bna-to-orlando-florida-mco/',
                    title: 'Nashville, TN (BNA) to Orlando, FL (MCO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/nashville-tennessee-bna-to-tampa-florida-tpa/',
                    title: 'Nashville, TN (BNA) to Tampa, FL (TPA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Nashville-Tennessee/BNA/',
            title: 'Flights To Nashville (BNA)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-nashville-tennessee-bna/',
                    title: 'Boston, MA (BOS) to Nashville, TN (BNA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-nashville-tennessee-bna/',
                    title: 'Denver, CO (DEN) to Nashville, TN (BNA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-nashville-tennessee-bna/',
                    title: 'Los Angeles, CA (LAX) to Nashville, TN (BNA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-nashville-tennessee-bna/',
                    title: 'Philadelphia, PA (PHL) to Nashville, TN (BNA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/tampa-florida-tpa-to-nashville-tennessee-bna/',
                    title: 'Tampa, FL (TPA) to Nashville, TN (BNA)'
                }
            ]
        }
    },
    {
        title: 'New Orleans (MSY)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-New-Orleans-Louisiana/MSY/',
            title: 'Flights From New Orleans (MSY)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/new-orleans-louisiana-msy-to-atlanta-georgia-atl/',
                    title: 'New Orleans, LA (MSY) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/new-orleans-louisiana-msy-to-houston-texas-hou/',
                    title: 'New Orleans, LA (MSY) to Houston, TX (HOU)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/new-orleans-louisiana-msy-to-las-vegas-nevada-las/',
                    title: 'New Orleans, LA (MSY) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/new-orleans-louisiana-msy-to-los-angeles-california-lax/',
                    title: 'New Orleans, LA (MSY) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/new-orleans-louisiana-msy-to-orlando-florida-mco/',
                    title: 'New Orleans, LA (MSY) to Orlando, FL (MCO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-New-Orleans-Louisiana/MSY/',
            title: 'Flights To New Orleans (MSY)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-new-orleans-louisiana-msy/',
                    title: 'Atlanta, GA (ATL) to New Orleans, LA (MSY)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-new-orleans-louisiana-msy/',
                    title: 'Austin, TX (AUS) to New Orleans, LA (MSY)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-new-orleans-louisiana-msy/',
                    title: 'Boston, MA (BOS) to New Orleans, LA (MSY)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-new-orleans-louisiana-msy/',
                    title: 'Denver, CO (DEN) to New Orleans, LA (MSY)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-new-orleans-louisiana-msy/',
                    title: 'Los Angeles, CA (LAX) to New Orleans, LA (MSY)'
                }
            ]
        }
    },
    {
        title: 'New York City (JFK)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-New-York-City-New-York/JFK/',
            title: 'Flights From New York City (JFK)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/new-york-city-new-york-jfk-to-los-angeles-california-lax/',
                    title: 'New York City, NY (JFK) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/new-york-city-new-york-jfk-to-miami-florida-mia/',
                    title: 'New York City, NY (JFK) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/new-york-city-new-york-jfk-to-phoenix-arizona-phx/',
                    title: 'New York City, NY (JFK) to Phoenix, AZ (PHX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/new-york-city-new-york-jfk-to-san-diego-california-san/',
                    title: 'New York City, NY (JFK) to San Diego, CA (SAN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/new-york-city-new-york-jfk-to-san-francisco-california-sfo/',
                    title: 'New York City, NY (JFK) to San Francisco, CA (SFO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-New-York-City-New-York/JFK/',
            title: 'Flights To New York City (JFK)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-new-york-city-new-york-jfk/',
                    title: 'Boston, MA (BOS) to New York City, NY (JFK)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-new-york-city-new-york-jfk/',
                    title: 'Los Angeles, CA (LAX) to New York City, NY (JFK)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-new-york-city-new-york-jfk/',
                    title: 'Miami, FL (MIA) to New York City, NY (JFK)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-new-york-city-new-york-jfk/',
                    title: 'Orlando, FL (MCO) to New York City, NY (JFK)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-new-york-city-new-york-jfk/',
                    title: 'San Francisco, CA (SFO) to New York City, NY (JFK)'
                }
            ]
        }
    },
    {
        title: 'Newark (EWR)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Newark-New-Jersey/EWR/',
            title: 'Flights From Newark (EWR)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/newark-new-jersey-ewr-to-atlanta-georgia-atl/',
                    title: 'Newark, NJ (EWR) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/newark-new-jersey-ewr-to-fort-lauderdale-florida-fll/',
                    title: 'Newark, NJ (EWR) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/newark-new-jersey-ewr-to-las-vegas-nevada-las/',
                    title: 'Newark, NJ (EWR) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/newark-new-jersey-ewr-to-los-angeles-california-lax/',
                    title: 'Newark, NJ (EWR) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/newark-new-jersey-ewr-to-miami-florida-mia/',
                    title: 'Newark, NJ (EWR) to Miami, FL (MIA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Newark-New-Jersey/EWR/',
            title: 'Flights To Newark (EWR)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-newark-new-jersey-ewr/',
                    title: 'Atlanta, GA (ATL) to Newark, NJ (EWR)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-newark-new-jersey-ewr/',
                    title: 'Boston, MA (BOS) to Newark, NJ (EWR)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-newark-new-jersey-ewr/',
                    title: 'Fort Lauderdale, FL (FLL) to Newark, NJ (EWR)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-newark-new-jersey-ewr/',
                    title: 'Los Angeles, CA (LAX) to Newark, NJ (EWR)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-newark-new-jersey-ewr/',
                    title: 'Orlando, FL (MCO) to Newark, NJ (EWR)'
                }
            ]
        }
    },
    {
        title: 'Oakland (OAK)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Oakland-California/OAK/',
            title: 'Flights From Oakland (OAK)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/oakland-california-oak-to-houston-texas-hou/',
                    title: 'Oakland, CA (OAK) to Houston, TX (HOU)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/oakland-california-oak-to-las-vegas-nevada-las/',
                    title: 'Oakland, CA (OAK) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/oakland-california-oak-to-los-angeles-california-lax/',
                    title: 'Oakland, CA (OAK) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/oakland-california-oak-to-portland-oregon-pdx/',
                    title: 'Oakland, CA (OAK) to Portland, OR (PDX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/oakland-california-oak-to-seattle-washington-sea/',
                    title: 'Oakland, CA (OAK) to Seattle, WA (SEA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Oakland-California/OAK/',
            title: 'Flights To Oakland (OAK)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/houston-texas-iah-to-oakland-california-oak/',
                    title: 'Houston, TX (IAH) to Oakland, CA (OAK)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-oakland-california-oak/',
                    title: 'Las Vegas, NV (LAS) to Oakland, CA (OAK)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-oakland-california-oak/',
                    title: 'Los Angeles, CA (LAX) to Oakland, CA (OAK)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/portland-oregon-pdx-to-oakland-california-oak/',
                    title: 'Portland, OR (PDX) to Oakland, CA (OAK)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-oakland-california-oak/',
                    title: 'San Francisco, CA (SFO) to Oakland, CA (OAK)'
                }
            ]
        }
    },
    {
        title: 'Ontario (ONT)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Ontario-California/ONT/',
            title: 'Flights From Ontario (ONT)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/ontario-california-ont-to-denver-colorado-den/',
                    title: 'Ontario, CA (ONT) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/ontario-california-ont-to-las-vegas-nevada-las/',
                    title: 'Ontario, CA (ONT) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/ontario-california-ont-to-los-angeles-california-lax/',
                    title: 'Ontario, CA (ONT) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/ontario-california-ont-to-san-francisco-california-sfo/',
                    title: 'Ontario, CA (ONT) to San Francisco, CA (SFO)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/ontario-california-ont-to-seattle-washington-sea/',
                    title: 'Ontario, CA (ONT) to Seattle, WA (SEA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Ontario-California/ONT/',
            title: 'Flights To Ontario (ONT)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-ontario-california-ont/',
                    title: 'Denver, CO (DEN) to Ontario, CA (ONT)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-ontario-california-ont/',
                    title: 'Los Angeles, CA (LAX) to Ontario, CA (ONT)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/portland-oregon-pdx-to-ontario-california-ont/',
                    title: 'Portland, OR (PDX) to Ontario, CA (ONT)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-ontario-california-ont/',
                    title: 'San Francisco, CA (SFO) to Ontario, CA (ONT)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/seattle-washington-sea-to-ontario-california-ont/',
                    title: 'Seattle, WA (SEA) to Ontario, CA (ONT)'
                }
            ]
        }
    },
    {
        title: 'Orlando (MCO)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Orlando-Florida/MCO/',
            title: 'Flights From Orlando (MCO)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-denver-colorado-den/',
                    title: 'Orlando, FL (MCO) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-key-west-florida-eyw/',
                    title: 'Orlando, FL (MCO) to Key West, FL (EYW)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-las-vegas-nevada-las/',
                    title: 'Orlando, FL (MCO) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-miami-florida-mia/',
                    title: 'Orlando, FL (MCO) to Miami, FL (MIA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-newark-new-jersey-ewr/',
                    title: 'Orlando, FL (MCO) to Newark, NJ (EWR)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Orlando-Florida/MCO/',
            title: 'Flights To Orlando (MCO)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-orlando-florida-mco/',
                    title: 'Boston, MA (BOS) to Orlando, FL (MCO)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/charlotte-north-carolina-clt-to-orlando-florida-mco/',
                    title: 'Charlotte, NC (CLT) to Orlando, FL (MCO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-orlando-florida-mco/',
                    title: 'Miami, FL (MIA) to Orlando, FL (MCO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/newark-new-jersey-ewr-to-orlando-florida-mco/',
                    title: 'Newark, NJ (EWR) to Orlando, FL (MCO)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-orlando-florida-mco/',
                    title: 'Philadelphia, PA (PHL) to Orlando, FL (MCO)'
                }
            ]
        }
    },
    {
        title: 'Philadelphia (PHL)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Philadelphia-Pennsylvania/PHL/',
            title: 'Flights From Philadelphia (PHL)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-fort-lauderdale-florida-fll/',
                    title: 'Philadelphia, PA (PHL) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-las-vegas-nevada-las/',
                    title: 'Philadelphia, PA (PHL) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-los-angeles-california-lax/',
                    title: 'Philadelphia, PA (PHL) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-miami-florida-mia/',
                    title: 'Philadelphia, PA (PHL) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-orlando-florida-mco/',
                    title: 'Philadelphia, PA (PHL) to Orlando, FL (MCO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Philadelphia-Pennsylvania/PHL/',
            title: 'Flights To Philadelphia (PHL)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-philadelphia-pennsylvania-phl/',
                    title: 'Atlanta, GA (ATL) to Philadelphia, PA (PHL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-philadelphia-pennsylvania-phl/',
                    title: 'Boston, MA (BOS) to Philadelphia, PA (PHL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-philadelphia-pennsylvania-phl/',
                    title: 'Denver, CO (DEN) to Philadelphia, PA (PHL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-philadelphia-pennsylvania-phl/',
                    title: 'Fort Lauderdale, FL (FLL) to Philadelphia, PA (PHL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-philadelphia-pennsylvania-phl/',
                    title: 'Los Angeles, CA (LAX) to Philadelphia, PA (PHL)'
                }
            ]
        }
    },
    {
        title: 'Phoenix (PHX)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Phoenix-Arizona/PHX/',
            title: 'Flights From Phoenix (PHX)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/phoenix-arizona-phx-to-atlanta-georgia-atl/',
                    title: 'Phoenix, AZ (PHX) to Atlanta, GA (ATL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/phoenix-arizona-phx-to-las-vegas-nevada-las/',
                    title: 'Phoenix, AZ (PHX) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/phoenix-arizona-phx-to-los-angeles-california-lax/',
                    title: 'Phoenix, AZ (PHX) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/phoenix-arizona-phx-to-san-francisco-california-sfo/',
                    title: 'Phoenix, AZ (PHX) to San Francisco, CA (SFO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/phoenix-arizona-phx-to-seattle-washington-sea/',
                    title: 'Phoenix, AZ (PHX) to Seattle, WA (SEA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Phoenix-Arizona/PHX/',
            title: 'Flights To Phoenix (PHX)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-phoenix-arizona-phx/',
                    title: 'Boston, MA (BOS) to Phoenix, AZ (PHX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-phoenix-arizona-phx/',
                    title: 'Denver, CO (DEN) to Phoenix, AZ (PHX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-phoenix-arizona-phx/',
                    title: 'Las Vegas, NV (LAS) to Phoenix, AZ (PHX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/minneapolis-minnesota-msp-to-phoenix-arizona-phx/',
                    title: 'Minneapolis, MN (MSP) to Phoenix, AZ (PHX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/salt-lake-city-utah-slc-to-phoenix-arizona-phx/',
                    title: 'Salt Lake City, UT (SLC) to Phoenix, AZ (PHX)'
                }
            ]
        }
    },
    {
        title: 'Pittsburgh (PIT)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Pittsburgh-Pennsylvania/PIT/',
            title: 'Flights From Pittsburgh (PIT)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/pittsburgh-pennsylvania-pit-to-boston-massachusetts-bos/',
                    title: 'Pittsburgh, PA (PIT) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/pittsburgh-pennsylvania-pit-to-fort-lauderdale-florida-fll/',
                    title: 'Pittsburgh, PA (PIT) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/pittsburgh-pennsylvania-pit-to-los-angeles-california-lax/',
                    title: 'Pittsburgh, PA (PIT) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/pittsburgh-pennsylvania-pit-to-orlando-florida-orl/',
                    title: 'Pittsburgh, PA (PIT) to Orlando, FL (ORL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/pittsburgh-pennsylvania-pit-to-philadelphia-pennsylvania-phl/',
                    title: 'Pittsburgh, PA (PIT) to Philadelphia, PA (PHL)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Pittsburgh-Pennsylvania/PIT/',
            title: 'Flights To Pittsburgh (PIT)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-pittsburgh-pennsylvania-pit/',
                    title: 'Boston, MA (BOS) to Pittsburgh, PA (PIT)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-pittsburgh-pennsylvania-pit/',
                    title: 'Denver, CO (DEN) to Pittsburgh, PA (PIT)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-pittsburgh-pennsylvania-pit/',
                    title: 'Los Angeles, CA (LAX) to Pittsburgh, PA (PIT)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-pittsburgh-pennsylvania-pit/',
                    title: 'Philadelphia, PA (PHL) to Pittsburgh, PA (PIT)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-pittsburgh-pennsylvania-pit/',
                    title: 'San Francisco, CA (SFO) to Pittsburgh, PA (PIT)'
                }
            ]
        }
    },
    {
        title: 'Portland (PDX)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Portland-Oregon/PDX/',
            title: 'Flights From Portland (PDX)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/portland-oregon-pdx-to-denver-colorado-den/',
                    title: 'Portland, OR (PDX) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/portland-oregon-pdx-to-las-vegas-nevada-las/',
                    title: 'Portland, OR (PDX) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/portland-oregon-pdx-to-los-angeles-california-lax/',
                    title: 'Portland, OR (PDX) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/portland-oregon-pdx-to-phoenix-arizona-phx/',
                    title: 'Portland, OR (PDX) to Phoenix, AZ (PHX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/portland-oregon-pdx-to-san-francisco-california-sfo/',
                    title: 'Portland, OR (PDX) to San Francisco, CA (SFO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Portland-Oregon/PDX/',
            title: 'Flights To Portland (PDX)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-portland-oregon-pdx/',
                    title: 'Denver, CO (DEN) to Portland, OR (PDX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-portland-oregon-pdx/',
                    title: 'Las Vegas, NV (LAS) to Portland, OR (PDX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-portland-oregon-pdx/',
                    title: 'Los Angeles, CA (LAX) to Portland, OR (PDX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/minneapolis-minnesota-msp-to-portland-oregon-pdx/',
                    title: 'Minneapolis, MN (MSP) to Portland, OR (PDX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-portland-oregon-pdx/',
                    title: 'San Francisco, CA (SFO) to Portland, OR (PDX)'
                }
            ]
        }
    },
    {
        title: 'Reno (RNO)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Reno-Nevada/RNO/',
            title: 'Flights From Reno (RNO)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/reno-nevada-rno-to-denver-colorado-den/',
                    title: 'Reno, NV (RNO) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/reno-nevada-rno-to-las-vegas-nevada-las/',
                    title: 'Reno, NV (RNO) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/reno-nevada-rno-to-los-angeles-california-lax/',
                    title: 'Reno, NV (RNO) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/reno-nevada-rno-to-phoenix-arizona-phx/',
                    title: 'Reno, NV (RNO) to Phoenix, AZ (PHX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/reno-nevada-rno-to-portland-oregon-pdx/',
                    title: 'Reno, NV (RNO) to Portland, OR (PDX)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Reno-Nevada/RNO/',
            title: 'Flights To Reno (RNO)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-reno-nevada-rno/',
                    title: 'Boston, MA (BOS) to Reno, NV (RNO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-reno-nevada-rno/',
                    title: 'Denver, CO (DEN) to Reno, NV (RNO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/houston-texas-iah-to-reno-nevada-rno/',
                    title: 'Houston, TX (IAH) to Reno, NV (RNO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-reno-nevada-rno/',
                    title: 'Las Vegas, NV (LAS) to Reno, NV (RNO)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-reno-nevada-rno/',
                    title: 'Los Angeles, CA (LAX) to Reno, NV (RNO)'
                }
            ]
        }
    },
    {
        title: 'Sacramento (SMF)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Sacramento-California/SMF/',
            title: 'Flights From Sacramento (SMF)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/sacramento-california-smf-to-atlanta-georgia-atl/',
                    title: 'Sacramento, CA (SMF) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/sacramento-california-smf-to-las-vegas-nevada-las/',
                    title: 'Sacramento, CA (SMF) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/sacramento-california-smf-to-los-angeles-california-lax/',
                    title: 'Sacramento, CA (SMF) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/sacramento-california-smf-to-san-diego-california-san/',
                    title: 'Sacramento, CA (SMF) to San Diego, CA (SAN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/sacramento-california-smf-to-seattle-washington-sea/',
                    title: 'Sacramento, CA (SMF) to Seattle, WA (SEA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Sacramento-California/SMF/',
            title: 'Flights To Sacramento (SMF)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-sacramento-california-smf/',
                    title: 'Boston, MA (BOS) to Sacramento, CA (SMF)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-sacramento-california-smf/',
                    title: 'Las Vegas, NV (LAS) to Sacramento, CA (SMF)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-sacramento-california-smf/',
                    title: 'Los Angeles, CA (LAX) to Sacramento, CA (SMF)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/portland-oregon-pdx-to-sacramento-california-smf/',
                    title: 'Portland, OR (PDX) to Sacramento, CA (SMF)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-diego-california-san-to-sacramento-california-smf/',
                    title: 'San Diego, CA (SAN) to Sacramento, CA (SMF)'
                }
            ]
        }
    },
    {
        title: 'Salt Lake City (SLC)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Salt-Lake-City-Utah/SLC/',
            title: 'Flights From Salt Lake City (SLC)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/salt-lake-city-utah-slc-to-boston-massachusetts-bos/',
                    title: 'Salt Lake City, UT (SLC) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/salt-lake-city-utah-slc-to-denver-colorado-den/',
                    title: 'Salt Lake City, UT (SLC) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/salt-lake-city-utah-slc-to-las-vegas-nevada-las/',
                    title: 'Salt Lake City, UT (SLC) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/salt-lake-city-utah-slc-to-los-angeles-california-lax/',
                    title: 'Salt Lake City, UT (SLC) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/salt-lake-city-utah-slc-to-phoenix-arizona-phx/',
                    title: 'Salt Lake City, UT (SLC) to Phoenix, AZ (PHX)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Salt-Lake-City-Utah/SLC/',
            title: 'Flights To Salt Lake City (SLC)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-salt-lake-city-utah-slc/',
                    title: 'Boston, MA (BOS) to Salt Lake City, UT (SLC)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-salt-lake-city-utah-slc/',
                    title: 'Denver, CO (DEN) to Salt Lake City, UT (SLC)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-salt-lake-city-utah-slc/',
                    title: 'Los Angeles, CA (LAX) to Salt Lake City, UT (SLC)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/phoenix-arizona-phx-to-salt-lake-city-utah-slc/',
                    title: 'Phoenix, AZ (PHX) to Salt Lake City, UT (SLC)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-salt-lake-city-utah-slc/',
                    title: 'San Francisco, CA (SFO) to Salt Lake City, UT (SLC)'
                }
            ]
        }
    },
    {
        title: 'San Antonio (SAT)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-San-Antonio-Texas/SAT/',
            title: 'Flights From San Antonio (SAT)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-antonio-texas-sat-to-dallas-fort-worth-texas-dfw/',
                    title: 'San Antonio, TX (SAT) to Dallas Fort Worth, TX (DFW)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/san-antonio-texas-sat-to-denver-colorado-den/',
                    title: 'San Antonio, TX (SAT) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/san-antonio-texas-sat-to-houston-texas-iah/',
                    title: 'San Antonio, TX (SAT) to Houston, TX (IAH)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/san-antonio-texas-sat-to-las-vegas-nevada-las/',
                    title: 'San Antonio, TX (SAT) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-antonio-texas-sat-to-los-angeles-california-lax/',
                    title: 'San Antonio, TX (SAT) to Los Angeles, CA (LAX)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-San-Antonio-Texas/SAT/',
            title: 'Flights To San Antonio (SAT)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-san-antonio-texas-sat/',
                    title: 'Atlanta, GA (ATL) to San Antonio, TX (SAT)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-san-antonio-texas-sat/',
                    title: 'Austin, TX (AUS) to San Antonio, TX (SAT)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-san-antonio-texas-sat/',
                    title: 'Denver, CO (DEN) to San Antonio, TX (SAT)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/houston-texas-iah-to-san-antonio-texas-sat/',
                    title: 'Houston, TX (IAH) to San Antonio, TX (SAT)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-san-antonio-texas-sat/',
                    title: 'Las Vegas, NV (LAS) to San Antonio, TX (SAT)'
                }
            ]
        }
    },
    {
        title: 'San Diego (SAN)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-San-Diego-California/SAN/',
            title: 'Flights From San Diego (SAN)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-diego-california-san-to-boston-massachusetts-bos/',
                    title: 'San Diego, CA (SAN) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-diego-california-san-to-denver-colorado-den/',
                    title: 'San Diego, CA (SAN) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-diego-california-san-to-las-vegas-nevada-las/',
                    title: 'San Diego, CA (SAN) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/san-diego-california-san-to-miami-florida-mia/',
                    title: 'San Diego, CA (SAN) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-diego-california-san-to-san-francisco-california-sfo/',
                    title: 'San Diego, CA (SAN) to San Francisco, CA (SFO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-San-Diego-California/SAN/',
            title: 'Flights To San Diego (SAN)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-san-diego-california-san/',
                    title: 'Boston, MA (BOS) to San Diego, CA (SAN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-san-diego-california-san/',
                    title: 'Denver, CO (DEN) to San Diego, CA (SAN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/las-vegas-nevada-las-to-san-diego-california-san/',
                    title: 'Las Vegas, NV (LAS) to San Diego, CA (SAN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/sacramento-california-smf-to-san-diego-california-san/',
                    title: 'Sacramento, CA (SMF) to San Diego, CA (SAN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-san-diego-california-san/',
                    title: 'San Francisco, CA (SFO) to San Diego, CA (SAN)'
                }
            ]
        }
    },
    {
        title: 'San Francisco (SFO)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-San-Francisco-California/SFO/',
            title: 'Flights From San Francisco (SFO)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-boston-massachusetts-bos/',
                    title: 'San Francisco, CA (SFO) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-las-vegas-nevada-las/',
                    title: 'San Francisco, CA (SFO) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-los-angeles-california-lax/',
                    title: 'San Francisco, CA (SFO) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-san-diego-california-san/',
                    title: 'San Francisco, CA (SFO) to San Diego, CA (SAN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-seattle-washington-sea/',
                    title: 'San Francisco, CA (SFO) to Seattle, WA (SEA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-San-Francisco-California/SFO/',
            title: 'Flights To San Francisco (SFO)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-san-francisco-california-sfo/',
                    title: 'Boston, MA (BOS) to San Francisco, CA (SFO)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-san-francisco-california-sfo/',
                    title: 'Denver, CO (DEN) to San Francisco, CA (SFO)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-san-francisco-california-sfo/',
                    title: 'Los Angeles, CA (LAX) to San Francisco, CA (SFO)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-diego-california-san-to-san-francisco-california-sfo/',
                    title: 'San Diego, CA (SAN) to San Francisco, CA (SFO)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/seattle-washington-sea-to-san-francisco-california-sfo/',
                    title: 'Seattle, WA (SEA) to San Francisco, CA (SFO)'
                }
            ]
        }
    },
    {
        title: 'San Jose (SJC)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-San-Jose-California/SJC/',
            title: 'Flights From San Jose (SJC)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-jose-california-sjc-to-boston-massachusetts-bos/',
                    title: 'San Jose, CA (SJC) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-jose-california-sjc-to-las-vegas-nevada-las/',
                    title: 'San Jose, CA (SJC) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-jose-california-sjc-to-los-angeles-california-lax/',
                    title: 'San Jose, CA (SJC) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-jose-california-sjc-to-san-diego-california-san/',
                    title: 'San Jose, CA (SJC) to San Diego, CA (SAN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-jose-california-sjc-to-seattle-washington-sea/',
                    title: 'San Jose, CA (SJC) to Seattle, WA (SEA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-San-Jose-California/SJC/',
            title: 'Flights To San Jose (SJC)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-san-jose-california-sjc/',
                    title: 'Boston, MA (BOS) to San Jose, CA (SJC)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-san-jose-california-sjc/',
                    title: 'Denver, CO (DEN) to San Jose, CA (SJC)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-san-jose-california-sjc/',
                    title: 'Los Angeles, CA (LAX) to San Jose, CA (SJC)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/phoenix-arizona-phx-to-san-jose-california-sjc/',
                    title: 'Phoenix, AZ (PHX) to San Jose, CA (SJC)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/portland-oregon-pdx-to-san-jose-california-sjc/',
                    title: 'Portland, OR (PDX) to San Jose, CA (SJC)'
                }
            ]
        }
    },
    {
        title: 'Seattle (SEA)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Seattle-Washington/SEA/',
            title: 'Flights From Seattle (SEA)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/seattle-washington-sea-to-atlanta-georgia-atl/',
                    title: 'Seattle, WA (SEA) to Atlanta, GA (ATL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/seattle-washington-sea-to-denver-colorado-den/',
                    title: 'Seattle, WA (SEA) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/seattle-washington-sea-to-las-vegas-nevada-las/',
                    title: 'Seattle, WA (SEA) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/seattle-washington-sea-to-los-angeles-california-lax/',
                    title: 'Seattle, WA (SEA) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/seattle-washington-sea-to-san-francisco-california-sfo/',
                    title: 'Seattle, WA (SEA) to San Francisco, CA (SFO)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Seattle-Washington/SEA/',
            title: 'Flights To Seattle (SEA)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-seattle-washington-sea/',
                    title: 'Atlanta, GA (ATL) to Seattle, WA (SEA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-seattle-washington-sea/',
                    title: 'Boston, MA (BOS) to Seattle, WA (SEA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-seattle-washington-sea/',
                    title: 'Denver, CO (DEN) to Seattle, WA (SEA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/phoenix-arizona-phx-to-seattle-washington-sea/',
                    title: 'Phoenix, AZ (PHX) to Seattle, WA (SEA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-seattle-washington-sea/',
                    title: 'San Francisco, CA (SFO) to Seattle, WA (SEA)'
                }
            ]
        }
    },
    {
        title: 'Syracuse (SYR)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Syracuse-New-York/SYR/',
            title: 'Flights From Syracuse (SYR)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/syracuse-new-york-syr-to-boston-massachusetts-bos/',
                    title: 'Syracuse, NY (SYR) to Boston, MA (BOS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/syracuse-new-york-syr-to-denver-colorado-den/',
                    title: 'Syracuse, NY (SYR) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/syracuse-new-york-syr-to-fort-lauderdale-florida-fll/',
                    title: 'Syracuse, NY (SYR) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/syracuse-new-york-syr-to-miami-florida-mia/',
                    title: 'Syracuse, NY (SYR) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/syracuse-new-york-syr-to-nashville-tennessee-bna/',
                    title: 'Syracuse, NY (SYR) to Nashville, TN (BNA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Syracuse-New-York/SYR/',
            title: 'Flights To Syracuse (SYR)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-syracuse-new-york-syr/',
                    title: 'Atlanta, GA (ATL) to Syracuse, NY (SYR)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-syracuse-new-york-syr/',
                    title: 'Boston, MA (BOS) to Syracuse, NY (SYR)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-syracuse-new-york-syr/',
                    title: 'Denver, CO (DEN) to Syracuse, NY (SYR)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/nashville-tennessee-bna-to-syracuse-new-york-syr/',
                    title: 'Nashville, TN (BNA) to Syracuse, NY (SYR)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-syracuse-new-york-syr/',
                    title: 'Orlando, FL (MCO) to Syracuse, NY (SYR)'
                }
            ]
        }
    },
    {
        title: 'Tampa (TPA)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Tampa-Florida/TPA/',
            title: 'Flights From Tampa (TPA)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/tampa-florida-tpa-to-atlanta-georgia-atl/',
                    title: 'Tampa, FL (TPA) to Atlanta, GA (ATL)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/tampa-florida-tpa-to-denver-colorado-den/',
                    title: 'Tampa, FL (TPA) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/tampa-florida-tpa-to-key-west-florida-eyw/',
                    title: 'Tampa, FL (TPA) to Key West, FL (EYW)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/tampa-florida-tpa-to-las-vegas-nevada-las/',
                    title: 'Tampa, FL (TPA) to Las Vegas, NV (LAS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/tampa-florida-tpa-to-miami-florida-mia/',
                    title: 'Tampa, FL (TPA) to Miami, FL (MIA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Tampa-Florida/TPA/',
            title: 'Flights To Tampa (TPA)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-tampa-florida-tpa/',
                    title: 'Atlanta, GA (ATL) to Tampa, FL (TPA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-tampa-florida-tpa/',
                    title: 'Boston, MA (BOS) to Tampa, FL (TPA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-tampa-florida-tpa/',
                    title: 'Denver, CO (DEN) to Tampa, FL (TPA)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-tampa-florida-tpa/',
                    title: 'Miami, FL (MIA) to Tampa, FL (TPA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-tampa-florida-tpa/',
                    title: 'Philadelphia, PA (PHL) to Tampa, FL (TPA)'
                }
            ]
        }
    },
    {
        title: 'Tucson (TUS)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Tucson-Arizona/TUS/',
            title: 'Flights From Tucson (TUS)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/tucson-arizona-tus-to-denver-colorado-den/',
                    title: 'Tucson, AZ (TUS) to Denver, CO (DEN)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/tucson-arizona-tus-to-las-vegas-nevada-las/',
                    title: 'Tucson, AZ (TUS) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/tucson-arizona-tus-to-los-angeles-california-lax/',
                    title: 'Tucson, AZ (TUS) to Los Angeles, CA (LAX)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/tucson-arizona-tus-to-phoenix-arizona-phx/',
                    title: 'Tucson, AZ (TUS) to Phoenix, AZ (PHX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/tucson-arizona-tus-to-san-diego-california-san/',
                    title: 'Tucson, AZ (TUS) to San Diego, CA (SAN)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Tucson-Arizona/TUS/',
            title: 'Flights To Tucson (TUS)',
            routes: [
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-tucson-arizona-tus/',
                    title: 'Austin, TX (AUS) to Tucson, AZ (TUS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-tucson-arizona-tus/',
                    title: 'Boston, MA (BOS) to Tucson, AZ (TUS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-tucson-arizona-tus/',
                    title: 'Denver, CO (DEN) to Tucson, AZ (TUS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-tucson-arizona-tus/',
                    title: 'Los Angeles, CA (LAX) to Tucson, AZ (TUS)'
                },
                {
                    url: 'https://www.airfarewatchdog.com/cheap-flights/phoenix-arizona-phx-to-tucson-arizona-tus/',
                    title: 'Phoenix, AZ (PHX) to Tucson, AZ (TUS)'
                }
            ]
        }
    },
    {
        title: 'Washington (DCA)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Washington-DC-District-of-Columbia/DCA/',
            title: 'Flights From Washington (DCA)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/washington-dc-district-of-columbia-dca-to-atlanta-georgia-atl/',
                    title: 'Washington, DC (DCA) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/washington-dc-district-of-columbia-dca-to-boston-massachusetts-bos/',
                    title: 'Washington, DC (DCA) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/washington-dc-district-of-columbia-dca-to-chicago-illinois-ord/',
                    title: 'Washington, DC (DCA) to Chicago, IL (ORD)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/washington-dc-district-of-columbia-dca-to-los-angeles-california-lax/',
                    title: 'Washington, DC (DCA) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/washington-dc-district-of-columbia-dca-to-miami-florida-mia/',
                    title: 'Washington, DC (DCA) to Miami, FL (MIA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Washington-DC-District-of-Columbia/DCA/',
            title: 'Flights To Washington (DCA)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-washington-dc-district-of-columbia-dca/',
                    title: 'Atlanta, GA (ATL) to Washington, DC (DCA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-washington-dc-district-of-columbia-dca/',
                    title: 'Boston, MA (BOS) to Washington, DC (DCA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-washington-dc-district-of-columbia-dca/',
                    title: 'Denver, CO (DEN) to Washington, DC (DCA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-washington-dc-district-of-columbia-dca/',
                    title: 'Los Angeles, CA (LAX) to Washington, DC (DCA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-washington-dc-district-of-columbia-dca/',
                    title: 'San Francisco, CA (SFO) to Washington, DC (DCA)'
                }
            ]
        }
    },
    {
        title: 'Washington (IAD)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-Washington-DC-District-of-Columbia/IAD/',
            title: 'Flights From Washington (IAD)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/washington-dc-district-of-columbia-iad-to-boston-massachusetts-bos/',
                    title: 'Washington, DC (IAD) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/washington-dc-district-of-columbia-iad-to-denver-colorado-den/',
                    title: 'Washington, DC (IAD) to Denver, CO (DEN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/washington-dc-district-of-columbia-iad-to-las-vegas-nevada-las/',
                    title: 'Washington, DC (IAD) to Las Vegas, NV (LAS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/washington-dc-district-of-columbia-iad-to-los-angeles-california-lax/',
                    title: 'Washington, DC (IAD) to Los Angeles, CA (LAX)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/washington-dc-district-of-columbia-iad-to-miami-florida-mia/',
                    title: 'Washington, DC (IAD) to Miami, FL (MIA)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-Washington-DC-District-of-Columbia/IAD/',
            title: 'Flights To Washington (IAD)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/austin-texas-aus-to-washington-dc-district-of-columbia-iad/',
                    title: 'Austin, TX (AUS) to Washington, DC (IAD)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-washington-dc-district-of-columbia-iad/',
                    title: 'Boston, MA (BOS) to Washington, DC (IAD)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/denver-colorado-den-to-washington-dc-district-of-columbia-iad/',
                    title: 'Denver, CO (DEN) to Washington, DC (IAD)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/los-angeles-california-lax-to-washington-dc-district-of-columbia-iad/',
                    title: 'Los Angeles, CA (LAX) to Washington, DC (IAD)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/san-francisco-california-sfo-to-washington-dc-district-of-columbia-iad/',
                    title: 'San Francisco, CA (SFO) to Washington, DC (IAD)'
                }
            ]
        }
    },
    {
        title: 'West Palm Beach (PBI)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-West-Palm-Beach-Florida/PBI/',
            title: 'Flights From West Palm Beach (PBI)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/west-palm-beach-florida-pbi-to-atlanta-georgia-atl/',
                    title: 'West Palm Beach, FL (PBI) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/west-palm-beach-florida-pbi-to-boston-massachusetts-bos/',
                    title: 'West Palm Beach, FL (PBI) to Boston, MA (BOS)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/west-palm-beach-florida-pbi-to-miami-florida-mia/',
                    title: 'West Palm Beach, FL (PBI) to Miami, FL (MIA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/west-palm-beach-florida-pbi-to-newark-new-jersey-ewr/',
                    title: 'West Palm Beach, FL (PBI) to Newark, NJ (EWR)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/west-palm-beach-florida-pbi-to-philadelphia-pennsylvania-phl/',
                    title: 'West Palm Beach, FL (PBI) to Philadelphia, PA (PHL)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-West-Palm-Beach-Florida/PBI/',
            title: 'Flights To West Palm Beach (PBI)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-west-palm-beach-florida-pbi/',
                    title: 'Atlanta, GA (ATL) to West Palm Beach, FL (PBI)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/boston-massachusetts-bos-to-west-palm-beach-florida-pbi/',
                    title: 'Boston, MA (BOS) to West Palm Beach, FL (PBI)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/miami-florida-mia-to-west-palm-beach-florida-pbi/',
                    title: 'Miami, FL (MIA) to West Palm Beach, FL (PBI)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/newark-new-jersey-ewr-to-west-palm-beach-florida-pbi/',
                    title: 'Newark, NJ (EWR) to West Palm Beach, FL (PBI)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/philadelphia-pennsylvania-phl-to-west-palm-beach-florida-pbi/',
                    title: 'Philadelphia, PA (PHL) to West Palm Beach, FL (PBI)'
                }
            ]
        }
    },
    {
        title: 'White Plains (HPN)',
        from: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/from-White-Plains-New-York/HPN/',
            title: 'Flights From White Plains (HPN)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/white-plains-new-york-hpn-to-atlanta-georgia-atl/',
                    title: 'White Plains, NY (HPN) to Atlanta, GA (ATL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/white-plains-new-york-hpn-to-fort-lauderdale-florida-fll/',
                    title: 'White Plains, NY (HPN) to Fort Lauderdale, FL (FLL)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/white-plains-new-york-hpn-to-orlando-florida-mco/',
                    title: 'White Plains, NY (HPN) to Orlando, FL (MCO)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/white-plains-new-york-hpn-to-washington-dc-district-of-columbia-dca/',
                    title: 'White Plains, NY (HPN) to Washington, DC (DCA)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/white-plains-new-york-hpn-to-west-palm-beach-florida-pbi/',
                    title: 'White Plains, NY (HPN) to West Palm Beach, FL (PBI)'
                }
            ]
        },
        to: {
            url: 'https://www.airfarewatchdog.com/cheap-flights/to-White-Plains-New-York/HPN/',
            title: 'Flights To White Plains (HPN)',
            routes: [
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/atlanta-georgia-atl-to-white-plains-new-york-hpn/',
                    title: 'Atlanta, GA (ATL) to White Plains, NY (HPN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/chicago-illinois-ord-to-white-plains-new-york-hpn/',
                    title: 'Chicago, IL (ORD) to White Plains, NY (HPN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/fort-lauderdale-florida-fll-to-white-plains-new-york-hpn/',
                    title: 'Fort Lauderdale, FL (FLL) to White Plains, NY (HPN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/milwaukee-wisconsin-mke-to-white-plains-new-york-hpn/',
                    title: 'Milwaukee, WI (MKE) to White Plains, NY (HPN)'
                },
                {
                    url:
                        'https://www.airfarewatchdog.com/cheap-flights/orlando-florida-mco-to-white-plains-new-york-hpn/',
                    title: 'Orlando, FL (MCO) to White Plains, NY (HPN)'
                }
            ]
        }
    }
];
