import React from 'react';
import { SearchFormProps } from '../props';
import { Suggestion } from 'models/Suggestion';
import msgs from '../messages';
import Button from 'components/Button';

type SearchButtonSearchFormProps<T extends Suggestion> = Pick<SearchFormProps<T>, 'buttonText' | 'onSubmit'>;

interface SearchButtonProps<T extends Suggestion> extends SearchButtonSearchFormProps<T> {
    classNames?: string[];
    buttonTextSize?: string;
}

export default function SearchButton<T extends Suggestion>({
    classNames,
    buttonText,
    onSubmit,
    buttonTextSize
}: SearchButtonProps<T>) {
    return (
        <div className={`search-form__button ${classNames ? classNames.join(' ') : ''}`}>
            <Button text={buttonText || msgs.buttonText} onClick={onSubmit} textFontSize={buttonTextSize} />
        </div>
    );
}
