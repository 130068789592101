import { defineMessages } from 'react-intl';
import {
    MSG_SUBSCRIBE_DISCLAIMER,
    MSG_SUBSCRIPTION_INFO_HOVER_AIR,
    MSG_SUBSCRIPTION_INFO_HOVER_HOTEL
} from 'constants/sharedMessages';

export default defineMessages({
    buttonText: {
        id: 'SearchFormDateless_buttonText',
        defaultMessage: 'Show Deals'
    },
    location1Label: {
        id: 'SearchFormDateless_location1Label',
        defaultMessage: 'Traveling from'
    },
    location1Placeholder: {
        id: 'SearchFormDateless_location1Placeholder',
        defaultMessage: 'Enter City'
    },
    location2Label: {
        id: 'SearchFormDateless_location2Label',
        defaultMessage: 'Traveling to'
    },
    location2Placeholder: {
        id: 'SearchFormDateless_location2Placeholder',
        defaultMessage: 'Anywhere (optional)'
    },
    filtersLabel: {
        id: 'SearchFormDateless_filtersLabel',
        defaultMessage: 'Optional Filters'
    },
    subscriptionHeader: {
        id: 'SearchFormDateless_subscriptionHeader',
        defaultMessage: 'Send me deals on this route:'
    },
    subscriptionHeaderNoLocation: {
        id: 'SearchFormDateless_subscriptionHeaderNoLocation',
        defaultMessage: 'Send me great deals:'
    },
    subscriberCheckboxLabelNoLocation: {
        id: 'SearchFormDateless_subscriberCheckboxLabelNoLocation',
        defaultMessage: 'Flight deals, travel tips & news'
    },
    subscriptionHoverAir: {
        id: 'SearchFormDateless_subscriptionHoverAir',
        defaultMessage: MSG_SUBSCRIPTION_INFO_HOVER_AIR
    },
    subscriptionHoverHotel: {
        id: 'SearchFormDateless_subscriptionHoverHotel',
        defaultMessage: MSG_SUBSCRIPTION_INFO_HOVER_HOTEL
    },
    subscriberEmailPlaceholder: {
        id: 'SearchFormDateless_subscriberEmailPlaceholder',
        defaultMessage: 'Enter your email address'
    },
    subscribeDisclaimer: {
        id: 'SearchFormDateless_subscribeDisclaimer',
        defaultMessage: MSG_SUBSCRIBE_DISCLAIMER
    }
});
