import { useCallback, useEffect, useState } from 'react';

interface MediaQuery {
    minHeight?: number;
    minWidth?: number;
    maxHeight?: number;
    maxWidth?: number;
}

/**
 * Converts a {@link MediaQuery} object to a valid media query string
 *
 * @param query
 */
function getMediaQueryString({ minHeight, minWidth, maxHeight, maxWidth }: MediaQuery): string {
    const queryStrings = [];

    minHeight && queryStrings.push(`min-height: ${minHeight}px`);
    minWidth && queryStrings.push(`min-width: ${minWidth}px`);
    maxHeight && queryStrings.push(`max-height: ${maxHeight}px`);
    maxWidth && queryStrings.push(`max-width: ${maxWidth}px`);

    return `(${queryStrings.join(') and (')})`;
}

/**
 * Does the viewport currently match the provided query?
 * @param query
 */
function matchMedia(query: MediaQuery): MediaQueryList {
    return window.matchMedia(getMediaQueryString(query));
}

/**
 * Hook used to determine whether the viewport matches a provided media query.
 * A cleaned up and simplified version of https://github.com/lessmess-dev/react-media-hook/blob/master/index.js
 * which works better with our types and existing workflow
 *
 * @param query
 */
export default function useMedia(query: MediaQuery): boolean {
    const [result, setResult] = useState(() => matchMedia(query)),
        // We use a memoized callback below so that we have reference equality across renders which we need
        // to use the callback as a dependency for useEffect
        onQueryStatusChange = useCallback(matchMediaResult => setResult(matchMediaResult), [setResult]);

    // Effect to update the result var whenever the query or the query's result changes
    useEffect(() => {
        const matchMediaResult = matchMedia(query);

        onQueryStatusChange(matchMediaResult);
        matchMediaResult.addListener(onQueryStatusChange); // #addListener is deprecated but we need it for IE11

        return () => {
            matchMediaResult.removeListener(onQueryStatusChange);
        };
    }, [query, onQueryStatusChange]);

    return result.matches;
}
