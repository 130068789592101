import React from 'react';
import msgs from '../messages';
import { FormattedMessage } from 'react-intl';
import { Suggestion } from 'models/Suggestion';
import SearchData from 'models/SearchData';
import SearchType from 'models/SearchData/enums/SearchType';

interface SubscriptionHeaderProps<T extends Suggestion> {
    classNames?: string[];
    searchData: SearchData<T>;
}

export default function SubscriptionHeader<T extends Suggestion>({
    classNames,
    searchData
}: SubscriptionHeaderProps<T>) {
    return (
        <div className={`search-form__subscription-header ${classNames ? classNames.join(' ') : ''}`}>
            <FormattedMessage {...getSubscriptionHeader(searchData)} />
        </div>
    );
}

function getSubscriptionHeader<T extends Suggestion>(searchData: SearchData<T>) {
    if (searchData.searchType === SearchType.HOTEL) {
        if (searchData.location2) {
            return msgs.subscriptionHeaderHotelCity;
        }
        return msgs.subscriptionHeaderHotel;
    }
    if (searchData.location1 && searchData.location2) {
        return msgs.subscriptionHeaderRoute;
    }
    return msgs.subscriptionHeader;
}
