import 'react-dates/initialize';
import React from 'react';
import { DateRangePicker as DRP, FocusedInputShape } from 'react-dates';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';

import './styles.scss';
import { MomentDate } from 'models/Date';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import { withSharedMethods } from '../common/withSharedMethods';
import { InjectedDatePickerProps } from '../common/props';

interface DatePickerRangeProps extends InjectedDatePickerProps {
    dateFormat: string | (() => string);
    date1Placeholder: FormattedMessage.MessageDescriptor;
    date2Placeholder: FormattedMessage.MessageDescriptor;
    inError?: boolean;
    date1?: MomentDate;
    date1Id?: string; // This id is applied to the input.
    date2?: MomentDate;
    date2Id?: string; // This id is applied to the input.
    onDatesChange?: ({}: { date1: MomentDate; date2: MomentDate }) => void;
    numberOfMonths?: number;
    intl: InjectedIntl; // Prop automatically injected by injectIntl: https://github.com/yahoo/react-intl/wiki/API#injectintl
}

interface DatePickerRangeState {
    date1: MomentDate;
    date2: MomentDate;
    focusedInput: FocusedInputShape | null;
}

class DatePickerRange extends React.Component<DatePickerRangeProps, DatePickerRangeState> {
    public state: DatePickerRangeState = {
        date1: this.props.date1 || null,
        date2: this.props.date2 || null,
        focusedInput: null
    };

    public render() {
        const { date1, date2, focusedInput } = this.state,
            {
                date1Id,
                date1Placeholder,
                date2Id,
                date2Placeholder,
                dateFormat,
                isOutsideRange,
                minDate,
                maxDate,
                numberOfMonths,
                inError,
                intl
            } = this.props;
        return (
            <span className={`date-picker-range do_no_popunder ${inError === undefined || !inError ? '' : 'in-error'}`}>
                <DRP
                    // Required props:
                    startDate={date1}
                    startDateId={date1Id || 'DPR-start'}
                    startDatePlaceholderText={intl.formatMessage(date1Placeholder)}
                    endDate={date2}
                    endDateId={date2Id || 'DPR-end'}
                    endDatePlaceholderText={intl.formatMessage(date2Placeholder)}
                    focusedInput={focusedInput}
                    onDatesChange={this.onDatesChange}
                    onFocusChange={this.onFocusChange}
                    // Input related props:
                    readOnly={true}
                    customInputIcon={<AwdIcon name={AwdIconName.Calendar} />}
                    // calendar presentation and interaction related props:
                    numberOfMonths={numberOfMonths || 1}
                    hideKeyboardShortcutsPanel={true}
                    // Day presentation and interaction related props:
                    isOutsideRange={isOutsideRange}
                    minDate={minDate}
                    maxDate={maxDate}
                    // Internationalization props:
                    displayFormat={dateFormat}
                />
            </span>
        );
    }

    private onDatesChange = ({ startDate, endDate }: { startDate: MomentDate; endDate: MomentDate }) => {
        const dates = { date1: startDate, date2: endDate };
        this.setState(dates);
        this.props.onDatesChange && this.props.onDatesChange(dates);
    };

    private onFocusChange = (focusedInput: FocusedInputShape | null) => {
        this.setState({ focusedInput });
    };
}

export default withSharedMethods(injectIntl(DatePickerRange));
