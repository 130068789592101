import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import './styles.scss';
import msgs from './messages';
import { HomeStoreState } from 'apps/home/globals';
import { isAwdUS } from 'ducks/config';
import { ConnectedAirSearchFormResponsive } from 'components/SearchForm/connectedAir';
import { ConnectedHotelSearchFormResponsive } from 'components/SearchForm/connectedHotel';
import { SearchFormStyle } from 'components/SearchForm/common/props';
import ConnectedSearchFormDateless from 'components/SearchFormDateless/connected';
import { SearchFormDatelessLayout } from 'components/SearchFormDateless';
import LinksListThroughPortal from 'components/LinksList';
import { mediaGreaterThanSm, mediaPhone, mediaXs } from 'constants/mediaQueries';
import GptLegacyAd from 'components/GptLegacyAd';
import { GptAdPosition, GptAdSize } from 'models/GPT';
import ConversationBubble, {
    ConversationBubbleSize,
    ConversationBubbleTailPosition
} from 'components/ConversationBubble';
import usePageRenderedCallbacks from 'hooks/usePageRenderedCallbacks';
import { Fare } from 'models/Fares';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import WeekenderRailCard from 'components/RailCard/Weekender';
import TwitterRailCard from 'components/RailCard/Twitter';
import SearchData from 'models/SearchData';
import { AirportSuggestion, Suggestion, SuggestionDecorator } from 'models/Suggestion';
import { getAirSearchData, StoreWithAirSearchDataState, updateAirSearchData } from 'ducks/airSearchData';
import { InlineInputWithAutocomplete } from 'components/InlineInput';
import { getAirportSuggestions, LocationSuggestType } from 'api/locationSuggest';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
    getFareTickerFares,
    isFareTickerInError,
    isFareTickerShowingFallbackFares,
    StoreWithFareTickerState
} from 'ducks/fareTicker';
import FareTicker from 'components/FareTicker';
import { PATH_TOP_50_FARES } from 'constants/paths';
import { buildFareListingUrl } from 'utils/urls';
import FareAction from 'models/Fares/FareAction';
import { CurrencyCode } from 'models/Currency';
import { getCurrency } from 'ducks/user';
import { SearchMode } from 'models/SearchMode';
import SearchModeToggles from 'components/SearchModeToggles';
import { useHeroImageUrl } from 'hooks/useHeroImageUrl';
import { HeroImageSize } from 'models/HeroImages';
import useMedia from 'hooks/useMedia';

interface HomePageProps {
    airSearchData: SearchData<AirportSuggestion>;
    fareTickerFares: Fare[];
    currency: CurrencyCode;
    getAirLocationSuggestions: (query: string) => Promise<Array<SuggestionDecorator<AirportSuggestion>>>;
    onTopFareLocationChanged: (suggestion: SuggestionDecorator<Suggestion>) => void;
    isAwdUS: boolean;
    isExactDateSearch: boolean;
    isFareTickerInError: boolean;
    hasFareTickerFallbackFares: boolean;
}

function HomePage({
    isExactDateSearch,
    fareTickerFares,
    getAirLocationSuggestions,
    isFareTickerInError,
    hasFareTickerFallbackFares,
    onTopFareLocationChanged,
    airSearchData,
    currency
}: HomePageProps) {
    const { location1 } = airSearchData,
        [searchMode, setSearchMode] = useState(SearchMode.Air),
        isAirSearchMode = searchMode === SearchMode.Air,
        isPhone = useMedia(mediaPhone),
        isXs = useMedia(mediaXs),
        isGreaterThanSm = useMedia(mediaGreaterThanSm),
        imgSize = isPhone ? HeroImageSize.MEDIUM_TALL : isXs ? HeroImageSize.MEDIUM_WIDE : HeroImageSize.LARGE_WIDE,
        heroImg = useHeroImageUrl({ size: imgSize });

    usePageRenderedCallbacks();

    return (
        <div className='home-page'>
            <div className='home-page__hero'>
                <div className='home-page__hero-img' style={{ backgroundImage: `url(${heroImg})` }}>
                    <div className='home-page__hero-img__gradient' />
                </div>

                {isGreaterThanSm && <PageHeader />}
                <div className={'home-page__toggles'}>
                    <SearchModeToggles activeSearchMode={searchMode} onToggleClicked={setSearchMode} />
                </div>
                {!isGreaterThanSm && <PageHeader />}

                <div
                    className={`home-page__search-widget ${
                        isExactDateSearch ? 'home-page__search-widget--exact-date' : ''
                    }`}
                >
                    {isExactDateSearch && isAirSearchMode && (
                        <ConnectedAirSearchFormResponsive
                            style={SearchFormStyle.WHITE}
                            insetLabels={false}
                            expandedEmailField={false}
                            buttonText={msgs.exactDateButtonText}
                            location1Label={msgs.exactDateLocation1Label}
                            location2Label={msgs.exactDateLocation2Label}
                            datesLabel={msgs.exactDateDatesLabel}
                            emailFieldPlaceholder={msgs.exactDateEmailFieldPlaceholder}
                            redirectOnSubmit={true}
                        />
                    )}
                    {!isExactDateSearch && isAirSearchMode && (
                        <ConnectedSearchFormDateless
                            layout={isXs ? SearchFormDatelessLayout.VERTICAL : SearchFormDatelessLayout.WIDE}
                        />
                    )}
                    {!isAirSearchMode && (
                        <ConnectedHotelSearchFormResponsive
                            style={SearchFormStyle.WHITE}
                            insetLabels={false}
                            expandedEmailField={false}
                            buttonText={msgs.exactDateButtonText}
                            location2Label={msgs.exactDateLocation2Label}
                            datesLabel={msgs.exactDateDatesLabel}
                            emailFieldPlaceholder={msgs.exactDateEmailFieldPlaceholder}
                            subscriptionFieldsAfterButton={false}
                        />
                    )}
                </div>
            </div>
            <div className='home-page__top-ad container'>
                {/* GPT visibility is controlled by CSS and not React Media component b/c the Flight code
                 * only attaches once on page load */}
                <div className='visible-xs visible-sm visible-md'>
                    <GptLegacyAd sizes={[GptAdSize.MPU]} position={GptAdPosition.ABOVE_FOLD} />
                </div>
            </div>
            <div className='home-page__fares-container container'>
                {isGreaterThanSm && (
                    <div className='home-page-fares__cta'>
                        <FormattedMessage {...msgs.topFaresCTA} />
                    </div>
                )}
                <div className='home-page-fares__header'>
                    <div className='home-page-fares__header-tooltip'>
                        <ConversationBubble
                            message={msgs.topFaresHeaderTooltip}
                            tailPosition={ConversationBubbleTailPosition.BOTTOM_LEFT}
                            size={ConversationBubbleSize.LARGE}
                        />
                    </div>
                    <div className='home-page-fares__header-line1'>
                        <FormattedMessage {...msgs.topFaresHeaderLine1} />
                    </div>
                    <div className='home-page-fares__header-line2'>
                        <FormattedMessage {...msgs.topFaresHeaderLine2} />
                        <div className='home-page-fares__header-input'>
                            <InlineInputWithAutocomplete
                                iconName={AwdIconName.Pin}
                                initialValue={location1 && location1.text}
                                placeholder={msgs.topFaresLocationPlaceholder}
                                getSuggestions={getAirLocationSuggestions}
                                onSuggestionSelected={onTopFareLocationChanged}
                                key={location1 && location1.text}
                            />
                        </div>
                    </div>
                </div>
                <FareTicker
                    fares={fareTickerFares}
                    beforeGrid={renderFareTickerErrorOrFallbackMsg(isFareTickerInError || hasFareTickerFallbackFares)}
                    additionalGridItems={getFareTickerAds()}
                    currency={currency}
                />
                <div className='home-page-fares__see-all'>
                    {location1 ? (
                        <a
                            href={buildFareListingUrl({
                                fareAction: FareAction.From,
                                airport: location1.suggestion
                            })}
                            target='_blank'
                            rel='noopener'
                            className='home-page-fares__see-all__link'
                        >
                            <span>
                                <FormattedMessage {...msgs.seeAllFaresFrom} values={{ locationName: location1.text }} />
                                <span>{location1.text}</span>
                            </span>
                            <AwdIcon name={AwdIconName.ArrowRight} />
                        </a>
                    ) : (
                        <a
                            href={PATH_TOP_50_FARES}
                            target='_blank'
                            rel='noopener'
                            className='home-page-fares__see-all__link'
                        >
                            <FormattedMessage {...msgs.seeAllFares} />
                            <AwdIcon name={AwdIconName.ArrowRight} />
                        </a>
                    )}
                </div>
            </div>
            {!isXs && (
                <div className='home-page__rail-cards container'>
                    <div className='home-page__rail-card'>
                        <WeekenderRailCard />
                    </div>
                    <div className='home-page__rail-card'>
                        <TwitterRailCard />
                    </div>
                </div>
            )}
            <div className='home-page__middle-ad container'>
                <div className='visible-lg'>
                    <GptLegacyAd
                        sizes={[GptAdSize.RISING_STAR, GptAdSize.LEADERBOARD]}
                        position={GptAdPosition.BELOW_FOLD}
                    />
                </div>
                <div className='visible-md visible-sm'>
                    <GptLegacyAd sizes={[GptAdSize.LEADERBOARD]} position={GptAdPosition.BELOW_FOLD} />
                </div>
                <div className='visible-xs'>
                    <GptLegacyAd sizes={[GptAdSize.MPU]} position={GptAdPosition.BELOW_FOLD} />
                </div>
            </div>
            <LinksListThroughPortal />
        </div>
    );
}

function PageHeader() {
    const isXs = useMedia(mediaXs),
        isGreaterThanSm = useMedia(mediaGreaterThanSm);
    return (
        <div className='home-page__header'>
            <h1 className='home-page__header-line1'>
                <FormattedMessage {...(isXs ? msgs.headerMobile : msgs.headerLine1)} />{' '}
            </h1>
            {isGreaterThanSm && (
                <div className='home-page__header-line2'>
                    <FormattedMessage {...msgs.headerLine2} />
                </div>
            )}
        </div>
    );
}

const renderFareTickerErrorOrFallbackMsg = (showMessage: boolean) => {
    if (!showMessage) {
        return;
    }
    return (
        <div className='fare-ticker__no-fares'>
            <span className='fare-ticker__no-fares-msg'>
                <FormattedMessage {...msgs.noFaresLine1} />
            </span>
            <span className='fare-ticker__no-fares-msg'>
                <FormattedMessage {...msgs.noFaresLine2} />
            </span>
        </div>
    );
};

const getFareTickerAds = () => {
    return [
        {
            element: <GptLegacyAd sizes={[GptAdSize.MPU]} position={GptAdPosition.BELOW_FOLD} />,
            reactKey: 'ad-slot-1',
            flexOrder: 1,
            className: 'fare-ticker__ad'
        },
        {
            element: <GptLegacyAd sizes={[GptAdSize.MPU]} position={GptAdPosition.BELOW_FOLD} />,
            reactKey: 'ad-slot-2',
            flexOrder: 4,
            className: 'fare-ticker__ad'
        }
    ];
};

const mapStateToProps = (state: HomeStoreState) => ({
    airSearchData: getAirSearchData(state),
    fareTickerFares: getFareTickerFares(state),
    getAirLocationSuggestions: async (query: string) => {
        return getAirportSuggestions({ query, searchType: LocationSuggestType.AIRPORTS_DOMESTIC });
    },
    isAwdUS: isAwdUS(state),
    isFareTickerInError: isFareTickerInError(state),
    hasFareTickerFallbackFares: isFareTickerShowingFallbackFares(state),
    currency: getCurrency(state)
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreWithAirSearchDataState & StoreWithFareTickerState, void, AnyAction>
) => ({
    onTopFareLocationChanged: (suggestion: SuggestionDecorator<Suggestion>) => {
        dispatch(updateAirSearchData({ location1: suggestion as SuggestionDecorator<AirportSuggestion> }));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
