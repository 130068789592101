import { defineMessages } from 'react-intl';

export default defineMessages({
    dateRange: {
        id: 'FareView_dateRange',
        defaultMessage: '{date1} - {date2}'
    },
    dateInfoFlexible: {
        id: 'FareView_dateInfoFlexible',
        defaultMessage: 'Travel Range: {date1} - {date2}'
    },
    dateInfoFlexibleDate1Only: {
        id: 'FareView_dateInfoFlexibleDate1Only',
        defaultMessage: 'Travel Starting: {date1}'
    },
    dateInfoFlexibleDate2Only: {
        id: 'FareView_dateInfoFlexibleDate2Only',
        defaultMessage: 'Travel Ending: {date2}'
    },
    dateInfoFlexibleVaries: {
        id: 'FareView_dateInfoFlexibleVaries',
        defaultMessage: 'Year Round Travel'
    },
    fareInstanceSelectorText: {
        id: 'FareView_fareInstanceSelectorText',
        defaultMessage: 'More dates for this deal'
    },
    fareMultiDateDefaultText: {
        id: 'FareView_fareMultiDateDefaultText',
        defaultMessage: 'Best Times to Go'
    }
});
