import * as React from 'react';
import moment from 'moment';
import { Subtract } from 'utility-types';
import { InjectedDatePickerProps } from './props';

/**
 * Function returns a higher order datepicker component (single or date range)
 * with the shared methods defined below injected into it
 *
 * @param WrappedComponent
 */
export const withSharedMethods = <WrappedProps extends InjectedDatePickerProps>(
    WrappedComponent: React.ComponentType<WrappedProps>
) => {
    // Subtract from the wrapped props the props that are needed only by this HOC factory function
    type HocProps = Subtract<WrappedProps, InjectedDatePickerProps>;

    return class WithSharedMethods extends React.Component<HocProps> {
        public static readonly displayName = `withSharedMethods(${WrappedComponent.name})`;
        private readonly minDate: moment.Moment = moment();
        private readonly maxDate = moment().add(1, 'year');

        public render() {
            const allProps = Object.assign({}, this.props, {
                isOutsideRange: this.isOutsideRange,
                minDate: this.minDate,
                maxDate: this.maxDate
            }) as WrappedProps;

            return <WrappedComponent {...allProps} />;
        }

        private isOutsideRange = (date: moment.Moment) => {
            return date.isBefore(this.minDate) || date.isAfter(this.maxDate);
        };
    };
};
