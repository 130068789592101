import React from 'react';

import './styles.scss';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';

interface FilterButtonProps {
    isFilterSet: boolean;
    text: string;
    onClearFilter(): void;
    onClick(): void;
}

export default class FilterButton extends React.Component<FilterButtonProps> {
    public render = () => {
        const { isFilterSet, onClick, text } = this.props;
        return (
            <div className={`filter-button ${isFilterSet ? 'filter-button--set' : ''}`} onClick={onClick}>
                <span className='filter-button__label'>{text}</span>
                {isFilterSet ? (
                    <span className='filter-button__icon' onClick={this.clearFilter}>
                        <AwdIcon name={AwdIconName.ExCircle} />
                    </span>
                ) : (
                    <span className='filter-button__icon'>
                        <AwdIcon name={AwdIconName.ArrowDown} />
                    </span>
                )}
            </div>
        );
    };

    private clearFilter = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.props.onClearFilter();
    };
}
