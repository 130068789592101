import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import './styles.scss';
import msgs from '../common/messages';
import { SearchType } from 'models/SearchData';
import { Suggestion } from 'models/Suggestion';
import { SearchFormProps } from '../common/props';
import { SearchFormField } from '../common/fields';
import {
    SearchFormOptionsRow,
    SearchFormLocationField,
    SearchFormDatesField,
    SearchFormSubscriptionHeader,
    SearchFormEmailField,
    SearchFormSearchButton,
    SearchFormSubscriberCheckbox
} from '../common/components';

/**
 * Search form specifically made for narrow contexts, like mobile or a right rail.
 */
export default class SearchFormVertical<T extends Suggestion> extends React.Component<SearchFormProps<T>> {
    public render() {
        const {
                getLocationSuggestions,
                getOnLocationChangeHandler,
                header,
                useShortFlightTypeLabels,
                subscriber,
                onClick,
                onDateChange,
                onDatesChange,
                onFlightTypeChange,
                onTravelersChange,
                onSubscribeUserChange,
                onEmailBlur,
                onSubmit,
                searchData,
                fieldsInError,
                subscribeUser,
                isCompact,
                isEmailFieldVisible,
                straightCorners,
                innerRef,
                getOnLocationBlurHandler,
                style,
                buttonText,
                buttonTextSize,
                location1Label,
                location2Label,
                datesLabel,
                subscriberCheckboxLabel,
                emailFieldPlaceholder,
                hideTopRowOptions,
                insetLabels,
                subscriptionFieldsAfterButton,
                location1Placeholder,
                location2Placeholder
            } = this.props,
            { location1, location2, searchType } = searchData,
            isAirSearch = searchType === SearchType.AIR;

        return (
            <div
                ref={innerRef}
                onClick={onClick}
                className={`search-form ${
                    straightCorners ? 'search-form__straight-corners' : ''
                } search-form--${searchType} search-form--${style} SFV`}
            >
                <div className='search-form__content'>
                    <div className='search-form__top-row SFV__top-row'>
                        {header && (
                            <div className='search-form__header SFV__header'>
                                <FormattedMessage {...header} />
                            </div>
                        )}

                        {isAirSearch && !hideTopRowOptions && (
                            <SearchFormOptionsRow
                                {...{
                                    onFlightTypeChange,
                                    onTravelersChange,
                                    searchData,
                                    style,
                                    useShortFlightTypeLabels
                                }}
                            />
                        )}
                    </div>

                    <div className='search-form__fields SFV__fields'>
                        {isAirSearch && (
                            <SearchFormLocationField
                                searchFormField={SearchFormField.LOCATION1}
                                location={location1}
                                label={location1Label}
                                placeholder={location1Placeholder}
                                containerClassNames={['SFV__field-container', 'SFV__location-container']}
                                fieldClassNames={['SFV__field']}
                                {...{
                                    insetLabels,
                                    getLocationSuggestions,
                                    getOnLocationBlurHandler,
                                    getOnLocationChangeHandler,
                                    fieldsInError
                                }}
                            />
                        )}
                        {!isCompact && (
                            <React.Fragment>
                                <SearchFormLocationField
                                    searchFormField={SearchFormField.LOCATION2}
                                    location={location2}
                                    label={location2Label}
                                    placeholder={location2Placeholder}
                                    containerClassNames={['SFV__field-container', 'SFV__location-container']}
                                    fieldClassNames={['SFV__field']}
                                    {...{
                                        insetLabels,
                                        getLocationSuggestions,
                                        getOnLocationBlurHandler,
                                        getOnLocationChangeHandler,
                                        fieldsInError
                                    }}
                                />

                                <SearchFormDatesField
                                    containerClassNames={['SFV__field-container']}
                                    fieldClassNames={['SFV__field', 'SFV__dates']}
                                    {...{
                                        datesLabel,
                                        fieldsInError,
                                        insetLabels,
                                        onDateChange,
                                        onDatesChange,
                                        searchData
                                    }}
                                />

                                {subscriptionFieldsAfterButton && (
                                    <SearchFormSearchButton
                                        onSubmit={onSubmit}
                                        buttonText={buttonText}
                                        buttonTextSize={buttonTextSize}
                                        classNames={['SFV__button']}
                                    />
                                )}

                                <SearchFormSubscriptionHeader
                                    searchData={searchData}
                                    classNames={['SFV__subscription-header']}
                                />

                                <SearchFormSubscriberCheckbox
                                    classNames={['SFV__subscriber-checkbox']}
                                    {...{
                                        subscribeUser,
                                        onSubscribeUserChange,
                                        searchData,
                                        style,
                                        subscriberCheckboxLabel
                                    }}
                                />
                                {isEmailFieldVisible && (
                                    <React.Fragment>
                                        <SearchFormEmailField
                                            containerClassNames={[
                                                'search-form__email-container',
                                                'SFV__field-container',
                                                'SFV__email-container'
                                            ]}
                                            fieldClassNames={['SFV__field']}
                                            {...{ subscriber, emailFieldPlaceholder, onEmailBlur }}
                                        />
                                        <div className='search-form__subscriber-disclaimer'>
                                            <FormattedHTMLMessage {...msgs.subscribeDisclaimer} />
                                        </div>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </div>

                    {!subscriptionFieldsAfterButton && (
                        <SearchFormSearchButton
                            onSubmit={onSubmit}
                            buttonText={buttonText}
                            classNames={['SFV__button']}
                        />
                    )}
                </div>
            </div>
        );
    }
}
