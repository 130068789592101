import { defineMessages } from 'react-intl';

export default defineMessages({
    flights: {
        id: 'SearchModeToggles_flights',
        defaultMessage: 'Flights'
    },
    hotels: {
        id: 'SearchModeToggles_hotels',
        defaultMessage: 'Hotels'
    }
});
