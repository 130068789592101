import SearchData from 'models/SearchData';
import { isAirportSuggestion, Suggestion } from 'models/Suggestion';
import { setCookie } from 'utils/cookies';
import CookieSearchData, { CookieLocation, CookieLocationType } from 'models/SearchData/Cookie';
import { CookieNames } from 'constants/cookies';

const COOKIE_EXPIRE_DAYS = 7;

/**
 * Converts a native Date object to the Unix timestamp (seconds) format we use in the cookie
 *
 * @param date
 */
function convertToCookieDate(date: Date): number {
    return date.getTime() / 1000;
}

/**
 * Converts a location suggestion to a CookieLocation
 *
 * @param suggestion
 */
function convertToCookieLocation<T extends Suggestion>(suggestion: T): CookieLocation {
    if (isAirportSuggestion(suggestion)) {
        return { type: CookieLocationType.AIRPORT, id: suggestion.id, aid: suggestion.id, cid: suggestion.parentId };
    }

    return { type: CookieLocationType.GEO, id: suggestion.id, cid: suggestion.id };
}

/**
 * Translates SearchData object to the format in which we store search data in the cookie
 *
 * @param data
 */
function convertToCookieSearchData(data: SearchData<Suggestion>): CookieSearchData {
    const { location1, location2, date1, date2, nonStop, travelers } = data,
        cookieData: CookieSearchData = {};

    // Once a user interacts with the widget we declaring the dates to no longer be
    // defaults. Even if they simply update a location and hit "search" it's fair to
    // consider these to be user-curated dates at that point.
    cookieData.defaultDates = false;
    date1 && (cookieData.date1 = convertToCookieDate(date1.toDate()));
    date2 && (cookieData.date2 = convertToCookieDate(date2.toDate()));
    location1 && (cookieData.location1 = convertToCookieLocation(location1.suggestion));
    location2 && (cookieData.location2 = convertToCookieLocation(location2.suggestion));
    nonStop !== undefined && (cookieData.oneway = nonStop ? 1 : 0);
    travelers !== undefined && (cookieData.travelers = travelers);

    return cookieData;
}

/**
 * Stores the provided SearchData in the cookie we pass to the server to
 * keep track of users' searches
 *
 * @param data
 */
export function setSearchDataCookie(data: SearchData<Suggestion>) {
    setCookie({
        name: CookieNames.CS,
        value: JSON.stringify(convertToCookieSearchData(data)),
        expireInDays: COOKIE_EXPIRE_DAYS
    });
}
