import React from 'react';

import './styles.scss';
import { Suggestion } from 'models/Suggestion';
import { SearchFormProps } from '../common/props';
import SearchFormWide from '../Wide';

/**
 * Sticky search form, that will show up at the top of the page
 */
export default class SearchFormSticky<T extends Suggestion> extends React.Component<SearchFormProps<T>> {
    public render() {
        return (
            <div className='search-form-sticky'>
                <SearchFormWide {...this.props} straightCorners={true} />
            </div>
        );
    }
}
