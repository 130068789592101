import React from 'react';
import { FormattedMessage } from 'react-intl';
import './styles';
import { SearchMode } from 'models/SearchMode';
import msgs from './messages';

function getSearchModeMsg(searchMode: SearchMode) {
    switch (searchMode) {
        case SearchMode.Air:
            return msgs.flights;
        case SearchMode.Hotel:
            return msgs.hotels;
    }
}

export default function SearchModeToggles({
    activeSearchMode,
    onToggleClicked
}: {
    activeSearchMode: SearchMode;
    onToggleClicked: (vertical: SearchMode) => void;
}) {
    return (
        <div className='search-mode-toggles'>
            {[SearchMode.Air].map((v, i) => {
                const classNames = [
                    'search-mode-toggles__toggle',
                    `search-mode-toggles__toggle--${v}`,
                    'do_no_popunder'
                ];

                v === activeSearchMode && classNames.push('search-mode-toggles__toggle--active');

                return (
                    <span
                        key={`search-mode-toggle-${i}`}
                        className={classNames.join(' ')}
                        onClick={() => onToggleClicked(v)}
                    >
                        <FormattedMessage {...getSearchModeMsg(v)} />
                    </span>
                );
            })}
        </div>
    );
}
