import { defineMessages } from 'react-intl';

export default defineMessages({
    duration: {
        id: 'FareDuration',
        defaultMessage: `{duration, plural,
            =0 {Same-Day}
            other {{duration}-Day}
        } Trip`
    }
});
