import emailValidator from 'email-validator';
import { Subscriber } from 'models/Subscriptions';

export function isValidEmail({ email }: { email: string }): boolean {
    return emailValidator.validate(email);
}

export function isValidSubscriber({ subscriber }: { subscriber?: Subscriber | null }): boolean {
    return !!(subscriber && subscriber.emailAddress && isValidEmail({ email: subscriber.emailAddress }));
}
