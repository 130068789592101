import { buildQueryParamString } from 'utils/urls';
import FareTickerRequestError from 'models/Errors/FareTickerRequestError';
import { Fare } from 'models/Fares';
import { IncomingDisplayFaresWrapper } from 'models/incomingData/Fares';
import { translateDisplayFare } from 'utils/fares/translators';

const FARE_TICKER_DEFAULT_ENDPOINT = '/fareTicker/fares/';
const FARE_TICKER_BY_CODE_ENDPOINT = '/fareTicker/fares/byCode/';

export async function getFareTickerDefaultFares(maxFares: number): Promise<Fare[]> {
    const queryString = buildQueryParamString({ maxDisplayFares: maxFares }),
        res = await fetch(FARE_TICKER_DEFAULT_ENDPOINT + queryString);

    if (!res.ok) {
        throw new FareTickerRequestError(`Failed to fetch fare ticker fares`);
    }

    const incomingFares: IncomingDisplayFaresWrapper[] = (await res.json()).success;
    return incomingFares.map(translateDisplayFare);
}

export async function getFareTickerFaresByAirportCode({
    airportCode,
    maxFares
}: {
    airportCode: string;
    maxFares: number;
}): Promise<Fare[]> {
    const queryString = buildQueryParamString({ maxDisplayFares: maxFares }),
        res = await fetch(`${FARE_TICKER_BY_CODE_ENDPOINT}${airportCode}/${queryString}`);

    if (!res.ok) {
        throw new FareTickerRequestError(`Failed to fetch fare ticker fares for requested airport code`);
    }

    const incomingFares: IncomingDisplayFaresWrapper[] = (await res.json()).success;
    return incomingFares.map(translateDisplayFare);
}
