import { AwdWindow } from 'models/AwdWindow';

declare const window: AwdWindow;

/**
 * Executes all callbacks already added to page rendered callbacks queue
 * and overrides the push method so that all subsequent callbacks are
 * executed immediately
 */
export function executePageRenderedCallbacks() {
    // If the global variable hasn't yet been set, then do so:
    window.pageRenderedCallbacks = window.pageRenderedCallbacks || [];

    const queue = [...window.pageRenderedCallbacks];

    // Execute all callbacks that have already been added to the queue
    queue.forEach(fn => fn());

    // Override push method so that any callbacks added subsequently are immediately executed
    window.pageRenderedCallbacks.push = fn => {
        fn();
        return 0; // Array.prototype.push must return the length of the resulting array, which should now always be 0
    };
}
