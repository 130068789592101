import React from 'react';
import { FormattedMessage } from 'react-intl';
import msgs from './messages';
import { FareInstance } from 'models/Fares';

export default function FareDuration({ fareInstance }: { fareInstance: FareInstance }) {
    const { departureDate, returnDate } = fareInstance,
        duration = departureDate && returnDate && returnDate.diff(departureDate, 'day');

    return <FormattedMessage {...msgs.duration} values={{ duration }} />;
}
