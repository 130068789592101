import { useReducer } from 'react';
import { FareInstance } from 'models/Fares';
import fareInstanceSelectorReducer, {
    FareInstanceSelectorDispatch,
    FareInstanceSelectorState
} from '../utils/fareInstanceSelectorReducer';

export default function useFareInstanceSelectorReducer(
    initialSelectedFareInstance: FareInstance
): [FareInstanceSelectorState, FareInstanceSelectorDispatch] {
    return useReducer(fareInstanceSelectorReducer, {
        selectorVisible: false,
        selectedFareInstance: initialSelectedFareInstance,
        isUserSelectedFareInstance: false
    });
}
