import { useRef } from 'react';
import { HeroImageSize } from 'models/HeroImages';

const HERO_IMAGE_IDS = [
    999676364,
    909195692,
    816763072,
    896976060,
    804131306,
    668770734,
    619530818,
    813974822,
    467551504,
    543208192,
    1033053000,
    143175936,
    940079550,
    927410780,
    659437730,
    891451752,
    613888222,
    868491300,
    154959807,
    540501828,
    494851054,
    860419888,
    178896691,
    584744762,
    909879530,
    853423076,
    688566394
];

/**
 * Returns a URL for a randomly selected image stored in the S3 bucket where we initially stored all of our
 * hero and categories images . Note that we use refs so that the* hero image won't change on different re-renders
 *
 * @param size
 */
export function useHeroImageUrl({ size }: { size: HeroImageSize }) {
    const imageId = useRef(HERO_IMAGE_IDS[Math.floor(Math.random() * HERO_IMAGE_IDS.length)]);

    return `https://static.airfarewatchdog.com/img/fare-svc/${size}${imageId.current}.jpg`;
}
