import React from 'react';

import { Fare } from 'models/Fares';
import './styles.scss';
import { mediaLessThanLg } from 'constants/mediaQueries';
import FareCol from 'components/FareView/Column';
import FareCard from 'components/FareView/Card';
import Media from 'react-media';
import { CurrencyCode } from 'models/Currency';

export enum FareTickerLayout {
    AUTO = 'auto', // switches between grid and scroll depending on screen width
    GRID = 'grid',
    SCROLL = 'scroll'
}
// Note: the grid layout won't really work as-is on small screens because the "fare modal" with full fare details
// is shown on hover. If we ever want to use grid mode for small screens we'll have to revisit this.

interface FareTickerProps {
    fares: Fare[];
    currency: CurrencyCode;
    layout?: FareTickerLayout;
    beforeGrid?: JSX.Element;
    additionalGridItems?: AdditionalFareTickerGridItem[];
}

// exported for storybook
export interface AdditionalFareTickerGridItem {
    element: JSX.Element;
    reactKey?: string;
    flexOrder?: number;
    className?: string;
}

export default function FareTicker({
    fares,
    layout = FareTickerLayout.AUTO,
    beforeGrid,
    currency,
    additionalGridItems
}: FareTickerProps) {
    return (
        <div className='fare-ticker'>
            {beforeGrid}
            <div className={`fare-ticker__fares fare-ticker__fares--${layout}`}>
                {fares.map((fare, index) => (
                    <Media queries={{ lessThanLg: mediaLessThanLg }} key={fare.id}>
                        {matches => {
                            const lessThanLgAutoLayout = matches.lessThanLg && layout === FareTickerLayout.AUTO;

                            if (lessThanLgAutoLayout || layout === FareTickerLayout.SCROLL) {
                                return (
                                    <div className='fare-ticker__card fare-ticker__fare' style={{ order: index }}>
                                        <FareCol fare={fare} currency={currency} />
                                    </div>
                                );
                            }
                            return (
                                <div className='fare-ticker__card fare-ticker__fare' style={{ order: index }}>
                                    <FareCard fare={fare} withBorder={false} currency={currency} />
                                    <div className='fare-ticker__fare-modal'>
                                        <FareCol fare={fare} currency={currency} />
                                    </div>
                                </div>
                            );
                        }}
                    </Media>
                ))}
                {additionalGridItems &&
                    additionalGridItems
                        .filter(({ flexOrder }) => flexOrder && flexOrder < fares.length)
                        .map(({ element, reactKey, flexOrder, className }) => (
                            <div
                                className={`fare-ticker__card ${className || ''}`}
                                key={reactKey}
                                style={flexOrder ? { order: flexOrder } : {}}
                            >
                                {element}
                            </div>
                        ))}
            </div>
        </div>
    );
}
