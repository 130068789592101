import React, { useState } from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line no-restricted-imports
import chunk from 'lodash/chunk';

import { linksListData, RouteStaticData, RoutesStaticData, CityStaticData } from './linksListData';
import './styles.scss';

function ToggleTitleDiv({ title, children }: { title: string; children: React.ReactNode }) {
    const [toggle, setToggle] = useState(false);

    return (
        <div onClick={() => setToggle(t => !t)}>
            <div className='destination-block'>
                <p>
                    <b>{title}</b>
                </p>
                <div
                    className='icon-downarrow'
                    style={toggle ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}
                />
            </div>
            <div style={{ display: toggle ? 'block' : 'none' }}>{children}</div>
            <hr />
        </div>
    );
}

function RoutesSection({ routesData }: { routesData: RoutesStaticData }) {
    return (
        <>
            <h5>
                <a href={routesData.url}> {routesData.title} </a>
            </h5>
            <ul>
                {routesData.routes.map((route: RouteStaticData) => (
                    <li key={route.title} style={{ listStyleType: 'none' }}>
                        <a href={route.url}> {route.title} </a>
                    </li>
                ))}
            </ul>
        </>
    );
}

function RouteColumns({ children }: { children: React.ReactNode }) {
    return <div className='col-md-4'>{children}</div>;
}

function LinksList() {
    const chunkedData = chunk(linksListData, 20);

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <h2 className='links-list-header'> Compare Flight Deals for Top Destinations </h2>
                    <br />
                    {chunkedData.map((listChunk: CityStaticData[], index: number) => (
                        <RouteColumns key={index}>
                            {listChunk.map((city: CityStaticData) => (
                                <ToggleTitleDiv title={city.title} key={city.title}>
                                    <RoutesSection routesData={city.from} />
                                    <RoutesSection routesData={city.to} />
                                </ToggleTitleDiv>
                            ))}
                        </RouteColumns>
                    ))}
                </div>
            </div>
        </>
    );
}

function LinksListThroughPortal() {
    const container = document.getElementById('links-list-portal');

    if (container) {
        return ReactDOM.createPortal(<LinksList />, container);
    }

    return null;
}

export default LinksListThroughPortal;
