import React from 'react';

import { AwdIconName } from 'components/AwdIcon';
import { PATH_WEEKENDER } from 'constants/paths';
import RailCard from '../index';
import msgs from './messages';

export default function WeekenderRailCard() {
    return (
        <RailCard
            iconName={AwdIconName.PlaneLineDrawn}
            title={msgs.weekenderCardTitle}
            description={msgs.weekenderCardDescription}
            buttonText={msgs.weekenderCardButton}
            linkUrl={PATH_WEEKENDER}
        />
    );
}
