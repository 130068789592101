/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awd-fare-service-types/browse/src/main/java/com/smartertravel/awd/faresvc/types/DeltaWindow.java
 */
export enum FarePriceDeltaWindow {
    YOY = 'yoy',
    MOM = 'mom',
    WOW = 'wow'
}

/**
 * @see https://stash.st.cognius.net/projects/AWD/repos/awd-fare-service-types/browse/src/main/java/com/smartertravel/awd/faresvc/types/FarePriceDelta.java
 */
export interface FarePriceDelta {
    deltaWindow: FarePriceDeltaWindow;
    startPrice: number;
    dollarChange: number;
    pctChange: number;
}

export interface FarePriceHistory {
    [FarePriceDeltaWindow.YOY]?: FarePriceDelta;
    [FarePriceDeltaWindow.MOM]?: FarePriceDelta;
    [FarePriceDeltaWindow.WOW]?: FarePriceDelta;
}
