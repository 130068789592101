import { defineMessages } from 'react-intl';

export default defineMessages({
    dollarDropShort: { id: 'FareAttributeTag_dollarDropShort', defaultMessage: '${dollarDrop} Price Drop' },
    dollarDropLong: {
        id: 'FareAttributeTag_dollarDropLong',
        defaultMessage:
            'The price for this trip is ${dollarDrop} lower than it was this time last {period}. Grab it while it lasts!'
    },
    yoyPeriod: { id: 'FareAttributeTag_yoyPeriod', defaultMessage: 'year' },
    momPeriod: { id: 'FareAttributeTag_momPeriod', defaultMessage: 'month' },
    wowPeriod: { id: 'FareAttributeTag_weekPeriod', defaultMessage: 'week' }
});
