import React from 'react';
import { injectIntl, InjectedIntl } from 'react-intl';

import msgs from './messages';
import { DropdownFilter, FilterOption } from 'components/Filter';
import { FareNumStops } from 'models/Fares';

interface NumStopsFareFilterProps {
    intl: InjectedIntl;
    selectedValue?: FareNumStops;
    onValueChanged(numStops: FareNumStops): void;
    onClearFilter(): void;
}

/**
 * A button and dropdown for filtering fares by number of stops (e.g. nonstop).
 */
class NumStopsFareFilter extends React.Component<NumStopsFareFilterProps> {
    public render() {
        const { intl, onClearFilter } = this.props;

        return (
            <DropdownFilter
                filterLabel={intl.formatMessage(msgs.label)}
                isMultiSelect={false}
                options={this.getOptions()}
                selectedOptions={this.getSelectedOptions()}
                onClearFilter={onClearFilter}
                onOptionChanged={this.onOptionChanged}
                onSelectAll={onClearFilter}
            />
        );
    }

    private getOptions = (): Array<FilterOption<FareNumStops>> => {
        return [
            {
                id: FareNumStops.NONSTOP,
                description: this.props.intl.formatMessage(msgs.nonStop)
            },
            {
                id: FareNumStops.ONEPLUS_STOPS,
                description: this.props.intl.formatMessage(msgs.onePlusStops)
            }
        ];
    };

    private getSelectedOptions = (): Array<FilterOption<FareNumStops>> => {
        return this.getOptions().filter(option => option.id === this.props.selectedValue);
    };

    private onOptionChanged = (option: FilterOption<FareNumStops>) => {
        this.props.onValueChanged(option.id);
    };
}

export default injectIntl(NumStopsFareFilter);
