import React, { ReactNode } from 'react';

import './styles.scss';
import AwdIcon, { AwdIconName } from 'components/AwdIcon';
import OutsideClickHandler from 'components/OutsideClickHandler';

interface FilterModalProps {
    children: ReactNode;
    labelText: string;
    onClose(): void;
}

/**
 * A modal component that detects clicks outside itself.
 */
export default class FilterModal extends React.Component<FilterModalProps> {
    public render = () => {
        const { children, labelText, onClose } = this.props;

        return (
            <div className='filter-modal'>
                <OutsideClickHandler onOutsideClick={onClose}>
                    <div className='filter-modal__header'>
                        <span className='filter-modal__label'>{labelText}</span>
                        <span className='filter-modal__close' onClick={onClose}>
                            <AwdIcon name={AwdIconName.Ex} />
                        </span>
                    </div>
                    <div className='filter-modal__content'>{children}</div>
                </OutsideClickHandler>
            </div>
        );
    };
}
