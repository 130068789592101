import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';

import { Fare, FareInstance } from 'models/Fares';
import { AwdIconName } from 'components/AwdIcon';
import FareAttributeTag, { FareAttributeTagIconColor, FareAttributeTagTheme } from 'components/FareAttributeTag/index';
import FareAttributeCode from 'models/Fares/FareAttributeCode';
import { FarePriceDeltaWindow } from 'models/Fares/FarePriceDelta';
import msgs from './messages';
import { CurrencyCode } from 'models/Currency';
import FormattedMessage = ReactIntl.FormattedMessage;

interface FareAttributeTagContainerProps {
    fare: Fare;
    fareInstance: FareInstance;
    theme?: FareAttributeTagTheme;
    hideIcon?: boolean;
    hideTooltip?: boolean;
    intl: InjectedIntl;
    renderWithToolTipExpanded?: boolean;
}

const PRICE_CHANGE_ATTRIBUTES: {
    [key: number]: { deltaWindow: FarePriceDeltaWindow; period: FormattedMessage.MessageDescriptor };
} = {
    [FareAttributeCode.PRICE_YOY_BETTER_AND_WILL_INCREASE]: {
        deltaWindow: FarePriceDeltaWindow.YOY,
        period: msgs.yoyPeriod
    },
    [FareAttributeCode.BETTER_YOY]: { deltaWindow: FarePriceDeltaWindow.YOY, period: msgs.yoyPeriod },
    [FareAttributeCode.BETTER_MOM]: { deltaWindow: FarePriceDeltaWindow.MOM, period: msgs.momPeriod },
    [FareAttributeCode.BETTER_WOW]: { deltaWindow: FarePriceDeltaWindow.WOW, period: msgs.wowPeriod }
};

export const FareAttributeTagContainer = injectIntl(
    ({
        fare,
        fareInstance,
        theme = FareAttributeTagTheme.Transparent,
        hideIcon = false,
        hideTooltip = false,
        intl,
        renderWithToolTipExpanded = false
    }: FareAttributeTagContainerProps) => {
        const priceConfidenceAttribute = fareInstance.priceConfidenceAttribute;

        if (!priceConfidenceAttribute) {
            return <></>;
        }

        const attributeCode = priceConfidenceAttribute.code,
            currency = fare.currency;
        let text = priceConfidenceAttribute.shortDescription,
            tooltipText = priceConfidenceAttribute.longDescription,
            iconName,
            iconColor;

        switch (attributeCode) {
            case FareAttributeCode.WORSE_MOM:
            case FareAttributeCode.WORSE_WOW:
                iconName = AwdIconName.SquiggleUp;
                iconColor = FareAttributeTagIconColor.Razzmatazz;
                break;
            case FareAttributeCode.PRICE_LIKELY_TO_INCREASE:
            case FareAttributeCode.BETTER_YOY:
            case FareAttributeCode.PRICE_YOY_BETTER_AND_WILL_INCREASE:
                iconName = AwdIconName.Paw;
                iconColor = FareAttributeTagIconColor.Blue;
                break;
            case FareAttributeCode.BETTER_MOM:
            case FareAttributeCode.BETTER_WOW: {
                const { deltaWindow, period } = PRICE_CHANGE_ATTRIBUTES[attributeCode],
                    history = fareInstance.priceHistory[deltaWindow],
                    dollarDrop = history && history.dollarChange && -1 * Math.round(history.dollarChange),
                    showDollarDrop = currency === CurrencyCode.USD && dollarDrop && dollarDrop > 0;

                iconName = AwdIconName.SquiggleDown;
                iconColor = FareAttributeTagIconColor.Blue;
                text = showDollarDrop ? intl.formatMessage(msgs.dollarDropShort, { dollarDrop }) : text;
                tooltipText = showDollarDrop
                    ? intl.formatMessage(msgs.dollarDropLong, { dollarDrop, period: period.defaultMessage })
                    : tooltipText;
                break;
            }
        }

        return (
            <FareAttributeTag
                text={text}
                iconName={hideIcon ? undefined : iconName}
                iconColor={iconColor}
                tooltipText={hideTooltip ? undefined : tooltipText}
                theme={theme}
                renderWithToolTipExpanded={renderWithToolTipExpanded}
            />
        );
    }
);
